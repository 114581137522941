import DivVideoPlayer from '../../components/common/DivVideoPlayer';
import { useEffect } from 'react';
import { globalFunctions } from '../../utils/main';

const Kddl3Tech4 = () => {

    useEffect(() => {
        console.log('App component mounted');
        
         // Check if document is already loaded
         if (document.readyState === 'loading') {
          document.addEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        } else {
          globalFunctions.initializeLazyLoad();
        }
    
        // Cleanup
        return () => {
          document.removeEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        };
    
      }, []);

    return (
        <div className='Kddl3Tech4'>
            <div className="Kddl3Tech4Content">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <div className="wrapper">
                            <img src="/static/images/hostmasters2024.png" id="img-banner-hostmaster" />
                            <h1 id="h1-hostmaster">FOY Flows Activation</h1>
                        </div>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <div className="wrapper prevent-copy">
                                <div className="chapter-part">
                                    <h6 className="lazy-item" id="heading-1-1">Part A: Light Crystal Body Keylontic FOY Flow Activation</h6>
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1-erdQHsShZhhAdBFWa1o0fKYEqrFSdLi/preview' />
                                    </div>
                                </div>
                                <div className="chapter-part">
                                    <h6 className="lazy-item" id="heading-1-2">Part B: FOY Flows Activation</h6>
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1-vVw0yiU5lyqJ1sqtocGnUvJpSo-V3u5/preview' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default Kddl3Tech4;