import { useEffect, useState } from "react";

function MobileNavigation() {
    const [isMenuActive, setIsMenuActive] = useState(false);

    const toggleMenu = () => {
        const menu = document.querySelector('#site-navigation');
        if (menu === null) { return; }
        setIsMenuActive(!isMenuActive);
        menu.classList.toggle('active');
    };

    useEffect(() => {
        const menuItems = document.querySelectorAll('.menu-item');
        
        const handleMenuClick = () => {
            setIsMenuActive(false);
            const menu = document.querySelector('#site-navigation');
            if (menu) {
                menu.classList.remove('active');
            }
        };

        menuItems.forEach(item => {
            item.addEventListener('click', handleMenuClick);
        });

        return () => {
            menuItems.forEach(item => {
                item.removeEventListener('click', handleMenuClick);
            });
        };
    }, []);

    return (
        <div className="mobile-nav">
            <div className="toggleBar">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <span className="menu" onClick={toggleMenu}>Menu</span>
            <span className="close" onClick={toggleMenu}>Close</span>
        </div>
    )
}

export default MobileNavigation;