import {users} from '../mockdata/users'

export const loginAPI = async (credentials) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
  
    const user = users.find(u => u.email === credentials.email);
  
    if (!user || user.password !== credentials.password) {
      throw new Error('Invalid credentials');
    }
  
    // Don't send password in response
    const { password, ...userWithoutPassword } = user;
    
    return {
      success: true,
      data: {
        ...userWithoutPassword,
        lastLogin: new Date().toISOString()
      }
    };
  };