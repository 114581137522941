import styles from './hostmaster.module.css'
import DivVideoPlayer from '../../components/common/DivVideoPlayer';
import { useEffect } from 'react';
import { globalFunctions } from '../../utils/main';
import MobileNavigation from '../../components/common/MobileNavigation';

const HostMaster20241A = () => {
    const baseUrl = process.env.REACT_APP_URL;
    useEffect(() => {
        console.log('App component mounted');
        
         // Check if document is already loaded
         if (document.readyState === 'loading') {
          document.addEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        } else {
          globalFunctions.initializeLazyLoad();
        }
    
        // Cleanup
        return () => {
          document.removeEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        };
    
      }, []);

    return (
        <div className='hostmaster20241A'>
            <div className="hostmaster1AContent">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <div className="wrapper">
                            <img src="/static/images/hostmasters2024.png" id="img-banner-hostmaster" />
                            <h1 id="h1-hostmaster">HostMasters 2024 <br />- Session 1A</h1>
                        </div>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <nav id="site-navigation" className="health-navigation">
                                {/* <!--<button className="menu-toggle" aria-controls="primary-menu" aria-expanded="false"></button>--> */}
                                <div className="menu-header-menu-container">
                                    <ul id="primary-menu" className="menu">
                                        <li id="menu-item-49"
                                            className="menu-item   menu-item-home current-menu-item page_item page-item-38 current_page_item menu-item-49">
                                            <a href={baseUrl}><span>00</span><label>Home</label></a></li>
                                        <li id="menu-item-36"
                                            className="menu-item   menu-item-36"><a
                                                href="#heading-1-1"><span>01</span><label>Các nền tảng Aurora</label></a></li>
                                        <li id="menu-item-33"
                                            className="menu-item   menu-item-33"><a
                                                href="#heading-1-2"><span>02</span><label>Krystal River Fail-Safe Host là Eternal-Host-1</label></a></li>
                                        <li id="menu-item-32"
                                            className="menu-item   menu-item-32"><a
                                                href="#heading-1-3"><span>03</span><label>Nhiệm vụ giải cứu Amenti-Host-3</label></a></li>
                                        <li id="menu-item-28"
                                            className="menu-item   menu-item-28"><a
                                                href="#heading-1-4"><span>04</span><label>Seeding-3 & Hành trình tiến hóa của chúng ta hiện tại</label></a></li>
                                        <li id="menu-item-37"
                                            className="menu-item   menu-item-37"><a
                                                href="#hea ding-1-5"><span>05</span><label>Có ai trên Hành tinh đã kích hoạt 12 sợi DNA chưa, trông như thế nào?</label></a></li>
                                        <li id="menu-item-31"
                                            className="menu-item   menu-item-31"><a
                                                href="#heading-1-6"><span>06</span><label>DNA nhiều hơn 2 sợi (xoắn kép) được hiểu & trông như thế nào?</label></a></li>
                                        <li id="menu-item-29"
                                            className="menu-item   menu-item-29"><a
                                                href="#heading-1-7"><span>07</span><label>Trumpet Pulse khiến Cổng đóng - Lưới Hành tinh có nguy cơ sụp đổ & Krystal River Fail-Safe Host kích hoạt 8/2011</label></a></li>
                                        <li id="menu-item-30"
                                            className="menu-item   menu-item-30"><a
                                                href="#heading-1-8"><span>08</span><label>Chúng ta có ít nhất bao nhiêu DNA đang được kích hoạt? & khả năng đi qua Cổng</label></a></li>
                                        <li id="menu-item-30"
                                            className="menu-item   menu-item-30"><a
                                                href="#heading-1-9"><span>09</span><label>Vì sao gọi là HostMaster?</label></a></li>
                                        <li id="menu-item-30"
                                            className="menu-item   menu-item-30"><a
                                                href="#heading-1-10"><span>10</span><label>Proga Shield</label></a></li>
                                        <li id="menu-item-30"
                                            className="menu-item   menu-item-30"><a
                                                href="#heading-1-11"><span>11</span><label>Freedom Teachings – Trumpet Pulse</label></a></li>
                                        <li id="menu-item-30"
                                            className="menu-item   menu-item-30"><a
                                                href="#heading-1-12"><span>12</span><label>6520AD - CAIC-IAFW - Con đường Thăng thiên (Ascension)</label></a></li>
                                        <li id="menu-item-30"
                                            className="menu-item   menu-item-30"><a
                                                href="#heading-1-13"><span>13</span><label>Freedom Teachings Online? Trumpet Pulse?</label></a></li>
                                    </ul>
                                </div>
                            </nav>
                            <MobileNavigation />
                            <div className="wrapper prevent-copy">
                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-1">1. Nền tảng Aurora & Chế độ Trường Bảo vệ Tuyệt đối</h6>
                                    <div className="textContent lazy-item">
                                        <p>
                                            <b>1.1. Các nền tảng Aurora</b>
                                        </p>
                                        <p>11:48</p>
                                        <p>- Sơ đồ Lối đi AnshaTASa Passage - Bản đồ dọc (Vertical Map) có 5 Nền tảng Aurora (1-5) dẫn đến những nơi và không gian khác nhau</p>
                                        <p>- Nền tảng Aurora-1 dẫn đến 2317AD (ở lớp lower & mid Mantle của Earth D2)</p>
                                        <p>- Quả cầu Amenti nằm ở Nền tảng Aurora-4 trong khu vực Lối đi Adashi của Đền Adashi Temples</p>
                                        <p>- Tần số Quả cầu Amenti sẽ mở từ Aurora-4-Đền Adashi Temples xuống Aurora-1-Vùng an toàn, khu vực Hang động</p>
                                        <p>- Những Nền tảng (5 Nền tảng Aurora 1-5) này dần mở (8/2024 – 8/2025) & chúng ta có thể đến được bằng Cơ thể Plasma qua chương trình HostMasters & chúng ta sẽ ở Chế độ Trường Bảo vệ Tuyệt đối (Absolute Protectorate)</p>
                                        <p>
                                            <b>1.2. Chế độ Trường Bảo vệ Tuyệt đối (Absolute Protectorate) là gì?</b>
                                        </p>
                                        <p>
                                            - Chế độ Trường Bảo vệ Tuyệt đối (Absolute Protectorate) là sự pha trộn kết hợp của 3 Tần số gồm:
                                        </p>
                                        <p>
                                            + Lối đi AnshaTASa Passage của Tần số Aurora Continuum (Tần số Dải Cực quang Aurora)
                                        </p>
                                        <p>
                                            + External Creation Lối đi Hành lang Amenti (Halls of Amenti Passage) kết nối với Lối đi của Vòng cung Giao ước (the Arc of the Covenant Passage)
                                        </p>
                                        <p>
                                            + Lối đi Cầu KrystalBridge Passage kết nối với Lưới Thánh đường Cathedral Grid
                                        </p>
                                        <p>
                                            <i>Khi kết hợp 3 tần số này lại với nhau chúng tạo ra Trường Bảo vệ Tuyệt đối (Absolute Protectorate), đó là một loạt/dòng Tần số bảo vệ bạn, bảo vệ những hóa thân trong cuộc sống này {'>'} nó sẽ dần dần giúp bảo vệ bạn không chỉ trong kiếp này mà cả kiếp sau, để đưa bạn trở lại Chu kỳ Tiến hóa Hữu cơ thay vì Sự Thoái hóa/Sa ngã {'>'} đó là một quá trình xây dựng</i>
                                        </p>
                                        <p>
                                            <i>Đây chính là điều chúng ta đã chờ đợi trong Hàng Triệu Năm – Nhiệm vụ giải cứu Amenti cách đây 550 Triệu Năm Trước để giải cứu những Linh hồn đã mất của Tara đã rơi vào Trái đất Mật độ 1 (DN-1) để họ trở lại với nhau, nơi họ có thể Kết nối lại với Mô hình Tiến hóa Ban đầu của mình đã bắt đầu ở Thiên hà Andromeda, chúng ta đi vào Andromeda qua Nền tảng Aurora 4 & 5 – chúng kết nối với Hành tinh Urtha-3 trong Thiên hà Andromeda {'>'} Đây là “Dự Án Lớn” mà chúng tôi đang thực hiện </i>
                                        </p>
                                        <p>End 14:12</p>

                                        <DivVideoPlayer src="https://adilo.bigcommand.com/watch/O8qScq2K" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-2">2. Krystal River Fail-Safe Host là Eternal-Host-1</h6>
                                    <div className="textContent lazy-item">
                                        <p>14:48</p>
                                        <p>Bắt đầu tháng 8 năm 2011 liên quan đến Blue Lotus Amenti {'>'} Nhiệm vụ giải cứu cuối cùng là sự hoàn thành của Nhiệm vụ giải cứu Amenti-Host-3 {'>'} đây là sự kết hợp của 3 Host: Host-3, Host-2 và Host-1 {'>'} cuối cùng hòa nhập với Nhiệm vụ giải cứu Amenti và Cổng Amenti-Cổng Arc of the Covenant</p>
                                        <p>Nghĩa là Hành tinh & Cơ thể Vật lý của bạn có thể kết nối với những Tần số này, được thực hiện thông qua Cơ thể Plasma sau đó tiến xa hơn nữa cho đến khi bạn đã chuẩn bị xong tất cả Cơ thể của mình (đó là những gì chúng ta đang làm):</p>
                                        <p>+ Cơ thể Ánh sáng (Light Body)</p>
                                        <p>
                                            + Cơ thể Plasma (Plasma Body)
                                        </p>
                                        <p>
                                            + Cơ thể Vật lý (Physical Body)
                                        </p>
                                        <p>End 15:45</p>
                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/jIRTK4WD" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-3">3. Nhiệm vụ giải cứu Amenti-Host-3</h6>
                                    <div className="textContent lazy-item">
                                        <p>16:45</p>
                                        <p>
                                            Nhiệm vụ giải cứu Amenti-Host-3 liên quan đến
                                        </p>
                                        <p>+ Quả cầu Amenti, STAFF Ngọn lửa xanh của Amenti</p>
                                        <p>
                                            + 6-Hành lang Amenti (Halls of Amenti)
                                        </p>
                                        <p>+ Lối đi Vòng cung Giao ước (the Arc of the Covenant)</p>
                                        <p>16:57</p>
                                        <p><b>3.1 Lối đi Vòng Cung Giao Ước – Hòm Giao Ước là gì? (the Arc of the Covenant?)</b></p>
                                        <p>- Vòng Cung Giao Ước (the Arc of the Covenant) là giao thoa của</p>
                                        <p>+ Lối đi Cầu KrystalBridge Passage</p>
                                        <p>+ Lối đi AnshaTASa Passage</p>
                                        <p><i> Để tạo ra Cầu (Bridge) Tần số Bảo vệ Tuyệt đối (Absolute Protectorate)</i>
                                        </p>
                                        <p>
                                            <b>3.2 Lịch sử Vòng Cung Giao Ước (the Arc of the Covenant)</b>
                                        </p>
                                        <p>
                                            - Ban đầu nó là Lối đi Vòng Cung Giao Ước (the Arc of the Covenant) của Palaidor
                                        </p>
                                        <p>
                                            - Vòng Cung Giao Ước (the Arc of the Covenant) là một Cánh cổng Thời gian giữa Trái đất của chúng ta và Aquinos Nguyên thủy-Thiên hà Andromeda M31 {'>'} được tạo ra cách đây 840.000 năm trước bởi các Chủng tộc GA-MCEO
                                        </p>
                                        <p>
                                            - Nó được sử dụng để Lưu trữ và Bảo vệ Quả cầu Amenti – Trường Di truyền Hình thái Chủng tộc của Loài Người Thiên thần {'>'} cho đến khi Quả cầu có thể được đưa trở lại Lõi Trái đất
                                        </p>
                                        <p>
                                            + Vòng Cung Arc cho phép Quả cầu Amenti đi xuống từ Aquinos-Thiên hà Andromeda M31 khi lõi Trái đất đạt đến Tốc độ Rung động đủ cao (điều này chưa xảy ra)
                                        </p>
                                        <p>
                                            + Trong Sứ mệnh Giải cứu Amenti Ban đầu {'>'} Quả cầu Amenti được cho là có thể hạ xuống Lõi Trái đất {'>'} vì vậy mọi người có thể truy cập từ Trái đất mà không phải đi bất cứ nơi nào khác để truy cập (điều này đã không xảy ra) vì Tần số Rung động của Trái đất không đạt tới mức thích hợp để điều đó xảy ra
                                        </p>
                                        <p>
                                            18:18
                                        </p>
                                        <p>
                                            <b>3.3. Trumpet Pulse – Arc of the Covenant – Absolute Protectorate</b>
                                        </p>
                                        <p>
                                            - 7 Xung kèn Trumpet Pulse được kích hoạt từ năm 2016 – 2023 sẽ phá hủy Quả cầu Amenti {'>'} vì vậy Quả cầu Amenti đã được đưa trở lại Đền Adashi Temples ở Andromeda
                                        </p>
                                        <p>
                                            - Cây Cầu Cổng (Portal Bridge) mà chúng tôi đang xây dựng đang kết hợp với:
                                        </p>
                                        <p>
                                            + Lối đi Vòng Cung Giao Ước (the Arc of the Covenant)
                                        </p>
                                        <p>
                                            + Lối đi AnshaTASa Passage
                                        </p>
                                        <p>
                                            + Lối đi Cầu KrystalBridge Passage
                                        </p>
                                        <p>
                                            <i> {'>'} Kết hợp Tần số của các Lối đi lại đó là Tần số Trường Bảo vệ Tuyệt đối (Absolute Protectorate)</i>
                                        </p>
                                        <p>
                                            <i> {'>'} Vì vậy chúng ta có thể tiếp cận Quả cầu Amenti dần dần bằng cách đi lên thay vì xuống Lõi Trái đất</i>
                                        </p>
                                        <p>
                                            - Nếu bạn hóa thân/đầu thai từ 6520AD ở Andromeda {'>'} bạn có thể quay trở lại đó
                                        </p>
                                        <p>
                                            - Nếu không bạn sẽ hóa thân/đầu thai ở một Nền tảng Aurora nào đó trên Bản đồ Dọc (Vertical Map) {'>'} nơi bạn có chuyến đi an toàn trở về Andromeda (nơi xuất phát ban đầu của chúng ta)
                                        </p>
                                        <p>19:14</p>
                                        <p>
                                            <b>3.4. The Arc of the Covenant?</b>
                                        </p>
                                        <p>
                                            - Vòng Cung Giao Ước (the Arc of the Covenant) không phải là một chiếc hộp lớn
                                        </p>
                                        <p>
                                            - Lối đi Vòng Cung Giao Ước (the Arc of the Covenant) cho phép truy cập đến các Cổng 6-Hành lang Amenti
                                        </p>
                                        <p>
                                            - Vòng Cung Giao Ước (the Arc of the Covenant) bảo vệ Quả cầu Amenti khỏi sự tấn công và cho phép Quả cầu Amenti có thể quay trở lại Lõi Trái đất khi nó (Vòng Cung Giao Ước-the Arc of the Covenant) mở, hoặc khi bạn mở một cây Cầu (Bridge) tới đó
                                        </p>
                                        <p><i> Những thứ trên Trái đất có thể tiếp cận phạm vi của Quả cầu Amenti để trở về với Trường Di truyền Hình thái Chủng tộc mà họ đã xuất phát ban đầu {'>'} nghĩa là họ có thể tiếp tục tiến lên/Thăng thiên trên con đường hữu cơ của mình</i></p>
                                        <p>- Có những đồ vật/vật thể năng lượng {'>'} được tích điện bởi Quả cầu Amenti qua Lối đi Vòng Cung Giao Ước (the Arc of the Covenant Passage) {'>'} Hộp đựng/những đồ vật được sạc (tích điện) đó đã bị nhầm lẫn về mặt lịch sử và cố tình bị hiểu sai thành Hòm Giao Ước (the Ark of the Covenant)</p>
                                        <p>
                                            <b>3.5. Vậy chiếc hộp đựng những dụng cụ đó không phải là Hòm Giao Ước (the Arc of the Covenant) sao?</b>
                                        </p>
                                        <p>
                                            - Không, chúng đã được sạc/tích điện bởi Lối đi Vòng Cung Giao Ước (the Arc of the Covenant) và Quả cầu Amenti {'>'} vậy đó chính là sự nhầm lẫn trong ghi chép lịch sử hoặc các văn bản tương tự về Hòm Giao Ước (the Arc of the Covenant)
                                        </p>
                                        <p>
                                            - The Arc of the Covenant thực sự là một Bộ Cổng Lối đi kết nối với Quả cầu Amenti và đó là cơ sở sức mạnh của một số đồ vật/vật thể nhất định như Ankh – một số thứ ở Ai Cập cần được nạp Tần số để làm những việc như làm vật thể bay lên (phản trọng lực) & tất cả những việc tương tự {'>'} sức mạnh đó đã không còn xuất hiện trong một thời gian dài kể từ thời Atlantis và những nơi tương tự
                                        </p>
                                        <p>
                                            End 20:50
                                        </p>

                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/nFViRd2J" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-4">4. Seeding-3 & Hành trình tiến hóa của chúng ta hiện tại</h6>
                                    <div className="textContent lazy-item">
                                        <p>20:51</p>
                                        <p>- 800.000 năm trước, các chủng tộc Con người Thiên thần trên Trái đất qua lần Gieo Giống-3 (Seeding-3) đã được sinh ra bằng xương bằng thịt thông qua Lối đi Cánh cổng Thời gian Arc of the Covenant</p>
                                        <p>- Hầu như tất cả mọi người trên hành tinh này (99%), một phần nào đó trong chúng ta vẫn còn giữ một phần nguyên bản của Khuôn Mẫu (Template) Con người Thiên Thần (Angelic Human) {'>'} nghĩa là ban đầu bạn đã hóa thân/đầu thai xuống Trái đất trong lần Gieo Giống thứ 3 (Seeding-3) thông qua Lối đi Vòng Cung Giao Ước (the Arc of the Covenant) {'>'} vì vậy bạn sẽ trở về nhà theo cách bạn đã đến {'>'} đó là mục đích của quá trình này {'>'} chúng tôi đã chờ đợi 3 lần Gieo Giống để đưa điều này trở lại Dòng dõi Con người Thiên thần</p>
                                        <p>
                                            - Thậm chí nếu bạn là con lai, vì hầu hết các sinh vật trên Hành tinh này đều lai với những chủng tộc khác ở nhiều giai đoạn khác nhau của Quá trình Tiến hóa
                                        </p>
                                        <p>+ Một số lai với Chủng tộc illuminati</p>
                                        <p>+ Một số lai với các Chủng tộc Liên sao-Hạt Giống giữa các Vì Sao (Stellar StarSeed)</p>
                                        <p>- Nếu bạn vẫn còn một chút Mẫu (Template) Con người Thiên thần (Angelic Human) trong DNA của bạn, và nếu bạn vẫn nhớ mình là con người {'>'} thì vẫn có khả năng Kết nối lại thông qua Lối đi Vòng Cung Giao Ước (the Arc of the Covenant) {'>'} để trở lại Quả cầu Amenti {'>'} để bắt đầu Hành trình Tiến hóa của riêng bạn.</p>
                                        <p>End 22:00</p>

                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/qbaNDzi0" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-5">5. Có ai trên Hành tinh đã kích hoạt 12 sợi DNA chưa, trông như thế nào?</h6>
                                    <div className="textContent lazy-item">
                                        <p>23:14</p>
                                        <p>- Chưa ai trên Hành tinh Kích hoạt 12 sợi DNA cả {'>'} chúng ta đang nỗ lực hướng tới mục tiêu đó</p>
                                        <p>- Chúng ta có khả năng thực hiện điều đó qua cơ thể Plasma {'>'} nhưng không có ai chạy được Mẫu (Template) 12 sợi DNA vì họ sẽ trông không giống con người, họ sẽ lớn hơn nhiều và có cơ thể bằng Silicat (Silicat Matrix) {'>'} họ sẽ có Cấu trúc Cơ thể ở dạng Tinh thể (Crystalline) {'>'} vì vậy trừ khi bạn trông giống như một người Tinh thể Crystal biết đi {'>'} nếu bạn trông giống như có đôi mắt 3D có lẽ bạn chưa đạt tới 12 sợi DNA.
                                        </p>
                                        <p>End 23:44
                                        </p>

                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/PeblFK36" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-6">6. DNA nhiều hơn 2 sợi (xoắn kép) được hiểu & trông như thế nào?</h6>
                                    <div className="textContent lazy-item">
                                        <p>24:58</p>
                                        <p>- Nó không giống như việc nhìn DNA dưới kính hiển vi và thấy 2 sợi {'>'} nhưng chính những gì được mã hóa trong các sợi DNA đó mới quyết định nó là 1 sợi – 2 sợi – 3 sợi hay 12 sợi DNA {'>'} bạn vẫn sẽ thấy chuỗi DNA xoắn kép trong một thời gian khá dài</p>
                                        <p>End 25:12</p>

                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/gSHS_yho" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-7">7. Trumpet Pulse khiến Cổng đóng - Lưới Hành tinh có nguy cơ sụp đổ & Krystal River Fail-Safe Host kích hoạt 8/2011</h6>
                                    <div className="textContent lazy-item">
                                        <p>26:29</p>
                                        <p>- Rất nhiều Cổng đã bị đóng lại khi Fail-Safe phải Kích hoạt vì Lưới (Grid) Hành tinh đang sụp đổ {'>'} rất nhiều Cổng External Amenti Gates đã bị đóng vào lúc đó & hiện tại đang dần mở lại vì Krystal River Fail-Safe Host đã thành công (8/2011)</p>
                                        <p>- Dù Chương trình Nghị sự Tiêu cực đã Kích hoạt 7 Xung kèn (Trumpet Pulse) trong Lưới (Grid) Hành tinh & có thể phá hủy Hành tinh {'>'} nhưng chúng ta có 8 Tần số Chervon Burst của Dòng chảy FOY Flows, đó chính là thứ giúp cân bằng lại {'>'} vậy nên mọi người vẫn ở đây và không có gì thay đổi – không có sự Dịch chuyển/Thay đổi Cực (Pole Shift) hay bất cứ điều gì tương tự.</p>
                                        <p>End 27:11</p>

                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/bOgvSbXa" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-8">8. Chúng ta có ít nhất bao nhiêu DNA đang được kích hoạt? & khả năng đi qua Cổng</h6>
                                    <div className="textContent lazy-item">
                                        <p>27:41</p>
                                        <p>- Bạn đã có ít nhất 2 sợi DNA nếu không bạn sẽ vô hình {'>'} vậy nếu bạn không vô hình thì có nghĩa là bạn đã có ít nhất 2 sợi DNA đã được Kích hoạt và ít nhất thì bạn đang làm việc với sợi DNA thứ 3, nếu không thì nó là sợi DNA thứ 4 của bạn</p>
                                        <p>- Có những Cổng chỉ yêu cầu ít nhất 3 DNA đã được Kích hoạt để đi qua {'>'} vì vậy bạn sẽ có thể đưa Cơ thể Vật lý đi qua những Cổng này (đó là một thành tựu) {'>'} nghĩa là bạn không cần phải Hóa thân/Đầu thai vào một Cơ thể khác {'>'} bạn có thể bắt đầu Quá trình Chuyển đổi Hình dạng Cơ thể mà bạn đang có</p>
                                        <p>End 28:13</p>

                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/0LaqgOoy" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-9">9. Vì sao gọi là HostMaster?</h6>
                                    <div className="textContent lazy-item">
                                        <p>28:55</p>
                                        <p>- Lý do mà Guardian gọi nó là HostMaster là vì nó là Master Host</p>
                                        <p>- Nó là Big Host {'>'} nơi 3 Host được kết hợp để cho phép:</p>
                                        <p>
                                            + Internal-Eternal với Lối đi Cầu KrystalBridge Passage kết hợp với Lối đi AmorAea Passage
                                        </p>
                                        <p>
                                            + Lối đi AnshaTASa Passage với Trường Tần số Aurora giữa Mẫu (Template) Vật Chất Tối Rasha (Rasha Dark Matter Template) và External Creation
                                        </p>
                                        <p>
                                            + Bộ Cổng Amenti đi kèm với Lối đi Vòng Cung Giao Ước (the Arc of the Covenant) và External Creation
                                        </p>
                                        <p>- Master Host {'>'} 3 Host đó kết hợp lại để cho phép Lối đi lên qua Lối đi Thăng thiên (Ascension) xuyên qua cánh cửa mở</p>
                                        <p>End 29:37</p>
                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/HGP4bmnD" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-10">10. Proga Shield</h6>
                                    <div className="textContent lazy-item">
                                        <p>37:14</p>
                                        <p>- Khi chúng ta bay với Proga Shield {'>'} chúng ta có thể làm những việc có ích cho Hành tinh này, giúp đỡ những người khác trên Hành tinh này {'>'} chúng ta không ngăn chặn được một cuộc chiến tranh đang diễn ra ở đây hay ở đó, hay một trận động đất ở kia nhưng chúng ta có thể giúp ổn định nó một chút {'>'} chúng ta có thể mang đến Shield cho phép những người đó-người không biết về công việc này một cách có ý thức thì họ vẫn sẽ có Tần số để kết nối với Trường Bảo vệ Tuyệt đối (Absolute Protectorate) {'>'} ở những nơi thiên tai hay chiến tranh và những nơi tương tự như vậy thì đó thực sự là một Vị Thần Bảo Vệ</p>
                                        <p>- Dành cho những người quan tâm đến việc có một Sứ mệnh lớn hơn, hơn là chỉ đạt được mục tiêu của riêng mình {'>'} cùng nhau đủ để bạn có thể bay lên và ra ngoài trong sự Thăng thiên (Ascension) để thực sự giúp đỡ người khác</p>
                                        <p>
                                            - Đạt được những Tần số đó chính là cốt lõi của HostMasters {'>'} đưa bạn đến nơi bạn có thể Thăng thiên (Ascension), sau đó lan truyền tiềm năng đó cho người khác {'>'} vì tại một thời điểm nào đó nó sẽ đạt đến khối lượng tới hạn, nơi mà rất nhiều – rất nhiều – rất rất nhiều người có thể đạt được tiềm năng Thăng thiên (Ascension) hơn so với bình thường
                                        </p>
                                        <p>
                                            - Bạn có thể tham gia với tư cách cá nhân, hoặc có thể tham gia như một hoạt động tập thể, làm việc với những người có cùng chí hướng, những người muốn hỗ trợ người khác, những người không có kiến thức về điều này {'>'} để mang những Tần số đó đến với họ, để họ có thể kết nối nếu họ muốn {'>'} bạn không thể tức giận với mọi người & đưa họ những thứ họ không muốn, bạn chỉ cần mang Tần số đến và giữ Tần số có sẵn ở một số địa điểm nhất định có thể gặp sự cố tại một thời điểm nhất định
                                        </p>
                                        <p>
                                            + 3 Ví dụ: Chúng ta có thể hướng mục tiêu đó đến Trung Đông ngay lúc này hoặc ở Ukraina, ở Nga
                                        </p>
                                        <p>- Chúng ta không Chiếu Projection đến những nơi đó ngay bây giờ, trước tiên chúng ta phải trở nên mạnh mẽ hơn, chúng ta phải học cách Chiếu Proga Projection để chúng ta có thể bay với Shield {'>'} chúng ta có thể cảm nhận sự kết nối của mình với nó, chúng ta có thể cảm nhận được sức mạnh trong mối liên hệ cá nhân của mình {'>'} sau đó sẽ đến lúc phải khắc phục sự cố ở những khu vực khác nhau trên Hành tinh</p>
                                        <p>- Tôi luôn thích làm những việc tốt {'>'} giúp đỡ Hành tinh, giúp đỡ các sinh vật trên Hành tinh bởi vì tôi thực sự yêu thương tất cả các sinh vật trên Hành tinh {'>'} và thất vọng với những người Hoa Thâm (Dark Flower People) khi họ làm điều xấu với người khác {'>'} nhưng vấn đề ở đây là hành vi & thái độ chứ không phải bản chất của họ {'>'} đây là cách thể hiện tình yêu dành cho tôi & cho bất kỳ ai tham gia vào hoạt động này</p>
                                        <p>
                                            - Đó là cách tốt nhất để bạn yêu thương hàng xóm của mình, cách tốt nhất để bạn yêu thương Hành tinh của mình, cách tốt nhất để bạn có thể yêu bản thân mình, giúp mang lại Tiềm năng Thăng thiên cho tất cả mọi thứ
                                        </p>
                                        <p>End 39:40</p>
                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/galEwaZK" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-11">11. Freedom Teachings – Trumpet Pulse</h6>
                                    <div className="textContent lazy-item">
                                        <p>43:28</p>
                                        <p>- Chúng tôi đã chờ đợi 10 năm để có thể can thiệp trực tiếp vào Hành tinh, nhưng vì sự điên rồ của Xung Kèn (Trumpet Pulse) bắt đầu từ 2016 nên việc đó không còn an toàn nữa {'>'} Đó là lý do tại sao Guardian vẫn giữ Freedom Teachings ngoại tuyến (Offline) vì những kỹ thuật Freedom Teachings sẽ kết nối bạn với Lưới Hành tinh</p>
                                        <p>- Lưới Hành tinh đang chạy Xung Kèn (Trumpet Pulse) khi làm kỹ thuật Freedom Teachings nó sẽ kết nối bạn với Xung Kèn Trumpet Pulse {'>'} đó là lý do tại sao chúng ta không có Freedom Teachings trong suốt thời gian đó cho đến khi Xung Kèn (Trumpet Pulse) ngừng chạy trong Human Shield và những Shield tương tự</p>
                                        <p>- Bây giờ chúng tôi đang tiến tới nơi mà chúng tôi có thể phát hành những thứ đó (Freedom Teachings)
                                        </p>
                                        <p>- Nếu bạn muốn tập chung vào công việc phía sau chứ không chỉ tập chung vào công việc phía trước, ngay bây giờ hãy tập chung vào công việc chuyển tiếp đó là HostMasters
                                        </p>
                                        <p>End 44:15</p>
                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/c7tZSc6u0" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-12">12. 6520AD - CAIC-IAFW - Con đường Thăng thiên (Ascension)</h6>
                                    <div className="textContent lazy-item">
                                        <p>48:16</p>
                                        <p>- Lần kích hoạt thứ 5 - Ngọn lửa Amenti Flame-2 là tháng 8/2025 {'>'} kết nối đến năm 6520AD, nằm trong Thiên hà Andromeda M31 – Hành tinh Urtha-3 {'>'} đó là nơi rất nhiều người trong chúng ta đã hóa thân/đầu thai; K+8 cũng từ đó và rất nhiều người khác nữa {'>'} đó là nơi chúng tôi có Trung tâm Phát triển Giao tiếp Liên chiều {'>'} những người đã liên lạc với tôi khi tôi còn nhỏ và cho tôi biết tôi đã đầu thai từ họ và giúp tôi thức tỉnh {'>'} họ là những người đã viết những cuốn sách Voyagers và những thứ tương tự {'>'} chúng ta kết nối lại với nơi chúng ta đến và mọi người đều có sự Kết nối ở đó</p>
                                        <p>- Có thể bạn không đầu thai trực tiếp từ kiếp này, nhưng ngày xưa bạn đã từng đầu thai từ đó, thậm chí bạn có thể đã đầu thai ở Gaia Mật độ 3 (DN-3) hoặc Tara Mật độ 2 (DN-2)</p>
                                        <p>- Mọi người đều có liên hệ với khu vực 6520AD Urtha-3 và chúng là những Thành phố Pha lê Crystal nằm trên bề mặt Urtha-3 trong Thiên hà Andromeda M31 {'>'} khi chúng ta trở lại đó, chúng ta đã xóa bỏ mọi hình mẫu tội lỗi nguyên thủy đáng sợ & mọi thứ mà chúng ta đã nói đến trong Freedom Teachings
                                        </p>
                                        <p>- Chúng ta sẽ quay trở lại Thiên hà Andromeda {'>'} mở ra con đường đi sâu hơn vào các Miền Domain bên trong, các miền Internal - Eternal Domain {'>'} cuối cùng phát triển và tiến hóa thành YhU-Rha-LA Kinservatory
                                        </p>
                                        <p>
                                            - Hiện tại là học cách thực hiện Chiếu Proga Projection với Proga Shield {'>'} kết nối với Trường Bảo vệ Tuyệt đối (Absolute Protectorate) {'>'} nó sẽ dần dần xây dựng sức mạnh trong cơ thể Plasma của bạn, điều này sẽ dần dần giúp Cơ thể Vật lý của bạn đạt đến cấp độ cao nhất có thể
                                        </p>
                                        <p><b>Chết tự nhiên và tự tử có khác nhau?</b></p>
                                        <p>
                                            - Cuối cùng bạn sẽ bỏ đi Cơ thể Vật lý khi Cơ thể đó bị hao mòn, và nếu điều đó xảy ra bạn không cần phải sợ
                                        </p>
                                        <p>
                                            - Nếu bạn cố gắng làm điều đó xảy ra (cố gắng tự tử) {'>'} bạn có thể bị ném trở lại Chu kỳ đầu thai ở đây {'>'} vì vậy tự tử không được khuyến khích {'>'} bạn không phải bị xuống Địa ngục hay gì cả, nhưng bạn có thể có nhiều lần đầu thai hơn bạn muốn trên Hành tinh Trái đất.
                                        </p>
                                        <p><b>Chữa lành với HostMasters</b></p>
                                        <p>- Một trong những điều chữa lành là HostMasters sẽ giúp chúng ta học hỏi và ghi nhớ và cảm thấy rằng chúng ta có thể tạo ra những kết nối này với Tần số của Trường Bảo vệ Tuyệt đối (Absolute Protectorate) của Sự Vĩnh hằng (Eternal) {'>'} đó là Nguồn God-Source, Ý thức Đầu tiên Vĩnh cửu EFFI bất kể bạn muốn gọi nó là gì {'>'} đó là toàn bộ Trường Ý thức rộng lớn mà tất cả chúng ta đều xuất phát từ đó {'>'} tất cả chúng ta đều quay trở về đó, tất cả chúng ta đều tồn tại bên trong nó</p>
                                        <p>
                                            - Khi bạn biết những khái niệm này {'>'} cái chết của Cơ thể Vật lý không còn đáng sợ nữa vì bạn đã có kế hoạch rồi {'>'} bạn biết mình sẽ đi đâu tiếp theo {'>'} đó là một trong những điều tuyệt vời về chương trình HostMasters {'>'} nó cho chúng ta thấy những Nền tảng Aurora này ở đâu
                                        </p>
                                        <p>
                                            - Nếu cơ thể bạn đang suy yếu và bạn chết một cách tự nhiên hoặc bạn tình cờ ở trên một chiếc máy bay bị rơi hoặc điều gì đó tương tự và bạn hét lên Ohhh! {'>'} bạn có thể biết nơi để Chiếu Projection và bạn chỉ cần nhảy vào trong đầu bạn và nhảy Cơ thể Plasma của bạn lên {'>'} bạn tự đứng dậy và ra ngoài cơ thể
                                        </p>
                                        <p><b>Cô E’Asha chia sẻ trải nghiệm của mình</b></p>
                                        <p>- Tôi đã có những kiếp sống như thế khi tôi nhớ mình đã nhảy xuống từ một vách đá, hoặc là nhảy xuống hoặc là bị tàn sát bởi những điều tồi tệ hơn ở phía sau tôi {'>'} và tôi nhảy xuống, cơ thể tôi chìm xuống nhưng Ý thức của tôi bay lên và bám vào một con chim, bằng cách nào đó có một con chim (Đại Bàng hoặc loại chim nào đó), nó đã giúp tôi bám vào và giúp tôi định hướng {'>'} giống như ồ, tôi không cảm thấy gì với cơ thể đã chết.</p>
                                        <p>
                                            - Với HostMasters chúng ta sẽ học một số Kỹ năng để có thể nói rằng - được rồi, tôi không sợ chết, nhưng tôi cũng không sợ cuộc sống {'>'} bởi vì một khi bạn có những kết nối này, bạn sẽ không vội vàng kết thúc cuộc sống {'>'} bạn đang vội vã muốn trải nghiệm cuộc sống – dù là khi còn sống hay sau khi chết {'>'} bạn thức dậy ở phía bên kia (sau khi chết) và nếu cuộc sống của bạn tồi tệ thì những điều tồi tệ sẽ đuổi theo bạn và những thứ tương tự như vậy {'>'} bạn có thể thoát khỏi điều đó bằng cách sống tốt trong cuộc sống này, đối xử tốt với mọi người và hướng tới điều gì đó cao cả hơn, ví dụ giống như HostMasters
                                        </p>
                                        <p>
                                            - Trải nghiệm cận kề cái chết giúp tôi tận hưởng cuộc sống tốt hơn, cuộc sống thật nhiều thử thách – tôi thích nó {'>'} khi bạn có thể nhận ra điều đó, giống như cái chết trở thành hư vô – là điều không thể xảy ra
                                        </p>
                                        <p>
                                            - Nếu họ tin rằng họ sắp chết và biến mất, họ không còn tồn tại nữa {'>'} nó như một loại phiền toái ở phía bên kia vì họ đang ngủ và họ từ chối thức dậy vì họ tin rằng họ đã chết và không còn nữa và cần rất nhiều Guardian đến để nói hello, hello, hellooo..! {'>'} bạn đã tỉnh chưa? bạn có thể nghe thấy tôi, tôi biết bạn có thể {'>'} và đôi khi phải mất một thời gian dài trước khi Ý thức đi đúng hướng {'>'} tôi được cho là đã chết, giống như ừ, tệ thật, đã đến lúc bắt đầu cuộc sống mới, bạn không nhận ra những điều đó sao?
                                        </p>
                                        <p>- Cuộc sống không đáng sợ như bạn nghĩ đâu, nếu bạn không nhận ra những điều đó</p>
                                        <p><i> HostMasters sẽ giúp bạn phát triển một số Kỹ năng đó, nó cũng giúp chúng ta đối phó với bất kỳ biến động (drama) nào sắp xảy ra trên Hành tinh này {'>'} hiện tại chúng ta đang ở giai đoạn cuối của Chương trình Nghị sự Âm mưu Lâu dài của Atlantean giữa các Chủng tộc illuminati và các Chủng tộc Guardian {'>'} đây là trò chơi kết thúc, nó sẽ không kết thúc vào ngày mai, nó giống như sự khởi đầu của kết thúc, còn lại 1000 năm nữa</i></p>
                                        <p>Chúng ta có thể tạo ra một tình huống khiến chúng ta cảm thấy tốt hơn, chúng ta không sợ hãi, kết nối với Trường Bảo vệ Tuyệt đối (Absolute Protectorate) {'>'} bạn có thể làm bất cứ điều gì mà Nguồn-Chúa-God-Source có thể làm, bạn không cần phải dùng cả cuộc sống để gọi Nguồn-Chúa-God-Source của bạn như Phật, Chúa, Jehovah, Allah hoặc bất kỳ ai {'>'} có rất nhiều tên gọi (lựa chọn) ngoài kia {'>'} chúng tôi chỉ thích gọi Nguồn God-Source là Ý thức Đầu tiên Vĩnh cửu EFFI, và EFFI không phải là tên – Eternal First Field Intelligence và Electro Thermal First Force Identity, đó chỉ là lời giải thích về năng lượng

                                        </p>
                                        <p>End 54:27</p>
                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/IfP4wcg9" />
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-13">13. Freedom Teachings Online? Trumpet Pulse?</h6>
                                    <div className="textContent lazy-item">
                                        <p>01:16:25</p>
                                        <p><b>Freedom Teachings</b></p>
                                        <p>- Mọi người luôn phản hồi bằng Freedom Teachings vì họ có thể cảm nhận được chúng (feel it) {'>'} bây giờ chúng ta đang trở lại nơi chúng ta có thể bắt đầu cảm nhận chúng (Freedom Teachings) một lần nữa</p>
                                        <p>- Chúng tôi thực sự phải cẩn thận về Tần số nào mà chúng tôi có thể cho phép Kỹ thuật (Technique) sử dụng để đưa Tần số từ Trái đất lên vì Xung Kèn (Trumpet Pulse) đang chạy</p>
                                        <p><b>Trumpet Pulse</b>
                                        </p>
                                        <p>- Bây giờ Xung Kèn (Trumpet Pulse) đang chạy vào Vương quốc Nguyên tố (Elemental Kingdom) nhưng Shield cá nhân và Human Shield của chúng ta hiện đã được bảo vệ khỏi Xung Kèn (Trumpet Pulse) vì nó đã đi qua chúng ta, thật không may rất nhiều người dính phải Hoa Thâm (Dark Flower), điều đó thật sự rất đáng buồn và không có bất cứ điều gì mà Guardian có thể làm để ngăn chặn điều đó xảy ra một khi nó đã bắt đầu
                                        </p>
                                        <p>- May mắn thay, cũng có những Bông hoa Tươi sáng và Nở rộ {'>'} Hoa Tươi Sáng nghĩa là mang đến Tần số Thăng thiên (Ascension Frequency)</p>
                                        <p>Dark Flower & Bright Flower, con đường trải nghiệm</p>
                                        <p>- Sự Nở hoa Thâm (Dark Flowering) {'>'} nghĩa là mang đến sự Vướng víu từ Vũ trụ Song song đã rơi (Fell) {'>'} vì thế họ bị mắc kẹt trong sự Vướng mắc và Sa ngã (Gone Fall) {'>'} đó là lý do tại sao Trái đất được gọi là Nền Văn Minh nhưng lại Phân cực đến vậy, bởi vì một phần của nó nằm trên Con đường Thăng thiên (Ascension) và phần còn lại nằm trên Con đường Thoái hóa (Descesion) {'>'} điều đó có nghĩa là cuối cùng trở về như Bụi Vũ trụ (Space Dust) {'>'} mọi người đều trở về theo cách này hay cách khác</p>
                                        <p>- Nếu bạn muốn trở về với ký ức của mình theo cách tốt đẹp {'>'} thì sự Thăng thiên (Ascension) được khuyến khích {'>'} bởi vì nếu không thì bạn sẽ trở về như Bụi Vũ trụ (Space Dust) và bạn không nhớ bạn đã từng tồn tại {'>'} tất cả đều là sự lựa chọn tự do (Free Will Choice)

                                        </p>
                                        <p>
                                            - Đây là một chút gợi ý về nơi họ sẽ đến khi họ tham gia vào phiên bản Guardian – Lời dạy Luật của Một (Law of One Teachings) {'>'} nghĩa là tất cả chúng ta đều cùng là một phần của Trường Ý thức {'>'} không có đúng và sai {'>'} chỉ có hành động và hậu quả của hành động {'>'} nghĩa là Nguồn God-Source – Ý thức đầu tiên Vĩnh cửu EFFI không phán xét, nó cho phép, nhưng nó vẫn có sở thích riêng, đó là lý do tại sao nó cho phép Vũ trụ Vĩnh cửu được tạo ra {'>'} đó là lý do tại sao có những biện pháp an toàn để nếu có điều gì đó đi quá xa (Gone Fall), nó sẽ rơi xuống một điểm nơi nó thành Bụi Vũ trụ (Space Dust) và trở về để chữa lành và được tái hòa nhập và tái sinh một lần nữa
                                        </p>
                                        <p>
                                            - Lời dạy Luật của Một (Law of One) liên quan đến Đạo đức của sự Thăng thiên (Ascension) vì có những đạo đức liên quan đến một số đặc điểm tính cách nhất định {'>'} bạn sẽ không thể tiến xa nếu không chịu trách nhiệm chữa lành những điều đó trong chính mình
                                        </p>
                                        <p>1:18:55</p>
                                        <p><b> Đúng và sai?</b></p>
                                        <p>- Ý bạn là không có đúng và sai sao? {'>'} đó là lúc họ (Guardian) nói với tôi điều đó {'>'} phản ứng đầu tiên của tôi như thể bạn có ý gì? {'>'} và họ nói vâng, điều đó phụ thuộc vào mục tiêu của bạn là gì</p>
                                        <p>- Nếu bạn muốn Thăng thiên (Ascension)</p>
                                        <p>+ có một số việc nhất định bạn phải làm để đạt được kết quả đó</p>
                                        <p>+ và có một số việc bạn không nên làm nếu muốn đạt được kết quả đó</p>
                                        <p>- Nhưng nếu bạn muốn Sa ngã (Fall) vì bạn nghĩ rằng điều đó sẽ vui hơn và bạn muốn sử dụng Tự do Ý chí (Free Will Choice) để gây ra sự tàn phá trong Vũ trụ và xem bạn sẽ đi được bao xa {'>'} và đó chính là ý tưởng của bạn về khoảng thời gian vui vẻ {'>'} bạn sẽ không muốn tham gia khóa đào tạo Thăng thiên (Ascension) vì chúng sẽ không mang lại cho bạn những gì bạn muốn {'>'} bạn muốn các khóa đào tạo Sa ngã (Fall Traning) và cả 2 đều được cho phép bởi Nguồn God-Source Đầu tiên Vĩnh cửu, nhưng sự ưu tiên luôn hướng đến sự Vĩnh cửu, nếu không sự Vĩnh cửu sẽ không còn là Vĩnh cửu nữa</p>
                                        <p>
                                            - Nguồn God-Source có một sự ưu tiên cho con đường Thăng thiên (Ascension) {'>'} hướng đến điều hạnh phúc, yêu thương, nhẹ nhàng và vui tươi {'>'} nhưng một mức độ nào đó nó cho phép sự Sụp đổ của Vạn vật (Fall of things) và sự Sụp đổ của Ý thức (Fall of consciousness) nếu Ý thức lựa chọn làm điều đó
                                        </p>
                                        <p>
                                            - Chúng ta đang ở trong vị trí mà chúng ta thấy sự Phân cực trở nên cực đoan ngay bây giờ, đặc biệt là ở Mỹ {'>'} thực ra nó ở khắp Hành tinh, nó cũng có ở Châu Âu nữa
                                        </p>
                                        <p>
                                            <b>Trumpet Pulse, con đường tiến hóa</b>
                                        </p>
                                        <p>- Họ (Guardian) đang theo dõi Tần số của Xung Kèn (Trumpet Pulse) chia tách mọi thứ trong Human Shield, trong Shield của các Dạng Sống (Life Form) trên Hành tinh {'>'} bây giờ Xung Kèn (Trumpet Pulse) đang di chuyển ra ngoài, chúng ta sẽ thấy một số điều Kỳ lạ về Khí hậu, Thời tiết, có thể là động đất vì nó lan ra các Vương quốc Nguyên tố (Elemental Kingdom) của chính Cơ thể Hành tinh</p>
                                        <p>
                                            - Vậy thì có lý do tại sao sự điên rồ đang xảy ra trong Chính trị trên Hành tinh này {'>'} các cực đối lập, chúng đang dần tách ra, một phần đang đi lên và một phần đang lựa chọn con đường rơi xuống (Gone Fall) {'>'} cả 2 đều về nhà, chỉ là không Nhớ rằng nó đã từng xảy ra, đó là lúc nó trở về như Bụi Vũ trụ (Space Dust), điều đó hoàn toàn được cho phép, cả 2 đều hoàn toàn được phép, điều này giúp giải phóng bớt gánh nặng
                                        </p>
                                        <p>
                                            - Bạn không cần phải ghét bất cứ điều gì nếu có một ngày bạn thực sự không thể chịu đựng được ý tưởng đó, nhưng hãy cho phép người khác cũng làm như vậy {'>'} khi gặp tình huống hỗn loạn hãy tìm nơi bạn cảm thấy ổn, nhưng hãy để người khác cũng có quyền đó – mọi người đều có quyền lựa chọn mối quan hệ của riêng mình với Nguồn God-Source Ý thức Đầu tiên Vĩnh cửu EFFI {'>'} họ có quyền ngầm định để đưa ra những lựa chọn đó và không để bất kỳ ai khác ở đây áp đặt chúng vì nghĩ rằng họ có thẩm quyền ra lệnh cho mọi người
                                        </p>
                                        <p>
                                            - Trở thành HostMasters giúp Proga Shield bay xung quanh, giúp ổn định và cân bằng Hành tinh và đưa nhiều người lên con đường Thăng thiên (Ascension) mà họ mong muốn {'>'} đó là lý do tôi ở đây, Guardian ở đây, đó là lý do Team chúng tôi ở đây
                                        </p>
                                        <p>End 01:21:40</p>
                                        <DivVideoPlayer src="https://videos.schoolofsouls.org/watch/tPl6Rj9o" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default HostMaster20241A