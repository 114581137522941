import styles from './hostmaster.module.css'
import DivVideoPlayer from '../../components/common/DivVideoPlayer';

const HostMaster20241B = () => {

    return (
        <div className='hostmaster20241B'>
            <div className="hostmaster1BContent">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <div className="wrapper">
                            <img src="/static/images/hostmasters2024.png" id="img-banner-hostmaster" />
                            <h1 id="h1-hostmaster">HostMasters 2024 <br />- Session 1B</h1>
                        </div>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <div className="wrapper prevent-copy">
                                <div className="chapter-part">
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1YpIxqaWmZd2vwT1gHFgw5N3pmnF9JS7S/preview' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default HostMaster20241B;