import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
// import Cookies from 'js-cookie';
import Home from './pages/Home/Home';
import Health from './pages/Health/Health';
import ProtectedRoute from './ProtectedRoute';
import Layout from './components/Layout';
import Login from './pages/Login/Login';
import HostMaster20241B from './pages/Hostmaster-2024/1B';
import HostMaster20241A from './pages/Hostmaster-2024/1A';
import FreeTech1 from './pages/Free-tech/1';
import FreeTech2 from './pages/Free-tech/2';
import FreeTech3 from './pages/Free-tech/3';
import FreeTech4 from './pages/Free-tech/4';
import FreeTech5 from './pages/Free-tech/5';
import Kddl1Dvd1 from './pages/KDDL1/Dvd1';
import Kddl1Dvd2 from './pages/KDDL1/Dvd2';
import Kddl2Dvd2 from './pages/KDDL2/Dvd2';
import Kddl3Tech1 from './pages/KDDL3/Tech1';
import Kddl3Tech2 from './pages/KDDL3/Tech2';
import Kddl3Tech3 from './pages/KDDL3/Tech3';
import Kddl3Tech4 from './pages/KDDL3/Tech4';
import Kddl3Tech5 from './pages/KDDL3/Tech5';
import Kddl3Tech6 from './pages/KDDL3/Tech6';
import Kddl3Tech7 from './pages/KDDL3/Tech7';
import Kddl3Tech8 from './pages/KDDL3/Tech8';
import Kddl3Tech9 from './pages/KDDL3/Tech9';
import Kddl3Tech10 from './pages/KDDL3/Tech10';
import FreedomTeachingChapter1 from './pages/Freedom-teaching/chapter-1';
import FreedomTeachingChapter2 from './pages/Freedom-teaching/chapter-2';
import Unauthorized from './components/Unauthorised';
import RoleBasedRoute from './components/RoleBasedRoute';
import Kddl1Dvd3 from './pages/KDDL1/Dvd3';
import Kddl1Dvd4 from './pages/KDDL1/Dvd4';
import Kddl1Dvd5 from './pages/KDDL1/Dvd5';

// Function to get the access token from cookies
// const getAccessToken = () => {
//     return Cookies.get('accessToken');
// }

// Function to check if the user is authenticated
const isAuthenticated = () => {
    // return !!getAccessToken();
    return true;
}

// const ProtectedRoute = ({ children }) => {
//   const isAuthenticated = localStorage.getItem('token');
//   return isAuthenticated ? children : <Navigate to="/login" replace />;
// };

// Create the router configuration
const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Layout />,
            children: [
              {
                index: true,
                element: <Home />
              },
              {
                path: "health",
                element:  <Health />
              },
              {
                path: "unauthorized",
                element: <Unauthorized />
              },
              {
                path: "login",
                element: <Login />
              },
              {
                // Parent free-tech route
                path: "free-tech",
                children: [
                  {
                    path: "1",
                    element: <FreeTech1 />
                  },
                  {
                    path: "2",
                    element: <FreeTech2 />
                  },
                  {
                    path: "3",
                    element: <FreeTech3 />
                  },
                  {
                    path: "4",
                    element: <FreeTech4 />
                  },
                  {
                    path: "5",
                    element: <FreeTech5 />
                  }
                ]
              },
              {
                // Parent hostmaster-2024 route
                path: "hostmaster2024",
                element: <RoleBasedRoute allowedRoles={['admin', 'paid']} />,
                children: [
                  {
                    path: "1B",
                    element: <HostMaster20241B />
                  },
                  {
                    path: "1A",
                    element: <HostMaster20241A />
                  }
                ]
              },
              {
                path: "kddl1",
                element: <RoleBasedRoute allowedRoles={['admin', 'paid']} />,
                children: [
                  {
                    path: "dvd1",
                    element:  <Kddl1Dvd1 />
                  },
                  {
                    path: "dvd2",
                    element:  <Kddl1Dvd2 />
                  },
                  {
                    path: "dvd3",
                    element:  <Kddl1Dvd3 />
                  },
                  {
                    path: "dvd4",
                    element:  <Kddl1Dvd4 />
                  },
                  {
                    path: "dvd5",
                    element:  <Kddl1Dvd5 />
                  }
                ]
              },
              {
                path: "kddl2",
                element: <RoleBasedRoute allowedRoles={['admin', 'paid']} />,
                children:[
                  {
                    path: "dvd2",
                    element:  <Kddl2Dvd2 />
                  }
                ]
              },
              // generate parent path kddl3 and children
              {path: "kddl3",
                element: <RoleBasedRoute allowedRoles={['admin', 'paid']} />,
                children: [
                  {
                    path: "tech1",
                    element:  <Kddl3Tech1 />
                  },
                  {
                    path: "tech2",
                    element:  <Kddl3Tech2 />
                  },
                  {
                    path: "tech3",
                    element:  <Kddl3Tech3 />
                  },
                  {
                    path: "tech4",
                    element:  <Kddl3Tech4 />
                  },
                  {
                    path: "tech5",
                    element:  <Kddl3Tech5 />
                  },
                  {
                    path: "tech6",
                    element:  <Kddl3Tech6 />
                  },
                  {
                    path: "tech7",
                    element:  <Kddl3Tech7 />
                  },
                  {
                    path: "tech8",
                    element:  <Kddl3Tech8 />
                  },
                  {
                    path: "tech9",
                    element:  <Kddl3Tech9 />
                  },
                  {
                    path: "tech10",
                    element:  <Kddl3Tech10 />
                  }
                ]
              },
              {
                path: "freedom-teaching",
                element: <RoleBasedRoute allowedRoles={['admin', 'paid']} />,
                children: [
                  {
                    path: "chapter1",
                    element:  <FreedomTeachingChapter1 />
                  },
                  {
                    path: "chapter2",
                    element:  <FreedomTeachingChapter2 />
                  }
                ]
              } 
            ]
          }
    ]
);

export default router;