import DivVideoPlayer from '../../components/common/DivVideoPlayer';

const Kddl3Tech6 = () => {

    return (
        <div className='Kddl3Tech6'>
            <div className="Kddl3Tech6Content">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <div className="wrapper">
                            <img src="/static/images/hostmasters2024.png" id="img-banner-hostmaster" />
                            <h1 id="h1-hostmaster">"Wind of Breath"</h1>
                        </div>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <div className="wrapper prevent-copy">
                                <div className="chapter-part">
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/14BzhBKgSUem2yq320maMOh_r-A71tVTT/preview' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default Kddl3Tech6;