export const users = [
    {
        id: 1,
        email: 'admin@sos.org',
        password: 'admin123', // In real app, passwords should be hashed
        role: 'admin',
        name: 'Admin User',
        token: 'admin-mock-token-123',
        permissions: ['read', 'write', 'delete', 'manage_users']
    },
    {
        id: 2,
        email: 'free@sos.org',
        password: 'free123',
        role: 'free',
        name: 'Free User',
        token: 'free-mock-token-456',
        permissions: ['read']
    },
    {
        id: 3,
        email: 'paid@sos.org',
        password: 'paid123',
        role: 'paid',
        name: 'Paid User',
        token: 'paid-mock-token-789',
        permissions: ['read']
    }
];