export const globalFunctions = {
    initializeLazyLoad: () => {
        console.log(`vannn initializeApp`)
        // lazy loading
            // document.addEventListener('DOMContentLoaded', function() {
            //     var lazyItems = document.querySelectorAll('.lazy-item');
            //     console.log(`vannn DOMContentLoaded`)
            //     var observer = new IntersectionObserver(function(entries, observer) {
            //         entries.forEach(function(entry) {
            //             if (entry.isIntersecting) {
            //                 console.log(`vannn isIntersecting`)
            //                 // When the div comes into the viewport, make it visible
            //                 loadLazyContent(entry.target);
            //             }
            //             if (!entry.isIntersecting) {
            //                 console.log(`vannn !isIntersecting`)
            //                 unloadContent(entry.target);
            //             }
            //         });
            //     }, { threshold: 0.01 }); // Adjust the threshold as needed

            //     lazyItems.forEach(function(item) {
            //         console.log(`vannn lazyItems.forEach`)
            //         observer.observe(item);
            //     })

            //     function loadLazyContent(item) {
            //         item.style.opacity = 1; // Make the div visible
            //     }

            //     function unloadContent(item) {
            //         item.style.opacity = 0;
            //     }
            // });
                
        //     document.addEventListener('contextmenu', function (e) {
        //         e.preventDefault();
        //     });

        //     document.addEventListener('copy', function (e) {
        //         e.preventDefault();
        //         // Optionally, you can display a message or take other actions here
        //     });
        
        var lazyItems = document.querySelectorAll('.lazy-item');
        console.log(`vannn DOMContentLoaded`)
        var observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    console.log(`vannn isIntersecting`)
                    // When the div comes into the viewport, make it visible
                    loadLazyContent(entry.target);
                }
                if (!entry.isIntersecting) {
                    console.log(`vannn !isIntersecting`)
                    unloadContent(entry.target);
                }
            });
        }, { threshold: 0.01 }); // Adjust the threshold as needed

        lazyItems.forEach(function(item) {
            console.log(`vannn lazyItems.forEach`)
            observer.observe(item);
        })

        function loadLazyContent(item) {
            item.style.opacity = 1; // Make the div visible
        }

        function unloadContent(item) {
            item.style.opacity = 0;
        }
    }
}

// jQuery(document).ready(function($){
//     var userip;
//     var d = new Date();

//     var month = d.getMonth()+1;
//     var day = d.getDate();

//     var currentdate = d.getFullYear() + '-' +
//         ((''+month).length<2 ? '0' : '') + month + '-' +
//         ((''+day).length<2 ? '0' : '') + day;
//     $.datepicker.setDefaults({ dateFormat: 'yy-mm-dd' });
//     $( "#datepicker" ).datepicker({
//         yearRange: "-100:-6",
//         changeMonth: true,
//         changeYear: true,
//         showOtherMonths: true,
//         selectOtherMonths: true,
//     });
//     $( "#wdatepicker" ).datepicker({
//         yearRange: "-15:-0",
//         changeMonth: true,
//         changeYear: true,
//         showOtherMonths: true,
//         selectOtherMonths: true,
//     });
//     $('#student_languages').multiSelect();
//     $('#ip_address').val( userip );
//     $('#Date_of_Submitting').val( currentdate );
//     $(':input').bind('keypress keydown keyup change', function(){
//         $('input[name="label:Place_of_Birth"]').val( $(':input[name="birth_city"]').val() + ' / ' + $(':input[name="birth_sate"]').val() + ' / ' + $(':input[name="birth_country"]').val() );
//     });

//     //---Soul Page Adding Background Image---\\
//     /*$('.imageText').each(function(){
//         $(this).css( "background-image", 'url("'+$(this).find('img').attr('src')+'")' );
//     });*/

//     //---Adding class "video" to iframe parent---\\
//     $('iframe').parent().addClass('video');

//     //---Adding class "imgCenter" to parent tag---\\
//     $('img').parent().addClass('imgCenter');

//     //Plasma uses
//     $('.scrollMenu a').click(function(elem){
//         elem.preventDefault();
//         var linkTarget = $(this).attr('href');
//         $('html, body').animate({
//             scrollTop: $(linkTarget).offset().top
//         }, 2000);
//     });
    
//     //home page menu show content
//     $('.close').click(function(){
//         $('.over-lay, body').removeClass('open');
//         $('#circle ul li, .information').removeClass('active');
//     });
//     $('#circle ul li a').click(function(elem){
//         elem.preventDefault();
//     });
//     $('#circle ul li').click(function(){
//         var curentClass = $(this).attr("data-id");
//         $('#circle ul li').not(this).removeClass('active');
//         if( !$('.information').hasClass('active') ){
//             $('.over-lay, body').toggleClass('open');
//         }
//         if( $(this).hasClass('active') ){
//             $('.over-lay, body').removeClass('open');
//         }
//         $(this).toggleClass('active');
//         $('.information').not('#'+curentClass).removeClass('active');
//         $('#'+curentClass).toggleClass('active');
//     });

//     $('.directPayment').click(function(){
//         $(this).find('.info').slideToggle();
//     });

//     var winWidth = $(window).width();

//     /*What is plasma - tab section*/
//     if(winWidth >= 769){
//         $('.tabHead a').click(function( elem ){
//             elem.preventDefault();
//             $(this).addClass('active');
//             $(this).siblings().removeClass('active');
//             var tabTarget = $(this).attr('href');
//             $(this).closest('.tabOuter').find('.itemContent').hide();
//             $('.tabBody '+tabTarget).show();
//         });
//         $('.tabHead a:first-child').click();
//     }
//     if(winWidth <= 768){
//         $('.tabBody .tabTitle').click(function( elem ){
//             elem.preventDefault();
//             $(this).parent().toggleClass('active');
//             $('.tabBody .tabTitle').not($(this)).parent().removeClass('active');
//             $('.tabBody .tabContents').not($(this).siblings()).slideUp();
//             $(this).siblings().slideToggle();
//         });
//         $('.tabBody .itemContent:first-child .tabTitle').click();
//     }

//     //Adding unique class to youtube's iframe
//     $('iframe[src*="https://www.youtube.com/embed/"]').addClass('youtube-iframe');

//     /*Change youtube playback function*/
//     $('.tabHead a').on('click', function(){
//         var divTarget = $(this).attr('href');
//         $('.youtube-iframe').each(function (){
//             if( $(this).closest(divTarget).length == 0 ){
//                 var video = $(this).attr("src");
//                 $(this).attr("src", "");
//                 $(this).attr("src", video);
//             }
//         });
//     });

//     /*Mobile toggle nav on click function*/
//     $('.mobile-nav').click(function(){
//         $(this).toggleClass('open');
//         $('#site-navigation').toggleClass('active');
//     });

//     $('.menu-item').click(function() {
//         $('.mobile-nav').click();
//     })


//     /*Stop youtube video on tab change*/
// });