function DivVideoPlayer({ src }) {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px', // Optional: maximum width
        margin: '0 auto',   // Optional: center the container
    };

    const wrapperStyle = {
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%', // 16:9 Aspect ratio
    };

    const iframeStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 0,
    };

    return (
        <div style={containerStyle}>
            <div style={wrapperStyle}>
                <iframe
                    style={iframeStyle}
                    src={src}
                    allowFullScreen
                    title="Video Player"
                />
            </div>
        </div>
    );
};

export default DivVideoPlayer;