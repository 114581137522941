import DivVideoPlayer from '../../components/common/DivVideoPlayer';
import { useEffect } from 'react';
import { globalFunctions } from '../../utils/main';

const Kddl3Tech9 = () => {

    useEffect(() => {
        console.log('App component mounted');
        
         // Check if document is already loaded
         if (document.readyState === 'loading') {
          document.addEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        } else {
          globalFunctions.initializeLazyLoad();
        }
    
        // Cleanup
        return () => {
          document.removeEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        };
    
      }, []);

    return (
        <div className='Kddl3Tech9'>
            <div className="Kddl3Tech9Content">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <div className="wrapper">
                            <img src="/static/images/hostmasters2024.png" id="img-banner-hostmaster" />
                            <h1 id="h1-hostmaster">SPARK of the ARC-1 Transmission</h1>
                        </div>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <div className="wrapper prevent-copy">
                                <div className="chapter-part">
                                    <h6 className="lazy-item" id="heading-1-1">Kích hoạt Arc Spark 1</h6>
                                    <div className="textContent lazy-item">
                                        <p className='imgCenter'> <img src="/static/images/Spark-Transmission.jpg" /></p>
                                        <p>
                                            Hít (hình ảnh Spark Transmission) vào trái tim Eff-E’ mah point (giữ hơi 1 chút).
                                            <br />
                                            Thở ra đẩy vào hạt giống Nomi Seed ở lõi trái đất “Earth Core”
                                            <br />
                                            Hít lên lại vào trái tim của Eff-E’mah (giữ hơi 1 chút)
                                            <br />
                                            Thở ra đi lên qua Tran”-TE-in window (cách đỉnh đầu 36 inch “91 centimet” – Luân xa 14) và đẩy hướng về phía YhU Rha LA Kinservatory (YKIN) và nói/nói thầm YhU Rha-LA Kinservatory (3 lần)
                                            <br />
                                            <b>Bước tiếp theo xem Video & nhận sóng:</b>

                                            <DivVideoPlayer src="https://drive.google.com/file/d/1YkM5FGyOLs8xLh0k07fH1qg2Jfgdazi9/preview" />
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default Kddl3Tech9;