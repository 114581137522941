// src/components/Header.js
function Header() {
  const baseUrl = process.env.REACT_APP_URL
    return (
      <header id="masthead" className="site-header">
        <div className="site-branding">
          <a href={baseUrl} className="custom-logo-link" rel="home" itemProp="url">
            <img 
              width="500"
              height="501" 
              src="/static/images/eye.webp" 
              className="custom-logo"
              alt="School of Souls" 
              itemProp="logo"
              sizes="(max-width: 500px) 100vw, 500px" 
            />
          </a>
        </div>
      </header>
    );
  }
  
  export default Header;
  