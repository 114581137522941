// components/RoleBasedRoute.js
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RoleBasedRoute = ({ allowedRoles }) => {
  const { user } = useAuth();
  const location = useLocation();


  if (!user) {
    // Not logged in, redirect to login page
    return <Navigate to="/login" state={{ from: location.pathname }} replace={true} />;
  }

  if (!allowedRoles.includes(user.role)) {
    // User's role is not authorized, redirect to home page
    return <Navigate to="/unauthorized" replace={true} />;
  }

  // Authorized, render child routes
  return <Outlet />;
};

export default RoleBasedRoute;
