// src/components/Unauthorized.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Unauthorized.module.css';

const Unauthorized = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.unauthorizedContainer}>
            <div className={styles.content}>
                <div className={styles.iconWrapper}>
                    <svg
                        className={styles.icon}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15 9L9 15"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9 9L15 15"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <h1 className={styles.title}>Access Denied</h1>
                <p className={styles.message}>Bạn không được phép truy cập vào nội dung này. Vui lòng liên hệ Ban Quản trị ở Trang chủ!</p>
                <button
                    className={styles.button}
                    onClick={() => navigate('/')}
                >
                    Trở về Trang chủ
                </button>
            </div>
        </div>
    );
};

export default Unauthorized;
