// // src/components/Layout/index.js
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SEO from './common/SEO';
import Header from './common/Header';
import Footer from './common/Footer';
import styles from './Layout.module.css';


function Layout() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  return (
    <div className={styles.wrapper}>
      <div id="page" className={styles.site}>
        <SEO />
        {/* Conditional header rendering */}
        {!isHomePage && (
          <Header />
        )}
        <main className={styles.content}><Outlet /></main>
        {/* Conditional footer rendering */}
        {!isHomePage && (
          <Footer />
        )}
      </div>
    </div>

  );
}

export default Layout;
