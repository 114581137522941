// src/App.js
import { RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home/Home';
import router from './Router';
import './App.css';
import { useEffect } from 'react';
import { globalFunctions } from './utils/main';
import { AuthProvider } from './context/AuthContext';

function App() {
  useEffect(() => {
    console.log('App component mounted');

    // Check if document is already loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
    } else {
      globalFunctions.initializeLazyLoad();
    }

    // Cleanup
    return () => {
      document.removeEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
    };

  }, []);
  return (
    // <Router>
    //   <Layout>
    //     <Routes>
    //       <Route index path="/" element={<Home />}>
    //         {/* <Route element={<Home />} /> */}
    //         {/* Add other routes here */}
    //       </Route>
    //     </Routes>
    //   </Layout>
    // </Router>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>

  );
}

export default App;
