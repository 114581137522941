// src/pages/Home/index.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faCircleRight, faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import './Home.css';


const Home = () => {
  const baseUrl = process.env.REACT_APP_URL;
  const menuItems = [
    { id: '00', number: '00', label: 'Home', isActive: true },
    { id: '01', number: '01', label: 'Lời mở đầu' },
    { id: '02', number: '02', label: 'TÂM THỨC THỨC TỈNH – CHÚNG TA LÀ AI?' },
    { id: '03', number: '03', label: 'TRẬT TỰ ẨN GIẤU: TỪ TÂM THỨC ĐẾN VẬT CHẤT' },
    { id: '04', number: '04', label: 'THOÁT KHỎI NHÀ TÙ' },
    { id: '05', number: '05', label: 'TRÁI ĐẤT ĐANG VƯƠN LÊN' },
    { id: '06', number: '06', label: 'HÀNH TRÌNH CHỮA LÀNH VÀ CHUYỂN HÓA' },
    { id: '07', number: '07', label: 'Sức Khỏe' },
    { id: '08', number: '08', label: 'Liên hệ' },
    { id: '09', number: '09', label: 'Học tập cộng đồng' },
    { id: '10', number: '10', label: 'Dịch vụ riêng' }
  ];

  const posters = [
    { id: 1, imgUrl: 'static/images/14s.png', content: 'Buông bỏ những suy nghĩ giới hạn để nâng cao linh hồn. Chúng ta là người tạo ra nhà tù tinh thần của chính mình. Mọi người chưa hiểu biết ý nghĩa của Co-Creation (cùng sáng tạo với Nguồn).' },
    { id: 2, imgUrl: 'static/images/15s.png', content: 'Chỉ khi tôi dám nghĩ đến mục tiệu nào thì tôi chỉ có thể phát triển tới đó, chỉ khi tôi muốn tìm kiếm đến đâu thì tôi chỉ có thể đến đó, chỉ khi tôi muốn nhìn bao sâu thì tôi chỉ có thể thấy tới đó, chỉ khi tôi dám mơ mộng bao nhiêu thì tôi chỉ có thể thể hiện tới đó.' },
    { id: 3, imgUrl: 'static/images/14s.png', content: 'Sự nhận thấy hoặc cách nhìn đời là nhận thức được định hình bởi niềm tin. Niềm tin "điều khiển" nhận thức. Thay đổi niềm tin thì bạn sẽ thay đổi cách nhìn đời. Thay đổi cách nhìn đời thì bạn sẽ thay đổi gene và hành vi... Tôi được tự do thay đổi cách tôi phản ứng với thế giới, vì vậy khi tôi thay đổi cách tôi nhìn thế giới, tôi thay đổi biểu hiện di truyền (thay đổi gene) của mình. Chúng ta không phải là nạn nhận của gene của chính mình. Chúng ta là minh sư và chủ nhân của gene mình.' },
    { id: 4, imgUrl: 'static/images/15s.png', content: 'Tâm thức là năng lượng. Năng lượng là tâm thức. Linh hồn là những khái niệm tâm thức có về bản thân. Nguồn là một cái tâm thức thập cẩm. Nếu muốn nói một cách đơn giản, thì tâm linh là sự phát triển của tâm thức qua những trải nghiệm cuộc sống hàng ngày. Nó là con đường đi khám phá của tâm thức, khởi đầu khi tách biệt từ Nguồn, rồi sau một thời gian sẽ quay trở lại về Nguồn để trở thành một thêm một lần nữa.' },
    { id: 5, imgUrl: 'static/images/14s.png', content: 'Khoa học Tâm Linh là con đường nghiên cứu về sự thể hiện và cấu trúc của Nguồn, mà chúng ta chưa thấy được ở giai đoạn này trong sự phát triển tâm thức của chúng ta. Nó không chỉ liên quan đến ma quỷ, pháp sư hoặc giai đoạn tỉnh thức, mà nó bao gồm tất cả về vũ trụ, phi thuyền, và người ngoài hành tinh. Tất cả đều là sự thể hiện của Nguồn. Sự khác biệt giữa Nguồn và tất cả là những khái niệm chúng có về bản thân và thế giới.' },
    { id: 6, imgUrl: 'static/images/15s.png', content: 'Những gì chúng ta nghĩ, chúng ta tạo ra. Những gì chúng ta cảm nhận, chúng ta thu hút. Những gì chúng ta tưởng tượng, chúng ta trở thành. Hãy chú ý đến những gì bạn nghĩ, cảm nhận và tưởng tượng. Chúng ta tạo ra thế giới mà chúng ta muốn trải nghiệm. Quan điểm của bạn sẽ quyết định chất lượng cuộc sống của bạn.' }
  ]

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // State management
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [activeInformation, setActiveInformation] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [currentPoster, setCurrentPoster] = useState(1);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  // Handle close button click
  const handleClose = () => {
    setIsOpen(false);
    setActiveItem(null);
    setActiveInformation(null);
  };

  // Handle circle item click
  const handleCircleClick = (dataId) => {
    // Toggle active state for clicked item
    if (activeItem === dataId) {
      setActiveItem(null);
      setIsOpen(false);
      setActiveInformation(null);
    } else {
      setActiveItem(dataId);
      setIsOpen(true);
      setActiveInformation(dataId);
    }
  };

  // Handle menu link click
  const handleMenuClick = (e, menuId) => {
    e.preventDefault();
    e.stopPropagation();

    setActiveSubMenu(activeSubMenu === menuId ? null : menuId);
  };

  const handleClosePoster = () => {
    document.getElementById('poster-slider').style.display = 'none';
    document.getElementById('home-content').style.opacity = 1;
  };

  const handlePrevPoster = () => {
    setIsAutoPlaying(false); // Pause auto-rotation
    setCurrentPoster(currentPoster === 1 ? posters.length : currentPoster - 1);
  }

  const handleNextPoster = () => {
    setIsAutoPlaying(false); // Pause auto-rotation
    setCurrentPoster(currentPoster === posters.length ? 1 : currentPoster + 1);
  }

  useEffect(() => {
    let interval;

    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentPoster(current =>
          current === posters.length ? 1 : current + 1
        );
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [isAutoPlaying, posters.length]);

  return (
    <div className='home'>

      <div id="home-root-content">
        <div id="bg">
          <div className='milky'></div>
        </div>
        <div id="home-content">
          <div id="circle">
            <nav>
              <div className="menu-header-menu-container">
                <ul id="primary-menu" className="menu">
                  {menuItems.map(item => {
                    return (
                      <li
                        key={item.number}
                        id={`menu-item-${item.number}`}
                        className={`menu-item-${item.number} ${activeItem === item ? 'active' : ''}`}
                        onClick={() => handleCircleClick(item.number)}
                      >
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <span>{item.number}</span><label>{item.label}</label>
                        </a>
                      </li>
                    );
                  })}

                </ul>
              </div>
            </nav>
            <div className="line">
              <img src="/static/images/spiral.png" alt="" />
            </div>
          </div>
          <div className="logo"><a href="#"><img src="static/images/eye.webp" alt="" /></a></div>
          <div className="info">
            <h1>School<br /> of Souls</h1>
            <h5 >Nơi tìm về bản thể</h5>
          </div>
        </div>
        <div className={`over-lay ${isOpen ? 'open' : ''}`}>
          <div className="close" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmarkCircle} />
          </div>
          <section key={menuItems.at(0).number} id={`menu-content-${menuItems.at(0).number}`} className={`information ${activeInformation === menuItems.at(0).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Home</h1>
              <div className="intro-text">
                <p>School of Souls</p>
              </div>
              <a className="submit-btn" href={baseUrl}>Tìm hiểu thêm</a>
            </div>
          </section>
          <section key={menuItems.at(1).number} id={`menu-content-${menuItems.at(1).number}`} className={`information ${activeInformation === menuItems.at(1).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Lời mở đầu</h1>
              <div className="intro-text wrap-overflow">
                <p>Những gì viết trên School of Souls là tập hợp từ nhiều nguồn và trải nghiệm cá nhân của tôi trên hành trình quay về Suối Nguồn Bên Trong. Nội dung không nhằm thuyết phục hay chứng minh điều gì với bất kỳ ai. Tất cả thông tin, kiến thức và sơ đồ trong School of Souls có nguồn gốc từ "Tài liệu Người Giám hộ" do E’Asha Arhayas và Công ty Arhays Production cung cấp, cũng như từ các học trò của bà như Ms. Ari Rishi từ TrhUAh School.
                  <br />
                  Tôi khuyên bạn nên tiếp cận trực tiếp với nguyên tác. Tôi tin rằng khi nghiên cứu trực tiếp tài liệu gốc, ý nghĩa và tinh thần của các thông tin sẽ được hiểu một cách trọn vẹn hơn. School of Souls chỉ là một ngọn hải đăng nhỏ giới thiệu sự tồn tại của "Tài liệu Người Giám hộ" trên thế giới. Việc khám phá rộng lớn đại dương tri thức đó là sự lựa chọn của mỗi độc giả.
                  <br />
                  Tôi để lại phần còn lại cho sự tự do lựa chọn của bạn.
                </p>
                <p>
                  Lưu ý: <br />
                  Những tài liệu gốc và biểu đồ được trích dẫn trong School of Souls thuộc quyền sở hữu trí tuệ của E’Asha A. Arhayas và Arhayas Production. School of Souls chỉ dịch và giải thích cho mục đích nghiên cứu và giáo dục, tuân thủ quyền sở hữu trí tuệ theo nguyên tắc Sử dụng Hợp lý. Cấm sao chép và phân phối nội dung khi chưa được phép.
                  <br />
                  Arhayas Production. Bảo lưu mọi quyền.
                  <br />
                  <a href="https://www.arhayas.com">www.arhayas.com</a>
                  <br />
                  Các tài liệu và công nghệ liên quan của "Tài liệu Người Giám Hộ" chỉ nhằm mục đích khám phá lý thuyết, không phải để chẩn đoán, điều trị hay chữa bệnh.
                </p>
                <p>
                  Tôi cảm ơn và tôn vinh đội ngũ những “Bản thể cao hơn của tôi”, các thiên thần đời thật đã giúp đỡ tôi hoàn thiện nội dung và thiết kế School of Souls như một sự lưu giữ thông tin và mang đến tri thức sự thật cho nhân loại. Gửi đến những người cộng sự của tôi, Lý Anh Nhân, Nguyễn Bảo Hồng Minh và Nguyễn Mạnh Văn đã hướng dẫn và hỗ trợ tôi trong suốt quá trình thực hiện. Tôi vô cùng biết ơn các tác phẩm của E’Asha Arhayas và Ms. Ari Rishi, cảm ơn các cô đã truyền cảm hứng và là nền tảng nội dung cho School of Souls.
                </p>
              </div>
            </div>
          </section>
          <section key={menuItems.at(2).number} id={`menu-content-${menuItems.at(2).number}`} className={`information ${activeInformation === menuItems.at(2).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Tâm thức thức tỉnh - Chúng ta là ai?</h1>
              <div className="intro-text">
                <ol type="1">
                  <li>QUY LUẬT CỦA TÂM THỨC – NĂNG LƯỢNG – SỰ TỒN TẠI</li>
                  <li>CẤP ĐỘ BẢN NGÃ TRONG CÁC CHIỀU TÂM THỨC KHÁC: HÀNH TRÌNH KHÁM PHÁ BẢN THỂ ĐÍCH THỰC CỦA CON NGƯỜI</li>
                  <li>MẪU HÌNH ẢNH KHÔNG GIAN BA CHIỀU</li>
                  <li>VŨ TRỤ KHUÔN MẪU HOLOGRAM (HOLOGRAPHIC MANIFESTATION TEMPLATE)</li>
                  <li>TỪ MỘT SINH RA HAI</li>
                  <li>KRYST: SỰ MÃ HÓA CUỘC SỐNG VĨNH CỬU</li>
                  <li>HAI ĐƯỜNG CONG TĂNG TRƯỞNG NĂNG LƯỢNG</li>
                  <li>HAI HẠT GIỐNG TRONG CHÚNG TA</li>
                  <li>SỰ TRỞ VỀ VỚI TRẠNG THÁI TRONG SÁNG BAN ĐẦU (RETURN TO INNOCENCE)</li>
                </ol>
              </div>
              <Link to='/freedom-teaching/chapter1' className="submit-btn">Tìm hiểu thêm</Link>
            </div>
          </section>
          <section key={menuItems.at(3).number} id={`menu-content-${menuItems.at(3).number}`} className={`information ${activeInformation === menuItems.at(3).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">TRẬT TỰ ẨN GIẤU: TỪ TÂM THỨC ĐẾN VẬT CHẤT</h1>
              <div className="intro-text">
                <ol type="1">
                  <li>LOÀI NGƯỜI ĐƯỢC SINH RA NHƯ THẾ NÀO?</li>
                  <li>NHẬN BIẾT BẢN NGÃ “I AM” VÀ CẤU TRÚC TÂM LÝ</li>
                  <li>VỀ SỰ CHUYỂN TIẾP CÁI CHẾT</li>
                  <li>TƯ TƯỞNG LÀ VẬT CHẤT</li>
                  <li>ĐIỀU KHIỂN NGUYÊN TỐ VÀ LƯỢNG TỬ ÁNH SÁNG</li>
                </ol>
              </div>
              <Link to='/freedom-teaching/chapter2' className="submit-btn">Tìm hiểu thêm</Link>
            </div>
          </section>
          <section key={menuItems.at(4).number} id={`menu-content-${menuItems.at(4).number}`} className={`information ${activeInformation === menuItems.at(4).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">THOÁT KHỎI NHÀ TÙ</h1>
              <div className="intro-text">

              </div>
              <a className="submit-btn" href="#">Tìm hiểu thêm</a>
            </div>
          </section>
          <section key={menuItems.at(5).number} id={`menu-content-${menuItems.at(5).number}`} className={`information ${activeInformation === menuItems.at(5).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">TRÁI ĐẤT ĐANG VƯƠN LÊN</h1>
              <div className="intro-text">

              </div>
              <a className="submit-btn" href={baseUrl}>Tìm hiểu thêm</a>
            </div>
          </section>
          <section key={menuItems.at(6).number} id={`menu-content-${menuItems.at(6).number}`} className={`information ${activeInformation === menuItems.at(6).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">HÀNH TRÌNH CHỮA LÀNH VÀ CHUYỂN HÓA</h1>
              <div className="intro-text">

              </div>
              <a className="submit-btn" href="#">Tìm hiểu thêm</a>
            </div>
          </section>
          <section key={menuItems.at(7).number} id={`menu-content-${menuItems.at(7).number}`} className={`information ${activeInformation === menuItems.at(7).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Sức Khỏe</h1>
              <div className="intro-text wrap-overflow">
                <p>Đây là nơi chúng tôi tổng hợp và chia sẻ những kiến thức, thông tin hữu ích và đáng tin cậy về phát triển một cơ thể khỏe mạnh dựa trên nghiên cứu về phương pháp năng lượng thông qua ăn uống của thầy Phạm Tám. Từ những bài viết chuyên sâu giải thích các nguyên lý của phương pháp đến việc ứng dụng chúng vào thực tế, chúng tôi mong muốn cung cấp cho bạn một cái nhìn toàn diện và chi tiết về cách thức cải thiện sức khỏe theo hướng tiếp cận của thầy Phạm Tám. Hãy cùng khám phá và trang bị cho mình những tri thức quý giá này để xây dựng lối sống lành mạnh và một cơ thể khỏe khoắn, tràn đầy sinh lực nhé! Vì lý do bản quyền, chúng tôi chỉ chia sẻ nội bộ các bài videos. Nếu có điều kiện, các bạn hãy ủng hộ các khoá học khác chính thống từ thầy. Chân thành cảm ơn các bạn!
                </p>
                <p>
                  Lưu ý: Các kiến thức sức khỏe được trình bày trong chuyên mục này có thể khác biệt so với những gì được giảng dạy trong chương trình giáo dục chính thống từ cô E'Asha. Chúng tôi khuyến khích bạn tiếp cận những thông tin này với một tâm thế cởi mở, tích cực và tránh đưa ra những phán xét vội vàng. Hãy xem đây là một nguồn tài liệu tham khảo bổ sung để mở rộng hiểu biết và tìm ra phương thức phù hợp nhất cho hành trình cải thiện sức khỏe của chính mình.
                </p>
              </div>
              <Link to="/health" className="submit-btn">Tìm hiểu thêm</Link>
            </div>
          </section>
          <section key={menuItems.at(8).number} id={`menu-content-${menuItems.at(8).number}`} className={`information ${activeInformation === menuItems.at(8).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Liên hệ</h1>
              <div className="intro-text">
                <p>Nguyen Du Yen Nhi<br />
                  Email: <a href="mailto: schoolofsouls4444@gmail.com">schoolofsouls4444@gmail.com</a><br />
                  Phone/Zalo/Whatsapp/Telegram: <a href="tel:+84902974036">+84 902 974 036</a><br />
                  {/* TP Bank: Nguyen Du Yen Nhi - 03666506301<br />
								Momo: +84 902 974 036 */}
                </p>
              </div>
              {/* <a className="submit-btn" href="#">Tìm hiểu thêm</a> */}
            </div>
          </section>
          <section key={menuItems.at(9).number} id={`menu-content-${menuItems.at(9).number}`} className={`information ${activeInformation === menuItems.at(9).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Học tập cộng đồng</h1>
              <div className="intro-text">
                <p>Các tài liệu học tập cộng đồng đang hỗ trợ các ngôn ngữ sau:</p>
                <p>English<br />
                  Vietnamese / Tiếng Việt</p>
              </div>
              <a className="submit-btn" href="#">Tìm hiểu thêm</a>
            </div>
          </section>
          <section key={menuItems.at(10).number} id={`menu-content-${menuItems.at(10).number}`} className={`information ${activeInformation === menuItems.at(10).number ? 'active' : ''}`}>
            <div className="wrap">
              <h1 className="title">Dịch vụ riêng</h1>
              <div className="intro-text">
                <div className="menu-link">
                  <a href="#"
                    onClick={(e) => handleMenuClick(e, 'hostmaster2024')}
                  >HostMaster 2024</a>
                  <ul className={`sub-menu ${activeSubMenu === 'hostmaster2024' ? 'active' : ''}`}>
                    <li>
                      <Link to="/hostmaster2024/1A">HostMasters 2024 - Session 1A</Link>
                    </li>
                    <li>
                      <Link to="/hostmaster2024/1B">HostMasters 2024 - Session 1B</Link>
                    </li>
                  </ul>
                </div>
                <div className="menu-link">
                  <a href="#"
                    onClick={(e) => handleMenuClick(e, 'kddl1')}
                  >KDDL1</a>
                  <ol className={`sub-menu ${activeSubMenu === 'kddl1' ? 'active' : ''}`}>
                    <li>
                      <Link to="/kddl1/dvd1" >DVD 1</Link>
                    </li>
                    <li>
                      <Link to="/kddl1/dvd2" >DVD 2</Link>
                    </li>
                    <li>
                      <Link to="/kddl1/dvd3" >DVD 3</Link>
                    </li>
                    <li>
                      <Link to="/kddl1/dvd4" >DVD 4</Link>
                    </li>
                    <li>
                      <Link to="/kddl1/dvd5" >DVD 5</Link>
                    </li>
                  </ol>
                </div>
                <div className="menu-link">
                  <a href="#"
                    onClick={(e) => handleMenuClick(e, 'kddl2')}
                  >KDDL2</a>
                  <ol className={`sub-menu ${activeSubMenu === 'kddl2' ? 'active' : ''}`}>
                    <li>
                      <Link to="/kddl2/dvd2" >DVD 2</Link>
                    </li>
                  </ol>
                </div>
                <div className="menu-link">
                  <a href="#"
                    onClick={(e) => handleMenuClick(e, 'kddl3')}>KDDL3</a>
                  <ol className={`sub-menu ${activeSubMenu === 'kddl3' ? 'active' : ''}`}>
                    <li>
                      <Link to='/kddl3/tech1'>Technique 1 - EFFI Homestar</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech2'>Technique 2 - Cracking the shell of contention</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech3'>Technique 3 - Elemental Clearing Journey</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech4'>Technique 4 - Journey to the Rainbow Reservoirs of the Nomi'-yah Sun-8 Cathedrals</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech5'>Technique 5 - Eff-im-a'-rhal Manifestation (Intro):  Plasma Seeding through the Heart of Eff-E'-Mah Rainbow Round</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech6'>Technique 6 - "Wind of Breath"</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech7'>Technique 7 - Mahadra-Adhrana Nomi-Seed</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech8'>Technique 8 - FOY Flows Activation</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech9'>Spark of the ARC-1 Transmission</Link>
                    </li>
                    <li>
                      <Link to='/kddl3/tech10'>Sparks of Freedom - Aurora Flame 1 - Mirror Ball Activation</Link>
                    </li>
                  </ol>
                </div>
                <div className="menu-link">
                  <a href="#"
                    onClick={(e) => handleMenuClick(e, 'freetech')}
                  >Free Technique</a>
                  <ul className={`sub-menu ${activeSubMenu === 'freetech' ? 'active' : ''}`}>
                    <li>
                      <Link to="/free-tech/1">Journey To The ARI-ARhAyas AL-Uma-UN Core Of The Krystar Seed Atom</Link>
                    </li>
                    <li>
                      <Link to="/free-tech/2">Journey to ARhAyas Island</Link>
                    </li>
                    <li>
                      <Link to="/free-tech/3">Journey to the Eff-i-yah State - EarthCync</Link>
                    </li>
                    <li>
                      <Link to="/free-tech/4">The EFFI Prayer & LTR (Linguistic-Template-Reprogramming) - Power Grid</Link>
                    </li>
                    <li>
                      <Link to="/free-tech/5">The TRIUMPH of Triumphs Quick-Link EarthCync™ Technique</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="poster-slider">
          <div className="div-slider-arrow">
            <button className="slider-arrow" id="prev-arrow" onClick={handlePrevPoster}>
              <FontAwesomeIcon icon={faCircleLeft} />
            </button>
          </div>
          <div id="poster-center">
            <button className="close-button" onClick={handleClosePoster}>
              <FontAwesomeIcon icon={faXmarkCircle} />
            </button>
            {
              posters.map(poster => {
                return (
                  <div key={poster.id} className={`poster ${currentPoster === poster.id ? 'active' : ''}`} style={{ backgroundImage: `url('${poster.imgUrl}')` }}>
                    <div className="poster-content">
                      <p>{poster.content}</p>
                    </div>
                  </div>
                )
              })
            }

          </div>
          <div className="div-slider-arrow">
            <button className="slider-arrow" id="next-arrow" onClick={handleNextPoster}>
              <FontAwesomeIcon icon={faCircleRight} />
            </button>
          </div>

        </div>
      </div>
    </div>

  );
};

export default Home;
