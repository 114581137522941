import DivVideoPlayer from '../../components/common/DivVideoPlayer';
import { useEffect } from 'react';
import { globalFunctions } from '../../utils/main';

const Kddl1Dvd2 = () => {

    useEffect(() => {
        console.log('App component mounted');
        
         // Check if document is already loaded
         if (document.readyState === 'loading') {
          document.addEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        } else {
          globalFunctions.initializeLazyLoad();
        }
    
        // Cleanup
        return () => {
          document.removeEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        };
    
      }, []);

    return (
        <div className='hostmaster20241B'>
            <div className="hostmaster1BContent">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <div className="wrapper">
                            <img src="/static/images/hostmasters2024.png" id="img-banner-hostmaster" />
                            <h1 id="h1-hostmaster">KDDL1 - DVD2</h1>
                        </div>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <div className="wrapper prevent-copy">

                                <div className="chapter-part">
                                    <h6 className="lazy-item" id="heading-1-1">Technical 1: Cha-TrhU Activation</h6>
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1NeOUo_5QtI2mibmg1WYMOZiOYXYL49YA/preview' />
                                    </div>
                                    <br />
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1NksSwV7If4a-l9lwIDEjiXl1--mrlZtn/preview' />
                                    </div>
                                </div>
                                <div className="chapter-part">
                                    <h6 className="lazy-item" id="heading-1-2">Technical 2: TrhU'-ah Body Activation</h6>
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1NiX3xqv6S0wAaMBgK-phVaVyWt6Jxlfi/preview' />
                                    </div>
                                    <div className="textContent">
                                        <DivVideoPlayer src='https://drive.google.com/file/d/1NmtMxPJGoO7N7qGdI7HHWtCB6SFmt9Ic/preview' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default Kddl1Dvd2;