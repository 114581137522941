// src/components/SEO.js
import { Helmet } from 'react-helmet';

function SEO() {
  return (
    <Helmet>
      <title>School of Souls</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="title" content="School of Souls" />
      <meta name="description" content="Nơi tìm về bản thể" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://schoolofsouls.org/" />
      <meta property="og:title" content="School of Souls" />
      <meta property="og:description" content="Nơi tìm về bản thể" />
      <meta property="og:image" content="/static/images/eye.webp" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://schoolofsouls.org/" />
      <meta property="twitter:title" content="School of Souls" />
      <meta property="twitter:description" content="Nơi tìm về bản thể" />
      <meta property="twitter:image" content="/static/images/eye.webp" />

      <meta name="robots" content="noindex,nofollow" />
      <link rel="icon" type="image/png" href="/favicon.png" />
      
      {/* CSS imports */}
      <link rel="stylesheet" href="/static/css/styles.css" />
      <link rel="stylesheet" href="/static/css/style.css" />
      <link rel="stylesheet" href="/static/css/fonts.css" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" />
      <link rel="stylesheet" href="/static/css/fullcalendar.min.css" />
      <link rel="stylesheet" href="/static/css/multi-select.css" />
      <link rel="stylesheet" href="/static/jquery-ui/jquery-ui.min.css" />
    </Helmet>
  );
}

export default SEO;
