import DivVideoPlayer from '../../components/common/DivVideoPlayer';
import { useEffect } from 'react';
import { globalFunctions } from '../../utils/main';
import MobileNavigation from '../../components/common/MobileNavigation';

const FreedomTeachingChapter2 = () => {
    const baseUrl = process.env.REACT_APP_URL;
    useEffect(() => {
        console.log('App component mounted');

        // Check if document is already loaded
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        } else {
            globalFunctions.initializeLazyLoad();
        }

        // Cleanup
        return () => {
            document.removeEventListener('DOMContentLoaded', globalFunctions.initializeLazyLoad);
        };

    }, []);

    return (
        <div className='FreedomTeachingChapter1'>
            <div className="hostmaster1AContent">
                <section className="plasmaScience">
                    <div className="sectionBanner mainBanner flexContainer">
                        <video width="100%" height="100%" autoplay="" loop="" muted="" __idm_id__="1245185">
                            <source src="/static/videos/solar_system.mp4" type="video/mp4" />
                        </video>
                        <h1>Chương 2: Trật tự ẩn giấu: <br /> Từ Tâm thức đến vật chất</h1>
                    </div>
                    <div>
                        <div className="content bg-charcoal-1 padSection">
                            <nav id="site-navigation" className="main-navigation">
                                <div className="menu-header-menu-container">
                                    <ul id="primary-menu" className="menu">
                                        <li id="menu-item-49"
                                            className="menu-item   menu-item-home current-menu-item page_item page-item-38 current_page_item menu-item-49">
                                            <a href={baseUrl}><span>00</span><label>Home</label></a></li>
                                        <li id="menu-item-36"
                                            className="menu-item   menu-item-36"><a
                                                href="#heading-1-1"><span>01</span><label>LOÀI NGƯỜI ĐƯỢC SINH RA NHƯ THẾ NÀO?</label></a></li>
                                        <li id="menu-item-33"
                                            className="menu-item   menu-item-33"><a
                                                href="#heading-1-2"><span>02</span><label>NHẬN BIẾT BẢN NGÃ "I AM" VÀ CẤU TRÚC TÂM LÝ</label></a></li>
                                        <li id="menu-item-32"
                                            className="menu-item   menu-item-32"><a
                                                href="#heading-1-3"><span>03</span><label>VỀ SỰ CHUYỂN TIẾP CÁI CHẾT</label></a></li>
                                        <li id="menu-item-28"
                                            className="menu-item   menu-item-28"><a
                                                href="#heading-1-4"><span>04</span><label>TƯ TƯỞNG LÀ VẬT CHẤT</label></a></li>
                                        <li id="menu-item-37"
                                            className="menu-item   menu-item-37"><a
                                                href="#hea ding-1-5"><span>05</span><label>ĐIỀU KHIỂN NGUYÊN TỐ VÀ LỰC LƯỢNG ÁNH SÁNG</label></a></li>
                                    </ul>
                                </div>
                            </nav>

                            <MobileNavigation />
                            <div className="wrapper prevent-copy">
                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-1">1.1	QUY LUẬT CỦA Ý THỨC – NĂNG LƯỢNG – SỰ TỒN TẠI</h6>
                                    <div className="textContent lazy-item">
                                        <p>Con người chúng ta là những ý thức - năng lượng khoác lên mình chiếc áo vật chất (thân xác). Ý thức là bản chất cốt lõi đằng sau mọi sự sống và thực tại. Năng lượng ý thức có nguồn gốc và xuất phát từ một cội nguồn (Source). Ý thức rời khỏi nguồn gốc mà nó sinh ra, trải qua vô số chiều không gian thực tại trong vũ trụ để trưởng thành và tiến hóa. Tất cả chúng ta sinh ra đều có trách nhiệm đối với việc chỉ huy năng lượng ý thức của mình. Cụm từ khác của trách nhiệm là tự do ý chí. Mỗi con người đều điều khiển năng lượng ý thức của mình thông qua tự do ý chí.</p>
                                        <p>Vậy điều khiển năng lượng có nghĩa là gì? Chúng ta sống và tồn tại mỗi ngày bằng cách điều khiển năng lượng của suy
                                            nghĩ, cảm xúc, và thể chất. Đây là những nguồn năng lượng cho phép chúng ta tồn tại và sống trong thực tại. Vậy suy
                                            nghĩ và cảm xúc có phải là ý thức không? Có phải suy nghĩ tạo ra ý thức? Tất cả đều không phải.</p>
                                        <p>Ý thức (Consciousness) tạo ra hoạt động năng lượng của suy nghĩ, cảm xúc và thể chất của chúng ta. Suy nghĩ, cảm xúc
                                            và thể chất là những công cụ năng lượng mà ý thức sử dụng để tham gia vào lĩnh vực kinh nghiệm gọi là thực tại. Việc
                                            chúng ta đang sống, thở và tồn tại là đang trải nghiệm một cách có ý thức thông qua bản thể “tôi” trong không gian
                                            ba chiều. Mặc dù tôi đang tập trung ý thức vào kinh nghiệm thực tại ở đây và bây giờ, về bản chất ý thức không bị
                                            giới hạn hay ràng buộc trong lĩnh vực thực tại này. Ý thức quan sát và cùng trải nghiệm cuộc sống thông qua cái tôi.
                                        </p>
                                        <p>Bản chất ý thức đã đem chúng ta đến đây, sinh ra ở nơi này trong kiếp sống này. Ý thức có nhiều cách gọi khác nhau.
                                            Linh hồn (Spirit) cũng là một trong số đó. Linh hồn là một trong vô số các dạng thể năng lượng (template – mẫu hình
                                            thánh thiêng liêng) mà ý thức mặc lên. Ý thức không bị giới hạn trong bất kỳ khái niệm cụ thể nào. Bởi vì về bản
                                            chất nó có thể tự biến đổi thành nhiều dạng năng lượng vật chất - phi vật chất khác nhau. Do đó, mọi dạng năng lượng
                                            đều chứa ý thức. Ý thức có khả năng nhận biết bản thân và vô hạn biểu hiện bản thân dưới vô vàn hình thái, dạng năng
                                            lượng. Năng lượng của ý thức không biến mất, nó chỉ thay đổi hình thức mà thôi.</p>
                                        <p>
                                            Ý thức xuất phát từ trường ý thức vô hạn và vĩnh cửu của Nguồn Thần (God Source). Nguồn Thần vô hạn là vĩnh cửu, vì vậy ý thức sinh ra từ đó cũng vĩnh cửu. Biểu hiện năng lượng của Nguồn Thần là vô hạn nên ý thức - vốn được tạo ra từ Nguồn cũng có biểu hiện năng lượng vô hạn. Con người chúng ta vẫn tồn tại dưới dạng ý thức sau khi lìa bỏ cơ thể. Chúng ta cũng đã tồn tại dưới dạng ý thức trước khi sinh ra với thân xác. Chúng ta chỉ mặc vào và cởi bỏ chiếc áo vật chất của cơ thể, chứ vẫn tồn tại dưới dạng năng lượng ý thức khác.
                                        </p>
                                        <p>
                                            Nguồn Thần (God Source) để ý thức "chọn lựa vũ trụ trải nghiệm". Đó là để ý thức nhận ra và biết được bản chất vô hạn vĩnh cửu, bản tính thần thánh của mình thông qua trải nghiệm. Nguồn Thần đã tạo ra vô số chiều không gian thực tại. Đó là để tạo ra sân khấu cho ý thức trưởng thành và tiến hóa, nhận ra bản thân vô hạn vĩnh hạn của mình, mở ra vũ trụ trải nghiệm giống như một hình ảnh hologram.
                                        </p>
                                        <p>
                                            Trong quá trình chọn lựa và trải nghiệm vũ trụ hologram, ý thức tiến hóa. Các vũ trụ/hành tinh thực tế là sân khấu hologram nơi ý thức trưởng thành, tiến hóa và nhận biết chính mình thông qua trải nghiệm. Trong vũ trụ trải nghiệm sáng tạo không gian 3 chiều có các quy tắc. Ý thức nhận thức và đồng ý với các quy tắc của vũ trụ trải nghiệm sáng tạo trước khi tham gia vào đó. Các quy tắc như sau:
                                        </p>
                                        <p>
                                            -	Thế giới trải nghiệm sáng tạo tuân theo quy luật vốn có của vũ trụ về sự sống vĩnh cửu, sự tiến hóa và thăng hoa (The Natural Eternal Laws of Unified-Field Physics).
                                        </p>
                                        <p>
                                            -	Tất cả ý thức đều sinh ra từ nguồn ý thức, trường ý thức God Source. Thông qua thế giới trải nghiệm sáng tạo trải dài vô tận, ý thức tiến hóa, thăng hoa và cuối cùng trở về tâm trí God Source. Tất cả ý thức là những mảnh vỡ ánh sáng bắt nguồn từ ánh sáng nguyên sơ. Trong quá trình trở về Nguồn, ý thức kết hợp tất cả các mảnh vụn của chính mình để trở thành toàn bộ ánh sáng, trở về Nguồn. Mọi sự tồn tại bắt đầu từ một Nguồn và quay trở lại với Nguồn, đều là một trong Nguồn (Định luật “Một”: Chúng ta là một, không có sự chia rẽ).
                                        </p>
                                        <p>
                                            -	God Source cho phép tất cả ý thức đều có ý chí tự do. "Sự cho phép" chính là tình yêu tuyệt đối của God Source. Sự cho phép của Nguồn là vô điều kiện và tuyệt đối. Tất cả chúng ta đều là những Đấng sáng tạo, tạo ra mọi kinh nghiệm mà chúng ta muốn trong tình yêu vô điều kiện và sự cho phép của God Source (Unconditional Love & Allowance of God Source).
                                        </p>
                                        <p>
                                            -	Mọi ý thức đều có tự do ý chí để tạo ra các kinh nghiệm mà nó muốn.Tự do ý chí là quyền năng tiềm ẩn, sức mạnh bẩm sinh của ý thức trong việc lựa chọn (Law of Free Will Choice).
                                        </p>
                                        <p>
                                            -	Lựa chọn tự do ý chí có kết quả về năng lượng. Tự do ý chí là sức mạnh tâm linh/tinh thần của ý thức. Kết quả năng lượng là sự phản ánh vật chất của sức mạnh tâm linh mà ý thức tạo ra. Ảnh hưởng tâm linh và kết quả vật lý được hợp nhất dưới dạng sóng tần số thành các khuôn mẫu (cơ thể năng lượng) của mọi sinh vật. Ý thức trưởng thành và tiến hóa thông qua quá trình học hỏi và thể hiện mối tương quan giữa sức mạnh tâm linh và sự phản ánh vật chất. (Law of Free Will Choice).
                                        </p>
                                        <p>
                                            -	Vì vậy, kết quả về năng lượng của tự do ý chí là nội dung học tập trải nghiệm sáng tạo của mỗi người tự lựa chọn. (Law of Cause-Effect & Consequence: luật nhân quả).
                                        </p>
                                        <p>
                                            -	Nội dung học tập trải nghiệm sáng tạo của cá nhân được gắn kết vào cơ thể năng lượng tồn tại thông qua tần số. Tần số là sự rung động riêng của năng lượng ý thức trong việc nhận biết và cảm nhận. Ý thức dần dần hiện thực hóa bản chất vô tận của Nguồn thông qua trải nghiệm các chiều không gian - vật chất trong thế giới trải nghiệm sáng tạo. Đây là sản phẩm của tiến hóa hữu cơ của ý thức. (Law of Evolution by Template Frequency Accretion-Tiến hóa bằng Tần số Mẫu tăng dần).
                                        </p>
                                        <p>
                                            -	Khuôn mẫu năng lượng của cá nhân được liên kết hữu cơ với các khuôn mẫu năng lượng vũ trụ. Khi tần số của chiều cao hơn trong vũ trụ tương tác với khuôn mẫu năng lượng thể của cá nhân, thể vật chất - cơ thể con người dần dần tiến hóa và mở rộng theo chu kỳ biến đổi hữu cơ các nguyên tố nguyên tử vốn có trong quy luật tự nhiên vũ trụ vĩnh cửu. (Quy luật về Nhận thức và Tiến hóa thông qua sự gắn kết tần số của khuôn mẫu).
                                        </p>
                                        <p>
                                            -	Tất cả các sinh vật trải qua quá trình tăng trưởng, tiến hóa, thăng hoa hoặc suy thoái, sa đọa thông qua quá trình học hỏi tự do ý chí. Kết quả năng lượng được phản ánh trong trạng thái cơ thể vật lý do mẫu hình năng lượng của sinh vật tạo ra. Trách nhiệm năng lượng về kết quả lựa chọn thuộc về bản thân sinh vật. (Law of Cause-Effect & Consequence - luật nhân quả).
                                        </p>
                                        <p>
                                            -	Trong thế giới trải nghiệm sáng tạo có ranh giới, giới hạn tự do ý chí mà không thể cho phép. Có những kết quả năng lượng cuối cùng đối với những lựa chọn áp dụng tự do ý chí cực đoan mà phá vỡ quy luật bản sắc của vũ trụ về sự sống vĩnh hằng, tiến hóa và thăng hoa. Đó là con đường phân rã tần số của khuôn mẫu năng lượng cá nhân được kết nối với đại vũ trụ. Những thực thể này sẽ mất hết các dữ liệu trải nghiệm của mình, trở thành bụi vũ trụ và quay trở về với nguồn. (Quy luật về hệ quả thực tế của năng lượng - ý thức - trải nghiệm).
                                        </p>
                                        <p>
                                            -	Đây không phải là God Source đang phán xét hay lên án chúng ta. God Source là trường ý thức vô hạn của tình yêu tuyệt đối. Hậu quả của ý chí tự do ảnh hưởng trực tiếp đến sự tiến hóa của các thực thể và là kết quả năng lượng do chính thực thể tự tạo ra từ kinh nghiệm. Nghĩa là, mọi thực thể đều chịu trách nhiệm về hậu quả của ý chí tự do đối với kinh nghiệm và tiến hóa của bản thân. (Luật Trách nhiệm Tồn tại - Law of Existence Responsibility).
                                        </p>
                                        <p>
                                            -	Cuối cùng, tất cả các thực thể đến các cõi Tạo ra kinh nghiệm sẽ tự lựa chọn hai hành trình: (Luật Tiến hóa Hài hòa so với Bất hài hòa - Law of Harmonic vs Disharmonic Evolution).
                                        </p>
                                        <p>
                                            -	Hành trình thứ nhất là con đường chọn sự tiến hóa, thăng hoa của ý thức, cùng với việc tạo ra các trải nghiệm tỉnh thức, hạnh phúc, vui vẻ. Đây là hành trình tự nhận thức, hòa hợp với tâm trí của Nguồn. Trong quá trình này, sự hiểu biết trải nghiệm về Thượng đế được hòa nhập thành tần số và biến đổi vật chất cơ thể-nguyên tử-nguyên tố thành nguyên tử của sự sống vĩnh cửu. Thông qua đó, tự nhận ra mình là Nguồn và trở về với tâm trí của Nguồn. (Con đường Niềm Vui: Tiến hóa Hài hòa) (Path of JOY-Harmonic Evolution)
                                        </p>
                                        <p>
                                            -	Hành trình thứ hai là con đường chọn sự suy thoái, sa đọa của ý thức, cùng với việc tạo ra các trải nghiệm vô minh, đau khổ, buồn phiền. Đây là hành trình tự phân mảnh hóa bản thân, mâu thuẫn với tâm trí của Nguồn. Trong quá trình này, sự không hiểu biết trải nghiệm về Thượng đế phân mảnh hóa tần số và biến đổi vật chất cơ thể-nguyên tử-nguyên tố thành bụi vũ trụ. Thông qua đó, quên đi mình là Thượng đế và trở về với tâm trí của Thượng đế. (Con đường Đau khổ: Tiến hóa Bất hài hòa) (Path of Sorrow: Disharmonic Evolution).
                                        </p>
                                        <p>
                                            -	Tất cả các ý thức đều đồng ý với các luật trên khi đến vũ trụ trải nghiệm sáng tạo. Cho dù bạn đồng ý hay không, đây là luật vũ trụ bất biến. Luật này chi phối mọi cuộc sống bạn lựa chọn trải nghiệm, là luật của ý thức-năng lượng-hiện hữu. (Law of Existence-Luật Hiện hữu)
                                        </p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-2">1.2. CÁC BẢN THỂ ĐA CHIỀU Ý THỨC VỀ SỰ TỰ NHẬN THỨC BẢN CHẤT ĐÍCH THỰC BÊN TRONG MỖI NGƯỜI</h6>
                                    <div className="textContent lazy-item">
                                        <p>Nhận biết và đồng ý với các luật trên, chúng ta bắt đầu hành trình đến vũ trụ trải nghiệm sáng tạo. Vũ trụ trải nghiệm sáng tạo bao gồm vô số các vũ trụ hologram thực tế. Hãy đơn giản hóa câu chuyện. Bạn cần chọn nơi bạn muốn đến. Giả sử ý thức của bạn chọn Trái Đất. Trái Đất là một trong số vô số các trường hologram trải nghiệm sáng tạo. Để vào thực tại Trái Đất, ý thức của bạn phải đưa bạn vào đó. Nói cách khác, bạn phải "hạ thấp" ý thức. Ý thức thuần khiết có năng lượng lượng tử vô hạn ở tần số cao. Ví dụ, làm thế nào để hạ thấp từng bước dòng điện - năng lượng - ý thức chảy với hàng trăm triệu volt để đi vào hành tinh Trái đất? Với dung lượng điện áp này, bạn không thể đi vào trường Trái đất. Bởi vì Trái đất không tồn tại một hình thái vật chất (khuôn mẫu) có thể chứa đựng dung lượng như vậy</p>
                                        <p>Vũ trụ hologram của thế giới trải nghiệm sáng tạo được tạo thành từ vô số chiều không gian thực tại xếp chồng lên nhau thành các quả cầu (Sphere). Vì vậy, bạn phải hạ cấp từng bước một như xuống cầu thang. Ở mỗi bậc là một thực tại chiều khác. Mỗi thực tại có đặc điểm nhịp độ thời gian, vật chất, mức nhận thức bản thân (bản sắc) khác nhau. Càng cao chiều, nhịp độ rung động và quay của các hạt càng nhanh, mật độ càng thấp Các chiều thấp hơn có rung động, thời gian, sự quay các hạt chậm hơn nên có mật độ cao hơn. Ý thức cần lấy các hình thức năng lượng-vật chất, hay cơ thể năng lượng, phù hợp với các chiều thực tại. Nghĩa là ở mỗi chiều không gian, ý thức phải lấy một cơ thể năng lượng thích hợp. Quá trình biến đổi năng lượng của ý thức được điều phối bởi luật khuôn mẫu của vũ trụ. Ý thức định hình và biến đổi chính mình theo trật tự và luật khuôn mẫu vũ trụ, xuống các mật độ chiều khác nhau.</p>
                                        <p>Khi hạ cấp từ chiều cao xuống thấp, bạn dần giảm cường độ năng lượng và dao động của ý thức, điều chỉnh chúng phù hợp với từng cấp độ chiều khác nhau. Đây là quá trình một ý thức vũ trụ lớn tách ra nhiều bản thể, mỗi bản thể ở một chiều thực tại khác nhau sẽ có nhận thức về bản thân và bản sắc khác nhau. Nghĩa là câu hỏi "Tôi là ai" sẽ khác nhau tùy theo lượng tử năng lượng của ý thức ở mỗi chiều không gian thời gian. "Chẳng hạn, ở thực tại 3 chiều của Trái Đất hiện tại, bạn xác định bản thân “Tôi là....”. Tuy nhiên, bản thể ở chiều cao hơn của bạn, tồn tại trong thực tại có nhiều chiều hơn 3 chiều, sẽ có nhận thức về bản thân sâu sắc và bao quát hơn. Đó là "nhận thức về bản thể đa chiều" của ý thức. Một ý thức vũ trụ lớn tách ra nhiều phần để có thể tập trung vào mỗi chiều thực tại để trải nghiệm chúng. Các bản thể riêng lẻ chia tách từ ý thức gốc ban đầu có chung dấu ấn năng lượng (chữ ký riêng) của ý thức gốc, liên kết với nhau thành một mạng lưới bản thể lớn.</p>
                                        <p>
                                            Bản sắc đa chiều của con người là một phổ tự hình bản thể rộng lớn trải dài trên vô số các chiều không gian. Mỗi phần của phổ tự hình đại diện cho một trạng thái/mức độ ý thức riêng biệt tương ứng với từng chiều.* Theo quan điểm này, ý thức Phật và ý thức Chúa là những khía cạnh cao cấp của ý thức con người. Các thuật ngữ "Phật" và "Christ*" vốn không chỉ đến một thực thể cụ thể bên ngoài. Mỗi con người vốn đã hàm chứa tiềm năng ý thức Phật và ý thức Chúa là những khía cạnh sâu thẳm bên trong bản thân.
                                            {/* <!-- <br/>•	Đoạn văn trên muốn diễn đạt rằng:
									<br/>Bản sắc của mỗi con người không chỉ giới hạn ở một khía cạnh, mà bao gồm nhiều khía cạnh khác nhau. Những khía cạnh này tạo thành một "phổ tự hình bản thể" lớn và phức tạp. 
									<br/>Cụ thể:
									<br/>- "Phổ tự hình" là một thuật ngữ chỉ sự phân bố liên tục của các yếu tố trong một hệ thống. Ở đây ám chỉ sự phân bố liên tục các khía cạnh của bản thể con người.
									<br/>- Các khía cạnh đó được triển khai trên nhiều chiều không gian, tức là trên nhiều bình diện tồn tại khác nhau của con người. 
									<br/>- Mỗi khía cạnh tương ứng với một trạng thái/mức độ ý thức nhất định ở mỗi chiều không gian đó. 
									<br/>Nói cách khác, bản sắc con người bao gồm nhiều khía cạnh đa chiều, với mức độ ý thức khác nhau ở mỗi chiều. Tất cả tạo nên một chỉnh thể phức tạp và đa dạng.
									<br/>•	Có thể dịch "ý thức Christ" sang tiếng Việt là "ý thức Chúa" hoặc "ý thức Chúa Kitô".
									<br/>Ví dụ: Ý thức Christ = Ý thức Chúa Kitô. Theo quan điểm nhận thức đa chiều, ý thức Christ là một khía cạnh cao siêu của ý thức con người. --> */}

                                        </p>
                                        <p>
                                            Từ góc nhìn của ý thức, việc chia nhỏ bản thân thành nhiều bản thể có nghĩa là ý thức đồng thời trải nghiệm thực tại của tất cả các bản thể. Ý thức đồng thời tham gia, trải nghiệm và quan sát tất cả các bản thể của mình. Tôi đang tập trung vào tần số ý thức của chiều 3D này, nhưng các bản sắc đa chiều khác của tôi đang trải nghiệm cuộc sống ở các chiều khác đồng thời. Các bản thể đa chiều đồng thời liên kết với nhau thông qua các mẫu hình cơ thể năng lượng và tác động qua lại với nhau qua cổng DNA sinh học. Theo như các nghiên cứu cho thấy, khái niệm "tiền kiếp" liên quan đến cuộc sống của các bản thể đồng thời trải dài trên nhiều chiều không gian thời gian. Thực tại tiền kiếp/quá khứ là dải tần số với nhịp độ thời gian chậm hơn và rung động nặng nề hơn so với hiện tại. Trong dải tần số đó, chúng ta nhận biết những phiên bản đồng thời của mình là các bản thể tiền kiếp hay là các kiếp trước của bản thân.
                                        </p>
                                        <p>Tương tự, trong các thực tại tương lai với nhịp độ thời gian nhanh hơn và các hạt dao động nhẹ hơn, tồn tại vô số khả năng tiềm năng cho các bản thể tương lai. Chúng ta ngày nay có thể coi là sự thực hiện của một trong số vô số các khả năng đó của các bản thể quá khứ. Quá khứ của tôi đã thực hiện các lựa chọn với quyền tự do ý chí, và kết quả của các lựa chọn đó là tôi ngày nay. Nói cách khác, tôi hiện tại là tương lai đã thực hiện của quá khứ - của những suy nghĩ, lựa chọn, cuộc sống của quá khứ. Giống như tôi không tồn tại một cách ngẫu nhiên vô nghĩa, thì không một thực thể, sự sống, hệ thống nào trong vũ trụ xuất hiện một cách vô nghĩa.
                                        </p>
                                        <p>Hãy nhớ lại rằng chúng ta đến vũ trụ trải nghiệm sáng tạo với quyền tự do ý chí. Hôm nay, tôi đang điều khiển dòng năng lượng ý nghĩ, tâm trí của mình để căn chỉnh với các phiên bản tiềm năng của bản thân trong tương lai. Nếu những suy nghĩ tôi điều khiển trong quá khứ đã thực hiện ra tôi ngày nay, thì những suy nghĩ hôm nay cũng sẽ thực hiện một trong số các phiên bản tiềm năng đó trong tương lai. Điểm tập trung của ý thức luôn là hiện tại. Ý thức là dòng chảy năng lượng liên tục đổ vào hiện tại. Vì vậy, điểm điều khiển dòng tư tưởng, điểm thụ thai các khả năng thực tại mới luôn nằm ở "khoảnh khắc hiện tại". Chúng ta có thể kết nối với các bản thể đồng thời và tác động lên các thực tại của họ thông qua điểm năng lượng của "khoảnh khắc hiện tại". Nhìn từ cấu trúc đa chiều của bản thể con người, về bản chất chúng ta là những thực thể xuyên chiều (Trans – dismensional)</p>
                                        <p>Có thể nói quá trình học hỏi và trưởng thành của con người thực sự diễn ra đồng thời ở nhiều chiều. Trực giác, linh cảm, trí tưởng tượng, giác ngộ, khai sáng, những trải nghiệm gián tiếp, giấc mơ, tiên tri... đều là những cách thức giao tiếp thông tin mà chúng ta trao đổi qua mạng lưới tự ngã đa chiều. Các phương pháp trị liệu hiện đại như thôi miên, hồi quy tiền kiếp... là những ví dụ tiếp cận "cấu trúc bản thể đa chiều" của con người. Như vậy, nền tảng tâm lý con người có sự tồn tại đồng thời, phức tạp và đa chiều của các cơ chế bản thể ý thức.</p>
                                        <p>Mỗi chúng ta vừa là một cá thể riêng biệt, độc nhất, vừa là một phần không tách rời của một cấu trúc liên kết chặt chẽ giữa các phiên bản song song của bản thân mình trải dài trên nhiều không gian và thời gian. Qua mỗi kiếp sống, chúng ta mở rộng và phát triển mạng lưới các phiên bản song song của mình để hoàn thiện. Trong từng cá thể riêng lẻ luôn tồn tại xung lực vươn lên vượt qua sự giới hạn bản thân để hướng tới hòa nhập vào mạng lưới lớn hơn - đó là động lực thúc đẩy sự tự hoàn thiện. Trong chúng ta tồn tại bản chất thiêng liêng của ý thức, khao khát trở thành chính mình, thể hiện trọn vẹn phần tinh túy sâu thẳm nhất.</p>

                                        <p>Hình thái của ý thức có cấu trúc đa chiều là bánh xe không gian thời gian vĩ đại, trong đó vô số bản thể trải rộng trên nhiều chiều thời gian như nan hoa bánh xe, khớp vào nhau một cách tinh vi để xoay chuyển. Thể năng lượng của linh hồn chúng ta là mandala vũ trụ, với các trục không gian thời gian - bản thể xoay vần như bánh xe. Trong bánh xe không - thời gian của linh hồn tồn tại cả "trường khả năng" và "trường thực hiện". Các lựa chọn tự do ý chí của các bản thể trên các thực tại khác nhau, biến xác suất khả năng thành sự giác ngộ được hiện thực hóa. Qua thời gian vũ trụ vô tận, vô số phiên bản đa chiều, những mảnh ghép nhận thức "tôi là cái này" sẽ hội tụ thành "tôi là tất cả những điều đó". Chính linh hồn tự thực hiện hóa thành một vũ trụ, một thế giới riêng.</p>
                                        <p>Trường khả năng là nơi linh hồn thử nghiệm nhận thức về bản thân thông qua các trạng thái tương phản. Cuộc khám phá "tôi thực sự là ai và là cái gì" sẽ phát triển thành lĩnh vực thực hiện của giác ngộ và tỉnh thức. Trong từ Bud-dha (Phật) chứa đựng cơ chế tự thực hiện bản thân của con người. Bud (nụ hoa) ám chỉ các khía cạnh tiềm năng của bản thể, giống như cánh hoa dần hội tụ về trung tâm giác ngộ thành trạng thái Bud (hợp nhất) của ý thức. Dha (linh hồn) là cấu trúc giải phẫu cụ thể được kích hoạt khi linh hồn hội nhập, mang lại trạng thái giác ngộ và hạnh phúc. Nghĩa là Bud-dha ám chỉ người đạt được trạng thái hợp nhất tất cả bản thể của mình, để đạt đến trạng thái giác ngộ, hạnh phúc viên mãn của linh hồn.</p>
                                        <p>Hành trình trải nghiệm sáng tạo của ý thức có thể chia làm hai giai đoạn chính: hành trình hạ thấp và hành trình thăng hoa. Trong giai đoạn hạ thấp, ý thức vận hành thông qua các nhánh bản thể để trải nghiệm các thực tại. Đến một lúc, ý thức bắt đầu hành trình thăng hoa, hội nhập dần các bản thể đã tách rời ở từng tầng thực tại. Trong sự vận động hai chiều đó, ý thức khám phá các khả năng tiềm tàng của cuộc sống thông qua vô số phiên bản của các bản thể, để tiến tới thực hiện "bản thể toàn vẹn". Quá trình hoàn thiện bản thân con người diễn ra trong khuôn khổ các quy luật vũ trụ về sự tiến hóa và thăng hoa của ý thức.</p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-3">1.3.	Vũ trụ Khuôn mẫu Hologram (Holographic Template Universe)</h6>
                                    <div className="textContent lazy-item">
                                        {/* <!-- <p><i>(*) Holographic Manifestation Template hay còn gọi là Bản mẫu hiện thực ảo là một kỹ thuật tập trung vào việc sử dụng trí tưởng tượng của bạn để tạo ra thực tế mà bạn muốn. Nó dựa trên ý tưởng rằng vũ trụ và thực tế của chúng ta phản ánh lại những gì chúng ta nghĩ và cảm nhận. 
									<br/>Cách thức hoạt động:
									<br/>- Bạn hình dung một khung cảnh, tình huống hoặc thực tế mà bạn muốn tạo ra. Càng chi tiết càng tốt.
									<br/>- Sử dụng các giác quan của bạn - thị giác, thính giác, xúc giác, vị giác, khứu giác - để làm cho cảnh tượng càng sống động càng tốt. 
									<br/>- Cảm nhận cảm xúc tích cực liên quan đến cảnh tượng đó - niềm vui, hạnh phúc, phấn khích. Điều này giúp hỗ trợ năng lượng tích cực.
									<br/>- Lặp lại quá trình này thường xuyên để củng cố cảnh tượng trong tâm trí bạn. 
									<br/>- Tin tưởng và biết rằng cảnh tượng sẽ trở thành hiện thực. Đừng nghi ngờ hay lo lắng. 
									<br/>- Để ý các dấu hiệu và đồng bộ hóa trong cuộc sống của bạn cho thấy cảnh tượng đang trở thành hiện thực.
									<br/>Như vậy, Holographic Manifestation Template là một công cụ mạnh mẽ để tạo ra thực tế bạn mong muốn bằng cách sử dụng sức mạnh của tâm trí và trí tưởng tượng. Nó kết hợp tư duy tích cực, trực giác và niềm tin vào khả năng thay đổi thực tế của chúng ta. 
									</i>
								</p> --> */}
                                        <p>
                                            Chúng ta là những nhà du hành có ý thức đang trải nghiệm một thực tại cụ thể của vũ trụ không gian ba chiều. Điều quan trọng trong hành trình là có bản đồ hướng dẫn, người hướng dẫn. Người hướng dẫn cho chúng ta thấy toàn bộ hành trình từ nơi chúng ta đến và nơi chúng ta đang đi đến. Con người vốn có một “kế hoạch thiêng liêng” hay còn gọi là khuôn mẫu vũ trụ, người dẫn đường bên trong. Cuốn sách này nói về việc chúng ta đã mất khuôn mẫu người hướng dẫn nội tâm nên đang ở trong trạng thái ý thức nào, và làm thế nào để có thể kích hoạt lại người hướng dẫn bên trong chúng ta. Để trở thành chủ thể của cuộc hành trình cuộc đời, chúng ta phải tìm lại người hướng dẫn của mình.
                                        </p>
                                        <p><i>Template *- Sơ đồ/khuôn mẫu là bản vẽ chi tiết hướng dẫn chúng ta đi con đường tự do .Thực chất, sơ đồ có thể coi là trọng tâm của hệ thống tri thức khoa học về tâm linh liên quan đến ý thức. Trong tài liệu Guardian có rất nhiều sơ đồ minh họa chi tiết cấu trúc và cách thức vận hành của “template”. Tuy nhiên, trong bài viết này tôi sẽ trình bày một cách rõ ràng và chi tiết về khái niệm sơ đồ thông qua một số chủ đề nhỏ mà không sử dụng các biểu đồ minh họa, nhằm giải thích một cách trực quan và dễ hiểu nhất về “template” cho người đọc.
                                            {/* <!-- <br/>- Sơ đồ vũ trụ 
									<br/>- Lược đồ vạn vật 
									<br/>- Bản thiết kế sự sống
									<br/>- Mã nguồn vũ trụ 
									<br/>- Kế hoạch tạo dựng  --> */}
                                        </i></p>
                                        <p>
                                            <b>1.3.1	Khái niệm "template" nghĩa là gì? </b>
                                        </p>
                                        <p>Đối với chúng ta đang sống trong nền văn minh kỹ thuật số hiện đại, khái niệm "Khuôn mẫu" (Template) không còn xa lạ. Ngày nay, khi xây dựng các trang web hay các loại trang khác, chúng ta thường chọn các khuôn mẫu phù hợp để thiết kế đúng như mong muốn.</p>
                                        <p>Khuôn mẫu (Template) là tấm phôi, khuôn có hình dạng cụ thể. Đầu tiên, người ta xác định mục đích, ý định cần tạo ra. Sau đó thiết kế các thành phần cơ bản và bố cục cho mục đích đó. Các thành phần này sẽ được mã hóa, lập trình thành các thuật toán. Như vậy, khuôn mẫu là tập hợp các quy tắc lập trình tạo thành khuôn, khung sườn, nền tảng, sơ đồ cố định để thực hiện một ý định.</p>
                                        <p>Như đã đề cập trước đó: </p>
                                        <p>"Ý thức" là chất liệu cốt lõi của vũ trụ. Ý thức là năng lượng và năng lượng có ý thức. Ý thức không đi vào thời gian và thế giới sáng tạo một cách ngẫu nhiên, vô trật tự, không có ý nghĩa và mục đích. Trong cấu trúc vũ trụ được tạo thành từ ý thức-năng lượng có "ý đồ" của Nguồn, tức mục đích cốt lõi mà Ngài muốn hiện thực hóa thông qua sáng tạo. Người ta tạo ra các thành phần cơ bản và thiết kế để hiện thực hóa ý đồ cốt lõi này, lập trình các thành phần thành quy tắc, thuật toán cố định.</p>
                                        <p>Tài liệu gọi Nguồn Thần là God-Source. God-Source có nghĩa là Đấng Sáng Tạo của ý thức (God) và Nguồn gốc của năng lượng (Source). Nguồn là trí tuệ vô hạn thiết kế và cấu trúc mọi quá trình sáng tạo từ khởi đầu. Vật liệu mà Nguồn sử dụng để tạo dựng vũ trụ là "ý thức-năng lượng". Trí tuệ vô hạn định hình các khuôn mẫu, khung sườn và cấu trúc của sự sáng tạo được vận hành bởi ý thức, thông qua ý thức, vì ý thức; và thiết lập trật tự, luật lệ cai quản các cấu trúc đó thành "thiết kế". Trí tuệ vô hạn của Nguồn thể hiện động lực vật lý của ý thức thành các "Khuôn mẫu" (Templates).</p>
                                        <p>Do đó, các "Khuôn mẫu" sáng tạo của Nguồn là kiến thức cốt lõi để hiểu hoạt động (động lực) của ý thức và năng lượng. Khoa học về tâm linh của ý thức còn có thể gọi là "Khoa học Khuôn mẫu", bởi nó giải thích cơ chế và trật tự sáng tạo của ý thức và năng lượng. Ý định sáng tạo của Thượng đế, thiết kế của ý định, và bản chất ý thức nguyên thủy được thể hiện tập trung thành các Khuôn mẫu – bản thiết kế.</p>
                                        <p style={{textAlign: 'center'}}><i>Nguồn Thần muốn trải nghiệm tất cả
                                            <br />Nguồn thiết kế ý định thành các khuôn mẫu-sơ đồ
                                            <br />Mọi thực tại diễn ra trong tâm trí của Nguồn
                                            <br />Vũ trụ hologram là  giấc mơ, do tâm tưởng tạo nên
                                            <br />Nguồn dựng nên vũ trụ hologram theo các khuôn mẫu
                                        </i>
                                        </p>
                                        <p>
                                            Trong Trường Ý thức của Nguồn (Consciousness Field) tồn tại vô số các khuôn mẫu (sơ đồ, bản thiết kế) hologram của vũ trụ trải nghiệm sáng tạo, liên tục hoạt động theo trật tự và quy luật ý thức và năng lượng của vũ trụ. Vũ trụ là mạng lưới khuôn mẫu vĩ đại trong đó các khuôn mẫu vũ trụ lớn và vũ trụ nhỏ tô điểm bằng các hạt ánh sáng, âm thanh, rung động. Dòng chảy ý thức năng lượng vĩnh hằng và trường tồn sắp xếp, xoay vần và khớp với nhau theo ý định của Thượng đế. Vũ trụ là các khuôn mẫu hologram thể hiện ý định sáng tạo bằng ánh sáng và âm thanh.
                                        </p>
                                        <p>
                                            Khuôn mẫu tạo nền tảng cho sự tương tác năng động tinh vi giữa các trật tự, cấu trúc và hệ thống của các chiều thực tại trong vũ trụ. Ý thức xuất hiện trên nền tảng đó dưới dạng các cấu trúc hình học và toán học có trật tự cao. Các mô hình hình học cụ thể là các dạng năng lượng mà ý thức tự tổ chức thành trật tự. Các khuôn mẫu liên kết và tương tác với nhau, biểu hiện thành các dạng vật chất tạo nên các chiều khác nhau. Thông qua các khuôn mẫu và bản thiết kế sáng tạo, tất cả mọi thực thể được kết nối với nhau và đồng thời gắn bó không thể tách rời với ý thức Nguồn. Luật Nhất Thể "Tất cả chúng ta là Một" được thể hiện bằng cấu trúc và động lực vật lý trong khuôn mẫu hologram.
                                        </p>
                                        <p>
                                            Ngay cả để tạo một trang web đơn giản cũng cần rất nhiều thành phần và quy luật. Huống hồ, tưởng tượng ra cấu trúc của khuôn mẫu sáng thế bao gồm những gì trong nhận thức của con người là điều vượt ra ngoài tầm hiểu biết của chúng ta. Tuy nhiên, chỉ vì nó nằm ngoài tầm hiểu biết của chúng ta thì không có nghĩa là "điều đó không tồn tại, không có những thứ như vậy...". Liệu chúng ta có thể kết luận như vậy không?
                                        </p>
                                        <p>
                                            Thực tế, có rất nhiều điều nằm ngoài tầm hiểu biết của chúng ta, nhưng lại có tồn tại thực sự. Do đó, thay vì phủ nhận hay khước từ, thái độ khôn ngoan hơn là giữ tâm trí cởi mở, tiếp tục tìm hiểu và khám phá những gì nằm ngoài tầm với tạm thời của trí tuệ nhân loại.
                                        </p>
                                        <p>
                                            <b>1.3.2	Trật tự ẩn sau vẻ ngoài </b>
                                        </p>
                                        <p>
                                            Khuôn mẫu chứa các quy luật năng lượng ý thức vận hành theo trật tự toán học hình học tinh vi, chi phối trật tự và quy tắc phát triển của ý thức ở mọi hoạt động sự sống. Đằng sau mọi thứ nhìn thấy được có một trật tự ẩn, tức cấu trúc khuôn mẫu ý thức-năng lượng vô hình khiến chúng xuất hiện như một hiện tượng.
                                        </p>
                                        <p>
                                            Các khuôn mẫu sáng tạo chứa đựng các yếu tố cấu thành bản chất của thực tại. Thế giới xuất hiện như thế này là do có những nguyên nhân cấu trúc ở chiều khuôn mẫu. Các học thuyết tâm linh đã truyền lại những hiểu biết về nguyên nhân, tác động gốc rễ ẩn sau vẻ ngoài. Ngôn ngữ giác ngộ, thần thoại, văn học tâm linh, các kinh điển là những nỗ lực nắm bắt "những thực tại vô hình, những trật tự ẩn trước khi xuất hiện". Mặt khác, khoa học đã cố gắng nắm bắt "hiện tượng thể hiện" thông qua nghiên cứu hoạt động của năng lượng-vật chất. Tuy nhiên, khoa học chỉ coi những gì xuất hiện là thực tại tồn tại, phủ nhận trật tự ẩn và trí tuệ ý thức cao đằng sau hiện tượng. Ngày nay, cơ học lượng tử bắt đầu nhận ra sự tương tác giữa trật tự ẩn và hiện tượng xuất hiện.
                                        </p>
                                        <p>
                                            Khuôn mẫu có thể được gọi là "bản thiết kế thiêng liêng" chứa đựng quy luật tiến hóa và thăng hoa vĩnh cửu. Các thực thể ý thức cá thể hóa từ trường ý thức Nguồn tiến hóa theo "bản thiết kế thiêng liêng" dưới dạng ý thức tập thể, ý thức hành tinh, ý thức chủng tộc và ý thức cá nhân. Tất cả các hệ thống, thực thể và sự vật trong vũ trụ đều tiến hóa qua thế giới trải nghiệm sáng tạo theo trật tự và cấu trúc của bản thiết kế thiêng liêng. Tất cả bản chất và yếu tố trải nghiệm của con người làm nên ta, khiến ta trở thành con người, tồn tại và suy nghĩ như một con người đều phát triển từ bản thiết kế thiêng liêng, khuôn mẫu.
                                        </p>
                                        <p>
                                            Kiến thức khuôn mẫu bao gồm các thông tin sau: Mối quan hệ giữa các khuôn mẫu đại vũ trụ và tiểu vũ trụ, động lực năng lượng hình thành vật chất, động lực chiều thời gian và không gian-vật chất, khoa học đa vũ trụ đa chiều, khoa học kích hoạt DNA và ấn tượng tế bào, động lực thăng hoa chiều, động lực hạt và hạt/phản hạt siêu vi mô, mối quan hệ giữa ý thức nguồn và loài người, v.v.
                                        </p>
                                        <p>
                                            Khuôn mẫu giải thích toàn diện mối quan hệ của mọi sinh vật trong bối cảnh tương tác giữa ý thức và năng lượng. Hiểu về khuôn mẫu vũ trụ liên quan trực tiếp đến hiểu biết về khuôn mẫu con người là nền tảng của nó. Bởi vì các yếu tố cấu thành khuôn mẫu con người được liên kết với các yếu tố cấu thành khuôn mẫu vũ trụ. Do đó, sự hiểu biết về con người là sự hiểu biết về vũ trụ và mở rộng ra là sự hiểu biết về sáng thế và nguồn gốc thần thánh.
                                        </p>
                                        <p>
                                            Kết quả là, thông qua kiến thức khuôn mẫu, chúng ta có thể đạt được sự hiểu biết tổng hợp, bao quát về hoạt động của vũ trụ và các sinh vật muôn loài. Không có gì tồn tại riêng rẽ, hoạt động độc lập. Bối cảnh tổng thể và tính quy luật liên kết của sự tồn tại cho phép chúng ta hiểu vị thế nguyên thủy của con người trong mối liên hệ với vũ trụ và trái đất. Khuôn mẫu tiết lộ bản chất thực sự của các mối quan hệ sống không thể tách rời của vũ trụ.
                                        </p>
                                        <p>
                                            <b>1.3.3.	Con người thể hiện khuôn mẫu vũ trụ lớn thành khuôn mẫu tiểu vũ trụ </b>
                                        </p>
                                        <p>
                                            Chúng ta thường nghe nói rằng con người là một vũ trụ nhỏ thu nhỏ từ vũ trụ lớn. Chúng ta cảm nhận được một sự thật nào đó trong câu nói đó. Mối liên hệ không thể tách rời và tương tác giữa vũ trụ, trái đất và con người làm cơ sở cho chân lý này có thể được hiểu đầy đủ thông qua kiến thức về khuôn mẫu.
                                        </p>
                                        <p>
                                            Khuôn mẫu liên kết và vận hành cùng với các khuôn mẫu khác. Khuôn mẫu vũ trụ lớn kết nối với khuôn mẫu hành tinh Trái Đất. Khuôn mẫu hành tinh Trái đất trở thành khuôn mẫu cơ sở (Base) cho tất cả các sinh vật sống trên Trái Đất. Khi chọn Trái Đất, chúng ta đi vào qua tử cung của Mẹ (Cha) Trái đất, tức khuôn mẫu Trái Đất, và nhận lấy khuôn mẫu chủng loài con người tương tác với Trái đất. Theo khuôn mẫu, dòng chảy ý thức - năng lượng và kết nối năng lượng giữa "vũ trụ - Trái Đất - con người" khiến các hoạt động của linh hồn, tâm trí và cơ thể con người chịu ảnh hưởng không thể tách rời lẫn nhau. Đó là sự kết nối ý thức (consciousness interface) và sinh học (bio-interface) xảy ra thông qua khuôn mẫu. Khuôn mẫu có thể coi như bản đồ sinh thái vũ trụ rộng lớn cho thấy mối quan hệ năng lượng - ý thức giữa vũ trụ và các hành tinh, cũng như các sinh vật sống trên hành tinh đó.
                                        </p>
                                        <p>
                                            Chúng ta là những ý thức tâm linh đang trải nghiệm cuộc sống con người bằng khuôn mẫu con người. Cơ thể (thể xác) là tấm áo mà ý thức mặc vào để tập trung vào các hoạt động ở một chiều cụ thể (chiều thứ 3). Ý thức mặc nhiều lớp áo năng lượng - vật chất như thế này. Khuôn mẫu con người là khuôn mẫu ý thức tiến hóa ở mức rất cao trong vũ trụ. Hơn nữa, các khuôn mẫu năng lượng (energy template) của con người thì "vô số kể" tùy theo chúng biểu hiện năng lượng và ý thức của chiều nào trong thế giới sáng tạo.
                                        </p>
                                        <p>
                                            Các khuôn mẫu năng lượng của con người được kích hoạt bằng cách hợp nhất với các tần số ý thức cấp cao hơn. Ý thức phát triển, tiến hóa và thức tỉnh ở một giai đoạn cụ thể là quá trình kích hoạt thể năng lượng tương ứng với mỗi chiều ý thức. Cơ thể là tấm áo vật chất nguyên tử được thể hiện nhiều nhất trong số những tấm áo giúp trải nghiệm con người trở thành khả thi. Khi lớp áo vật chất được cởi bỏ (lìa bỏ thể xác) chúng ta thức tỉnh ở các cấp độ ý thức-năng lượng sâu hơn bên trong. Các trường hợp cận tử chứng minh thực tế này khi họ miêu tả trạng thái ý thức và cảm giác mở rộng sống động đến mức sốc sau khi rời bỏ cơ thể.
                                        </p>
                                        <p>
                                            Biểu đồ sau mô tả khái niệm các khuôn mẫu năng lượng kết nối với nhau. Khi các thể năng lượng cấp cao được kích hoạt theo sự tiến hóa của ý thức, chúng kích hoạt các trạng thái tần số (frequency) và ý thức tương ứng trong thể xác. Từ trái sang: Thể plasma (biểu hiện năng lượng ý thức của vũ trụ sáng tạo vĩnh cửu), thể linh hồn kết nối nội tại và ngoại tại, thể vật chất tối, thể tinh thể ánh sáng, thể vật chất nguyên tử. Mỗi thể năng lượng hạ thấp ý thức năng lượng xuống hiện thực vật chất theo từng thực tại-chiều. (Tài liệu dạy chi tiết cấu trúc, chức năng, sự kết nối của các thể năng lượng)
                                        </p>
                                        <p className='imgCenter'><img src="/static/images/chapter1-img8.png" loading="lazy" />
                                            <br /><i>Biểu đồ: “Các khuôn mẫu năng lượng của con người” [Nguồn biểu đồ: Guardian Teaching Materials]</i>
                                        </p>
                                        <p>
                                            Kiến thức về khuôn mẫu sáng thế là kiến thức về quá trình sáng thế và hiểu biết về Nguồn, cũng như định luật tâm linh - năng lượng. Đồng thời, nó là sự hiểu biết về bản chất năng lượng - ý thức của con người, bản chất của thực tại, và mối quan hệ giữa vũ trụ, trái đất và con người. Bởi vì chính sự tồn tại của chúng ta được tạo ra và tồn tại bởi khuôn mẫu thiêng liêng. Các nguyên tử của cơ thể, tế bào, hệ thần kinh, v..v.. của chúng ta cũng như sự tương tác của chúng diễn ra thông qua các khuôn mẫu năng lượng chi phối chúng từ phía sau. Đằng sau vật chất là sự tồn tại của các thực thể khuôn mẫu năng lượng vô hình tương tác với cấu trúc tổ chức hữu hình.
                                        </p>
                                        <p>
                                            Các học thuyết cổ xưa nói về thực thể thiêng liêng, vĩnh cửu bên trong con người, đó chính là “khuôn mẫu thiêng liêng”. Kiến thức khuôn mẫu không phải là những khái niệm trừu tượng hay niềm tin mơ hồ, mà là “thực tại hữu hình của linh hồn” - cách mà ý thức - năng lượng biến đổi thành vật chất. Ý thức tâm linh biểu hiện thành vật chất thông qua các quy luật vật lý - khoa học của quá trình sáng tạo vũ trụ. Khoa học đích thực là sự hiểu biết về tính ý thức sống động tiềm ẩn trong vật chất. Vì vậy, khuôn mẫu “tiết lộ” niềm tin tâm linh thành tri thức, sự hiểu biết.
                                        </p>
                                        <p>
                                            Bên trong khuôn mẫu sáng tạo chi phối các quy luật khoa học tâm linh của ý thức và năng lượng, tính tâm linh và khoa học trở thành một. Thực ra, tranh luận giữa thuyết sáng tạo và thuyết tiến hóa được kết thúc trong “hệ thống tri thức khuôn mẫu”. Ý thức - năng lượng tiến hóa trong khuôn khổ rộng lớn của quá trình sáng tạo (khuôn mẫu). Khuôn mẫu sáng tạo là bối cảnh tồn tại cũng như lộ trình, bản vẽ cho sự tiến hóa của vô số các thực thể ý thức thông qua quyền tự do lựa chọn.
                                        </p>
                                        <p>
                                            Tuy nhiên, khuôn khổ sáng tạo không hoạt động như định mệnh. Mỗi thực thể tự chọn lộ trình tiến hóa hay thoái hoá, thăng hoa hay sa đọa thông qua “quyền tự do lựa chọn”. Kết quả năng lượng của sự lựa chọn tự do của mỗi thực thể sẽ dẫn đến các bước tiến hóa hoặc thoái hoá tiếp theo theo quy luật khuôn mẫu vũ trụ. Tiến hoá hay thoái hoá hoàn toàn do hệ quả năng lượng của lựa chọn tự do ý chí gây ra. Hệ quả đó phản ánh thành "thực tại tần số hợp nhất" trong các thể năng lượng của con người, khuôn mẫu ý thức - năng lượng. Trong vũ trụ sự sống vĩnh hằng, khuôn mẫu sáng tạo dẫn dắt mọi thực thể đến trạng thái ý thức – năng lượng cao hơn và thúc đẩy bản năng tiến hóa. Mọi thực thể đều phát triển và tiến hóa với bản năng vốn có hướng đến sự hợp nhất với ý thức nguyên thủy.
                                        </p>
                                        <p><b>1.3.4.	Khuôn mẫu - bí mật của việc tạo ra thực tại</b></p>
                                        <p>Đối với những ý thức chọn trải nghiệm thế giới sáng tạo, kiến thức về khuôn mẫu là hiểu biết cơ bản về nơi mình đến, tại sao mình đi qua đây và mình đang đi đến đâu. Khuôn mẫu là người dẫn đường giúp chúng ta khám phá thế giới hologram này. Việc có hay không kiến thức về khuôn mẫu có ý nghĩa thực tế sâu sắc giữa việc sống cuộc sống có bản đồ dẫn đường hay sống mà không có bản đồ. Vì vậy, đó là vấn đề quyết định giữa việc sống với quan điểm hạn hẹp về bản thân và thế giới bị cuốn vào mê cung của thực tại hologram, hay mở rộng tầm nhìn về bản thân và thế giới để trở thành người sáng tạo có ý thức của thực tại hologram. Nếu có những thực thể ý thức xấu xa muốn nhốt chúng ta trong hologram để sống vô thức, thứ đầu tiên họ hủy bỏ chính là bản đồ khuôn mẫu sự tồn tại.</p>
                                        <p>
                                            Khuôn mẫu cũng chính là bí mật thực sự của sáng tạo – về việc chúng ta sử dụng tự do ý chí để tạo ra thực tại hologram riêng của mình, cuộc sống của mình. Khao khát sáng tạo và trải nghiệm  của Nguồn chảy qua khuôn mẫu con người như một bản năng tự hiện thực bản thân. Đam mê khám phá vô hạn của Nguồn thông qua vô số khả năng đã tạo ra “thực tại hologram mà ở đó ta có thể trải nghiệm mọi thứ theo tự do ý chí. Tương tự, con người chúng ta đang sáng tạo trường trải nghiệm hologram, cuộc sống của riêng mình thông qua ý định (suy nghĩ) theo tự do ý chí. Con người là Người Hiện thực hóa (Manifestor) với khuôn mẫu hiện thực hóa suy nghĩ thành hiện thực.
                                        </p>
                                        <p>
                                            Để hiểu bí mật biến ước mơ thành hiện thực, chúng ta cần lý giải khái niệm “sự căn chỉnh”. Các khuôn mẫu hoạt động phức tạp với nhau thông qua sự căn chỉnh. Tiếp diễn bằng sự căn chỉnh và kết thúc bằng sự căn chỉnh. Vì vậy khuôn mẫu cũng là bản đồ căn chỉnh năng lượng - ý thức cho thấy trạng thái căn chỉnh của các thực thể (chủng loại, hệ hành tinh) phát sinh từ Trường Ý thức Nguồn trong hành trình tiến hóa lâu dài.
                                        </p>
                                        <p>
                                            Khuôn mẫu - người hướng dẫn cho thấy một thực thể (chủng loài, hệ hành tinh) đang ở giai đoạn tiến hóa hay thoái hóa, suy tàn. Điều đó cũng có thể được xác định thông qua bản đồ căn chỉnh vũ trụ của khuôn mẫu. Lựa chọn tự do ý chí của một thực thể ảnh hưởng và thay đổi trạng thái căn chỉnh năng lượng-ý thức của nó. Theo thuật ngữ khuôn mẫu, điều chúng ta mong muốn và khao khát là các thực tại dao động năng lượng - ý thức cấp cao. Quá trình thực hiện các điều mong ước là quá trình căn chỉnh “dao động” giữa tôi và điều tôi khao khát.
                                        </p>
                                        <p>
                                            Suy nghĩ tạo ra dao động - bức xạ từ tâm trí và tạo ra Nhịp điệu Cơ bản (BPR - Base Pulse Rhythm) rung động của cơ thể - tế bào. Cảm xúc giúp điều chỉnh BPR của cơ thể - tế bào với các thực tại dao động đó. Khi sự căn chỉnh dao động của suy nghĩ-cảm xúc-cơ thể đạt được, nó được chiếu ra bên ngoài thành hologram và hóa vật chất.
                                        </p>
                                        <p>
                                            Hạnh phúc mà tất cả chúng ta đều mong muốn là các dao động được trải nghiệm khi “căn chỉnh” về mặt tần số với các trạng thái ý thức cấp cao. Sự căn chỉnh tần số dao động với ý thức cấp cao tạo ra trạng thái hài hòa năng lượng, lưu thônng trong khuôn mẫu hiện hữu của chúng ta, mang lại niềm vui, hạnh phúc, bình an. Ngược lại, các kinh nghiệm đau khổ, bất hạnh, buồn phiền... là các dao động được trải nghiệm khi có sự “không căn chỉnh” về mặt tần số dao động với các trạng thái ý thức cấp cao. Tình trạng không căn chỉnh dẫn đến tình trạng rối loạn năng lượng, tắc nghẽn dòng chảy năng lượng trong các khuôn mẫu hiện hữu của chúng ta. Chúng ta đang trải nghiệm các thực tại dao động do căn chỉnh và không căn chỉnh thông qua lựa chọn tự do ý chí. Con người là những thực thể có khuôn mẫu sáng tạo thiêng liêng thần thánh, có khả năng biến ước mơ thành hiện thức bằng cách thiết lập sự căn chỉnh hài hòa về dao động ý thức-năng lượng cấp cao.
                                        </p>
                                        <p>
                                            <b>1.3.5.	Kiến thức bên trong (Inner Knowing)</b>
                                        </p>
                                        <p>
                                            Rất nhiều kiến thức được dạy trên Trái đất cho rằng mọi thứ về bản chất của sự tồn tại nằm bên ngoài chính nó. Nghĩa là lý do và ý nghĩa tồn tại của bạn nằm ở nơi khác, bên ngoài bạn. Vì vậy bạn lang thang tìm kiếm tình yêu, những người thầy giỏi hơn mình, niềm vui, hạnh phúc, thành công, chân lý ở bên ngoài bản thân. Tuy nhiên, Nguồn đã đặt tất cả những gì cần thiết cho sự tồn tại "vào bên trong" và "khuôn mẫu hóa" chúng. Theo trật tự khuôn mẫu, con người mang tất cả kiến thức về bản thân tích lũy được trong hành trình như thông tin linh hồn trong các thể năng lượng. Khuôn mẫu là trí tuệ nội tại và thể nội tại vốn có của mỗi con người. Tri thức về khuôn mẫu đánh thức trí tuệ nội tại, kích hoạt sự hiểu biết bẩm sinh (Inner Knowing) của chúng ta. Kiến thức về khuôn mẫu chính là sự hiểu biết về bản chất của thực tại và sự tồn tại mà tất cả sinh vật cần học hỏi - đó là quyền lợi bẩm sinh của mọi sinh linh.
                                        </p>
                                        <p>
                                            Bạn đến Trái đất như thế này, không nhớ bạn đến từ đâu, không biết tại sao lại chọn hành tinh Trái Đất, mục đích của mình là gì, cũng như điểm đến cuối cùng trong toàn bộ hành trình liên tục của bạn. Sự hiểu biết tự nhiên, tự phát về bản thân, khao khát thiêng liêng muốn hiểu được sự tồn tại của mình bị phủ nhận trong cuộc sống trên trái đất. Việc học tập trên trái đất diễn ra theo hướng kìm nén và phủ nhận sự hiểu biết nội tâm (Knowing) của con người.
                                        </p>
                                        <p>
                                            Trong thời gian dài, con người bị mắc kẹt trong nhận thức nhị nguyên cho rằng tâm linh và vật chất tồn tại theo các quy luật riêng biệt, không thể hiểu được hoặc giải thích lẫn nhau. Cuộc tranh luận giữa thuyết sáng tạo và thuyết tiến hóa không cho phép suy nghĩ của chúng ta vượt qua nhị nguyên. Các giá trị trong cuộc tranh luận định hình phần lớn thực tại mà chúng ta đang sống. Đó là những giá trị cho rằng Thượng đế không tồn tại, và nếu có thì đó là Thượng đế phán xét nên phải tin theo mù quáng, vũ trụ hình thành ngẫu nhiên từ các bong bóng và chùm năng lượng vô nghĩa, con người sinh ra vô nghĩa và sống vô nghĩa rồi chết đi. Bị nhị nguyên khống chế, chúng ta không biết rằng có một thuyết tổng hợp nguyên thủy hoàn chỉnh tồn tại phía sau hai quan điểm đó.
                                        </p>
                                        <p>
                                            Vô tình chúng ta chấp nhận những giá trị đó là thực tại, và tiếp nhận khuôn mẫu của sự tồn tại loài người được định hình bởi nhị nguyên vào suy nghĩ của mình. Vậy cuộc sống của con người do những suy nghĩ đó tạo ra trông như thế nào? Liệu chúng ta có đang thể hiện "giá trị suy nghĩ vật chất” về nguồn gốc từ khuôn mẫu khỉ, phát ra năng lượng “sinh tồn cho phù hợp nhất”, "ý thức sinh tồn", “đấu tranh" vào cuộc sống? Chúng ta xuất phát từ khuôn mẫu nào mà lại trải nghiệm cuộc sống vấy bẩn bởi đau khổ, bi kịch, cái chết và sinh tồn? Liệu Thượng đế có ý định tạo tra con người để trảinghiệm những “sinh tồn của phù hợp nhất” qua khuôn mẫu con người không? Nếu đó không phải là ý định ban đầu của Thượng đế khi tạo ra khuôn mẫu con người, thì điều gì đã xảy ra với khuôn mẫu thiêng liêng của con người?
                                        </p>
                                        <p>
                                            Nếu mục đích và ý nghĩa sâu xa của nhân loại là trải nghiệm “sinh tồn mạnh được yếu thua – ý thức sinh tồn – đấu tranh” thì ít nhất con người phải có khả năng nhìn thấy rõ ràng bản thiết kế, sơ đồ thiết kế năng lượng - ý thức của khuôn mẫu người vượn tạo nên mình. Đó là quyền lợi chính đáng của mọi sinh vật được sinh ra từ tâm trí Thượng đế. Bởi kiến thức liên quan đến khuôn mẫu là kiến thức bẩm sinh, quyền lợi bẩm sinh của mỗi sinh vật. Đó chính là lời hứa yêu thương giữa Đấng Tạo Hóa và sinh vật. Đó là luật “Chúng Ta Là Một” vốn có trong mọi sinh vật.
                                        </p>
                                        <p>
                                            Thượng đế không che giấu bản đồ tồn tại mà tất cả chúng sinh nên biết bằng trí thông thường, biến nó thành một tri thức huyền bí. Không bóp méo nó thành tri thức giáo điều cần phải tin theo một vị thần nhất định. Không chờ đợi sự chấp thuận của khoa học chỉ công nhận những gì quan sát được dưới kinh hiển vi và kính thiên văn.
                                        </p>
                                        <p>
                                            Vì vậy, các vị thầy tâm linh vĩ đại có lẽ đã nói:

                                            "Tất cả chân lý mà bạn cần đều nằm bên trong bạn."
                                            "Ý thúc Nguồn ở ngay trong lòng bạn."
                                            "Hãy tự nhìn sâu vào bản thân, bạn sẽ tìm thấy tất cả mọi thứ."
                                            "Con người không thể dạy cho ai điều gì mà họ không biết. Họ chỉ có thể chia sẻ với người khác điều mà chính họ đã khám phá."

                                        </p>
                                        <p>
                                            Có lẽ, đây là lời nhắc nhở rằng tất cả kiến thức về bản chất thực sự của vũ trụ, sự sống, và chính bản thân đã được Nguồn ghi khắc sẵn bên trong mỗi người. Đó là lời mời gọi trở về với trí tuệ nội tại mà ai cũng có thể tiếp cận nếu chịu lắng nghe tiếng nói từ trái tim. Bởi lẽ trái tim chính là nơi Thượng đế đặt mọi điều bạn cần biết và làm, nếu bạn chịu lắng nghe.
                                        </p>
                                        <p><b>1.3.6.	Vũ trụ Khuôn mẫu Hologram (Holographic Manifestation Template)</b></p>
                                        <p>
                                            Cuộc sống được thể hiện ra bên ngoài, vật chất hóa, và các bản sắc cá nhân hóa, tất cả đều là sự chiếu tia hologram của ý thức NĂNG LƯỢNG-VẬT CHẤT (Holographic Projection of Consciousness-ENERGY SUBSTANCE). Sự chiếu tia hologram được tạo ra thông qua trật tự riêng của các mối quan hệ năng lượng. Các mối quan hệ năng lượng diễn ra trong vô hạn các hình thái hiện hữu và phi hiện hữu của các mối quan hệ ý thức.
                                        </p>
                                        <p>
                                            Ý thức là năng lượng và năng lượng chứa ý thức (Consciousness IS ENERGY and Energy IS CONSCIOUS). Khoa học chân chính có phần tinh thần và tâm linh cốt lõi, và tâm linh chân chính giải thích bản chất thông qua các luật năng lượng điều khiển sự xuất hiện của vũ trụ. Thực tạ (Reality) là cấu trúc tư tưởng (Thought Construction). Vật chất cốt lõi của vũ trụ là ý thức. Suy nghĩ là đặc tính của ý thức. Ý thức thể hiện chính mình dưới dạng không gian ba chiều thông qua suy nghĩ.
                                        </p>
                                        <p>
                                            Không có gì thực sự rắn chắc cả. Mọi thứ được cấu tạo từ ý thức. Chúng chỉ có vẻ rắn chắc do mối quan hệ giữa ý thức quan sát và ý thức tạo nên hình thái (form). Ý thức con người xuất hiện thông qua trật tự phân chiều (Dimensionalization). Phân chiều thiết lập nên khuôn khổ cho sự tương tác giữa các ý thức. Độ cứng và sự khách quan mà con người nhận thức là do mối quan hệ giữa tốc độ dao động (Oscillation rates) của các đơn vị ý thức chiều không gian hóa và tần số trường thống nhất bao quanh. Các đơn vị ý thức theo chiều tồn tại trong các mối quan hệ năng lượng riêng biệt với nhau.
                                        </p>
                                        <p>
                                            Nhận thức và hình hài con người được hình thành bởi các mẫu tần số phát sinh từ quá trình phân chia các đơn vị ý thức. Điều chúng ta cảm nhận là sự tồn tại cứng rắn, vững chắc là do mối quan hệ giữa tần số của các ý thức tạo nên chúng ta với tần số của trường năng lượng ý thức thống nhất bao quanh chúng ta. Con người nhận thức toàn bộ phổ tần số phía dưới tần số mà ý thức cá nhân hoặc tập thể cư trú là thực tại hiện hữu cứng rắn.
                                        </p>
                                        <p>
                                            Sự khác biệt duy nhất giữa tư tưởng và thực tại là tần số của ý thức quan sát. Thực tại hiện hữu là sự chiếu phát của tư tưởng. Nó được vật thể hóa cứng rắn do mối quan hệ giữa tần số mà ý thức chúng ta tập trung và tần số của hình thái tư tưởng được chiếu phát. Chúng ta cảm nhận các hình thái tư tưởng tập thể là thực tại bởi vì trọng tâm của ý thức tập thể hiện tại nằm ở tầng tần số thấp hơn so với tần số mà các hình thái tư tưởng tập thể chiếm giữ.
                                        </p>
                                        <p>
                                            Thực tại được cấu tạo từ trường tư tưởng bao gồm các đơn vị nhận thức của ý thức. Tư tưởng tác động trực tiếp lên thực tại. Vật chất của tư tưởng và vật chất của thực tại là một. Chìa khóa để làm chủ và khôi phục sức khỏe của thực tại hiện tại là hiểu chiều thực tại mà ý thức chúng ta đang tồn tại. Thể xác con người là sự chiếu phát hologram của ý thức. Thể xác con người được xây dựng trên khuôn mẫu hologram cấu trúc từ các đơn vị ý thức. Khuôn mẫu hologram là cơ sở sự sống. Ánh sáng, âm thanh, sóng vô tuyến có ý thức là bản vẽ chi tiết theo từng chiều. Các ý thức cá nhân cư trú bên trong và trải nghiệm ảo ảnh về sự cứng rắn của vật chất trên đó. Để tạo ra sự thay đổi bền vững trong hệ thống cơ thể - tâm trí - linh hồn của con người, chúng ta cần đi sâu vào lõi của cấu trúc thực tại, đó là khuôn mẫu hologram. Khuôn mẫu hologram là bản vẽ của tư tưởng - năng lượng. Dựa trên đó, ý thức nhận biết chính mình như một thực thể.
                                        </p>
                                        <p>
                                            Thể xác là hologram. Ý thức tạo nên vật chất của thể xác trực tiếp tác động lên thể xác. Khi học về cấu trúc phân chiều mà ảo ảnh thể xác xuất hiện, chúng ta có thể dần thành thạo trong việc điều khiển tư tưởng để tạo ra các thực tại cụ thể mong muốn. Khuôn mẫu hologram cốt lõi của cơ thể cá nhân và cơ thể vũ trụ được gọi là lưới Kathara. Kathara healing giúp hiểu và điều khiển quá trình chiếu phát hologram nội tại để phục hồi hoàn toàn chức năng của hệ thống cơ thể-tâm trí-linh hồn.
                                        </p>
                                        <p>
                                            Không có gì thực sự hiện hữu. Chỉ là vẻ bề ngoài như vậy.
                                        </p>
                                        <p>
                                            Chỉ là hiện tượng khúc xạ của ý thức trong các mối quan hệ năng lượng của khuôn mẫu hologram làm cho nó có vẻ như vậy. Khuôn mẫu hologram là cấu trúc tư tưởng của Thượng đế. Cấu trúc vũ trụ được tái tạo liên tục trên nền tảng của cấu trúc tư tưởng của Thượng đế. Các yếu tố nguyên nhân gây ra mọi kinh nghiệm hiện hữu được gọi là lưới phát sinh hình thái hoặc khuôn mẫu hiện hữu. Khoa học về khuôn mẫu hiện hữu, Keylontic Science, là một phần của Vật lý thống nhất 15 chiều - một thuyết vật lý tiên tiến.
                                        </p>
                                        <p>
                                            Tất cả mọi thực thể và vật thể đều là những hữu thể tâm linh đang trải nghiệm hiện hữu. Không thực thể hay vật thể nào có thể tách rời khỏi tâm linh bẩm sinh của mình. Chỉ có thể bị ngắt quãng trong nhận thức của con người mà thôi.
                                        </p>
                                        <p>
                                            Cuộc sống là sự thể hiện của linh hồn và là vật chất.
                                        </p>
                                        <p>
                                            Thực tại hiện hữu chỉ là cảnh tượng trong giấc mơ mà tất cả chúng ta cuối cùng sẽ tỉnh thức.
                                        </p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-4">1.4. TỪ MỘT SINH RA HAI</h6>
                                    <div className="textContent lazy-item">
                                        <p>Chúng ta đã xem xét các khái niệm lớn về khuôn mẫu là gì. Bây giờ chúng ta hãy tìm hiểu các yếu tố chính cấu thành khuôn mẫu.</p>
                                        <p><b>1.4.1 Lực Thần Nguyên Thủy (God Force) và Hạt Ý thức (Conscious Unit)</b></p>
                                        <p>Quá trình sáng tạo vũ trụ của Nguồn Thần giống như quá trình chúng ta sáng tạo thực tại bằng suy nghĩ. Trước tiên, Nguồn ấp ủ một ý định (Intention). Nguồn muốn tạo ra thực tại hologram trong Tâm Trí của mình (Trường Ý thức của Nguồn) để trải nghiệm chính mình. Theo ý định đó, Nguồn tập trung ý thức (Focus of attention). Xung quanh điểm tập trung ý thức của Nguồn, Lực Thần Nguyên Thủy (Primal God-Force) xuất hiện. Điểm tập trung ý thức là Nguyên lực Vật lý ban đầu - Void (Hư Không, ManU-MaNu). Từ Void (Hư Không - MaNu) phát sinh dao động âm thanh nguyên thủy đầu tiên EirA và dao động ánh sáng nguyên thủy đầu tiên ManA.</p>
                                        <p style={{textAlign: 'center'}}>
                                            <i>"Ban đầu, có một không gian trống (Void - MaNu điểm tĩnh lặng). Trong Hư Không, Nguồn Sáng tạo phát ra Lời (dao động âm thanh nội tại - EirA) và từ Lời phát sinh Ánh sáng (dao động ánh sáng ngoại tại - ManA) và từ Ánh sáng phát sinh toàn bộ Sáng Thế trên Cõi Trời Cao và Cõi Trời Thấp."
                                                <br /> (12 vũ trụ giới, 15 tia sáng, sơ đồ thiêng liêng tiền vật chất – MaHaRaTa/Christos)
                                            </i>
                                        </p>
                                        <p>Từ một sinh ra hai. Hai sinh ra ba. Ba trở lại là một.</p>
                                        <p><b>1.4.2. TỪ MỘT SINH RA HAI RỒI ĐẾN BA</b></p>
                                        <p>Từ Lực Thần Nguyên Thủy (God Force, ManU, EirA, ManA), Chất Liệu Nguyên Thủy (Primal substance, chất liệu gốc) truyền tải lực thần được sinh ra, đó là hạt ý thức nguyên thủy. Từ Void (khoảng trống) của ManU, hạt ý thức nguyên thủy Partiki xuất hiện. Partiki là đơn vị nhỏ nhất của chất liệu ý thức-năng lượng, là nền tảng mà mọi ý thức hiện thực hóa. Partiki là trạng thái bán-vật chất Ante (tiền vật chất), nguyên mẫu của vật chất, trống rỗng như Void. Void (khoảng trống) là điểm tĩnh lặng (Still point) của ý thức vô hạn với mọi khả năng, đồng thời là điểm trung tâm (Center point) mà từ đó mọi thứ ra đời.</p>
                                        <p>Từ Partiki, rung động nội tại phát xạ âm thanh Particum và rung động ngoại phát xạ ánh sáng Partika xuất hiện. Partika là dao động giãn nở điện (+) của hạt-bán vật chất. Particum là rung động nội tại từ (-) của hạt-vật chất. Partiki là ý thức phi cực, Omni Polar, nghĩa là sở hữu tính điện và từ đồng thời không có cực. Partiki, Particum, Partika là máy phát chia-hợp tự tái tạo, liên tục vận động pha. Điều này được gọi là Partiki phasing (pha Partiki).</p>
                                        <p>Từ sự tương tác chia-hợp của ba hạt nguyên thủy, lĩnh vực điện từ (Electromagnetic Fields) của tần số âm thanh và quang phổ ánh sáng tạo nên và duy trì vũ trụ được sáng tạo và duy trì. Tần số (Frequency) được xác định theo tỷ lệ rung động pha Partiki (tỷ lệ giữa dao động ngoại và rung động nội).</p>
                                        <p>Từ ba hạt, mọi hạt và sóng của vũ trụ hologram phát sinh và vạn vật được tạo thành. Các dải tần số (Frequency Band) chiều được hình thành bằng cách hạ thấp dần góc xoay hạt (ARPS) và tỷ lệ rung động. Các hạt mà vật lý hạt hiện đại phát hiện ra phát triển từ ba đơn vị ý thức gốc. Thông qua đặc tính vật lý của Partiki, các hạt như photon, lepton, muon, fermion, gauge boson... xuất hiện. Thông qua đặc tính của Partika, các hạt như electron, hadron, quark, fermion... xuất hiện, còn qua Particum, các hạt như proton, gluon... xuất hiện. Các hạt vi mô trên trái đất xuất hiện dựa trên mối quan hệ giữa ARPS (Angular Rotation Particle Spin, góc xoay hạt) và dải tần số của chiều cụ thể.</p>
                                        <p><b>1.4.3 BA THÀNH MỘT</b></p>
                                        <p>Hai hạt phân cực từ phi cực thành dao động âm thanh, dao động ánh sáng lại hợp nhất thành Void (khoảng trống), Partiki. Ba đơn vị ý thức là mạch đập (xung) ý thức liên tục tuần hoàn, cung cấp nhiên liệu và sáng tạo ý thức Nguồn Thần thành vũ trụ khuôn mẫu hologram. Chu kỳ chia-hợp pha (Partiki phasing) của ba hạt là chuyển động tam vị nhất thể, qua đó tạo ra trường hình thái phát sinh (Morphogenetic Field), khung của vật chất. Dòng chảy ý thức (Stream of Consciousness) của Nguồn Thần trải rộng thành trường hình thái phát sinh của quang phổ ánh sáng và rung động âm thanh, là mô vải cơ bản của khuôn mẫu hologram. Nhị nguyên điện từ xuất phát từ VOID, điểm trung tâm tĩnh lặng (Partiki, Omni Polar) của ý thức nguồn. Nhị nguyên đạt được cân bằng và hài hòa thông qua tam vị nhất thể, trở về điểm trung tâm để hoàn thành Một là Ba (Law of ONE).</p>
                                        <p>Vạn vật được tạo thành từ các hạt ý thức của nguồn. Mọi vạn vật đều là những hiện tượng tạm thời bắt nguồn từ "một sự sống" vượt lên hình tướng. Mọi vật thể, mọi cơ thể và hình tướng đều xuất phát từ nguồn "một sự sống" này.</p>
                                        <p style={{textAlign: 'center'}}>
                                            <i>“Đạo sinh Nhất, Nhất sinh Nhị, Nhị sinh Tam, Tam sinh Vạn Vật”
                                                <br /> - Lão Tử (Đạo Đức Kinh) -
                                            </i>
                                        </p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-5">1.5.	KRYST: Ký hiệu Sự sống Vĩnh hằng</h6>
                                    <div className="textContent lazy-item">
                                        <p>Sự sáng tạo của nguồn bắt đầu bằng "ý đồ (Intention)". Từ ý đồ, chất liệu nguyên thủy (chất liệu gốc của tam vị nhất thể) hiện thực hóa ý đồ đã xuất hiện. Ý đồ thể hiện thành "rung động âm thanh cốt lõi đầu tiên". Rung động âm thanh tạo ra một tông cộng hưởng năng lượng cụ thể và được mã hóa thành thông tin năng lượng. "Mã năng lượng" được gọi là Ký hiệu (Encryption). Do đó, ký hiệu là ý định cốt lõi mà năng lượng ý thức mang theo, trở thành một chữ ký, dấu hiệu riêng. Giống như chúng ta thể hiện danh tính (identity) của mình bằng chữ ký, ý thức cũng có chữ ký, ký hiệu năng lượng độc đáo của riêng mình để thể hiện ý định của mình. Mọi ý thức đều có ký hiệu năng lượng độc đáo của riêng mình.</p>
                                        <p>Ý đồ của Nguồn Thần khi sáng tạo vũ trụ nằm ở "sáng tạo Sự sống Vĩnh hằng (Eternal Life Creation)". Ý đồ "Sự sống Vĩnh hằng" đã tạo ra âm thanh thiêng liêng đầu tiên, bảy âm thanh - tông cộng hưởng:
                                            <br /><i>Ka Ra Ya Sa Ta Ha La</i>
                                            <br />Ghép âm tiết đầu lại thành:
                                            <br /><b>Kryst Hala</b>
                                        </p>
                                        <p>Ý định Sáng tạo Sự sống Vĩnh cửu của Thuượng đế được thể hiện thông qua cộng hưởng âm thanh và mã hóa năng lượng cốt lõi gọi là "KrystHala",. KrystHala là công thức sự sống vĩnh cửu tạo nên muôn vật.
                                            Các hạt nguyên thủy của Vũ trụ sáng tạo được cố định hóa (Krystallization) thành Mẫu hình thông qua mã hóa Kryst Hala. Mã hóa Kryst Hala hình thành các họa tiết hình học và số học, nơi ánh sáng (Ka) và âm thanh (Tha) hợp nhất (Ra) tạo thành Mẫu hình Hiển hiện cơ bản, Krystal (Ka-Tha-Ra).
                                        </p>
                                        <p>Tài liệu Freedom Teachings của Các Giám hộ là hệ thống tri thức dịch từ 12 đĩa CDT-Plates thông tin vũ trụ. Theo tài liệu, 12 bộ tộc Angelic Human cổ xưa từng được gọi là "Quân đoàn truyền khẩu (Legion) 12 CDT-plate" để bảo vệ và dịch thuật các ghi chép lịch sử trong CDT-plate, được gọi là "Krys-thl'-a".  "Kryst-al-la" được biết đến như "nguồn gốc chung" mà từ đó các tôn giáo và cảm hứng khoa học ban đầu xuất hiện. Quân đoàn truyền khẩu (legion) Angelic Human đã dịch và bảo tồn các hồ sơ của CDT-Plates từ nhiều giai đoạn lịch sử và văn hóa khác nhau. Các bản dịch sớm nhất của Krystalla về các tài liệu bằng văn bản đã được dịch sang nhiều ngôn ngữ và truyền thống truyền miệng khác nhau với tên gọi "Re-ligions" (tôn giáo). (Đây là nguồn gốc của từ tôn giáo).
                                        </p>
                                        <p>Các bản dịch ban đầu của giáo lý CDT như Cổ văn Ấn Độ được cho là độc đáo nhưng bổ sung cho nhau, thể hiện quan điểm thống nhất. Theo thời gian, 12 tôn giáo nguyên thủy của các người thiên thần trên Trái đất vốn bổ sung cho nhau dần trở nên cạnh tranh và mâu thuẫn với nhau do bị bóp méo thành các giáo điều kiểm soát. Krysthala, khắc âm sáng tạo sự sống vĩnh cửu, bị bóp méo thành một từ chỉ một tôn giáo cụ thể. (K ban đầu bị thay đổi thành C).</p>
                                        <p>Mọi sinh vật đều là những thực thể sự sống vĩnh cửu của Chúa Kryst. Tuy nhiên, trên hành trình của mình trong cõi Tạo hóa, tùy thuộc vào cách sử dụng ý chí tự do, mỗi thực thể có thể tiến hóa theo con đường sự sống vĩnh cửu hay trải nghiệm sự suy thoái và sa đọa.</p>
                                        <p>Anti-Kryst (Phản-Kryst) đơn giản là những thực thể mà mã số khắc ghi sự sống vĩnh cửu Kryst bị bóp méo hay mất đi, bao gồm các cá nhân, chủng tộc, hệ thống.</p>
                                        <p>Năng lượng ý thức thể hiện công thức sáng tạo mang ý nghĩa cốt lõi thông qua các khắc ghi. Do đó, Phản Kryst thể hiện các "công thức sự sống nhân tạo giới hạn" là sự bóp méo công thức sự sống vĩnh cửu, và mang các khắc âm giả mạo và lừa đảo mượn từ khắc âm sự sống vĩnh cửu. (Ví dụ: 12 điểm ý thức và công thức căn chỉnh Krystic đặc biệt đại diện bởi Kathara bị bóp méo thành Kabbalah).</p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-6">1.6.	HAI ĐƯỜNG CONG TĂNG TRƯỞNG NĂNG LƯỢNG</h6>
                                    <div className="textContent lazy-item">
                                        <p>Mọi thực thể đều trao đổi hơi thở của ý thức và sự sống với nguồn. Ý thức Nguồn chảy vào thế giới sáng tạo như một hơi thở "liên tục tuần hoàn và tự tái tạo" theo công thức sáng tạo Krystic của chuyển động hạt nguyên thủy tam vị nhất thể. Hơi thở trao đổi giữa nguồn và các sinh vật tạo ra chuyển động tuần hoàn xoáy năng lượng. </p>
                                        <p>Công thức năng lượng tuần hoàn của sự sống vĩnh cửu là "Merkaba". Mer có nghĩa là chuyển động, Ka là ánh sáng, Ba là thân thể. "Merkaba" có nghĩa là "thân thể ánh sáng chuyển động", chỉ sự trao đổi hơi thở Krystic vĩnh cửu giữa Thượng đế và các thực thể sống tạo ra chuyển động xoáy năng lượng. Mọi thực thể đều có Merkaba, và Merkaba Krystic có hình dạng và tốc độ quay nhất định.</p>
                                        <p>CChuyển động tuần hoàn năng lượng xuất hiện theo hai đường cong tăng trưởng khác nhau, tùy thuộc vào việc nó là mô hình hữu cơ và vĩnh viễn xuất phát từ quy luật sự sống vĩnh hằng (Krystic), hay là mô hình nhân tạo và vô cơ của sự sống hữu hạn. Nghĩa là, tùy theo ý định-ký hiệu cốt lõi, nó sẽ có sự sắp xếp toán học và công thức khuếch tán khác nhau. </p>
                                        <p><b>1.6.1.	So sánh Dãy số Fibonacci và Dãy số Krystal</b></p>
                                        <p>Chuyển động pha tuần hoàn của các hạt ý thức gốc tạo ra sóng tam vị nhất thể. Thế giới sáng tạo hologram được tạo thành từ năng lượng ý thức nguồn phản ánh đường cong tăng trưởng năng lượng Krystic của "sự sống vĩnh hằng". Đường cong này được gọi là "Xoắn ốc Krystal (Krystal Spiral)". Tuy nhiên, khi mất kết nối với ý thức nguồn, sóng tam vị nhất thể bị bóp méo thành sóng nhị nguyên.</p>
                                        <p>Dãy số Fibonacci nổi tiếng được nhà toán học người Ý ở thế kỷ 12 Leonardo Fibonacci khám phá, là quy luật tăng trưởng toán học tuân theo tỷ lệ vàng Phi (Φ) tìm thấy trong tự nhiên. Tuy nhiên, đây chỉ là đường cong tăng trưởng hữu hạn chịu ảnh hưởng của ánh sáng vô cơ (inorganic light), trong khi đường cong tăng trưởng hữu cơ và vĩnh cửu (organic) lại tồn tại riêng biệt. Các nguyên lý toán học tạo ra ánh sáng có thể được phân biệt dựa trên việc chúng phản ánh nguyên lý sự sống vĩnh cửu hay chỉ thể hiện sự sống hữu hạn. Để hiểu rõ hơn, hãy xem xét các biểu đồ minh họa sau: </p>
                                        <p className='imgCenter'><img src="/static/images/chapter1-img1.png" loading="lazy" />
                                            <br /><i>Ánh sáng nhị nguyên và ánh sáng tam vị nhất thể. [Nguồn sơ đồ: Freedom Teaching – Guardian Material]</i>
                                        </p>
                                        <p>
                                            Sơ đồ 1) Ánh sáng nhị nguyên (Bi-Wave polarity) vô sinh phát sinh khi năng lượng âm và dương gặp nhau và tiêu tan.
                                            <br />Sơ đồ 2) Ánh sáng tam vị nhất thể (Tri-Wave unity) hữu cơ phát sinh khi phân chia/hợp nhất với Ý thức Nguyên Thủy (Nguồn)
                                            <br />Sơ đồ 1) Hình cá trong hình hoa sen nơi hai vòng tròn chồng lên nhau được gọi là "Vesica Piscis". Nó tượng trưng cho sự kết hợp của Âm và Dương, và được mô tả như "tử cung của vũ trụ" nơi vũ trụ được sinh ra.
                                            <br />So với sóng Tam nguyên (Tri-Wave) kết nối với Nguồn gốc ở Biểu đồ 2, sóng Nhị nguyên (Bi-Wave) trong Biểu đồ 1 là vô sinh, khi Âm và Dương va chạm tạo ra ánh sáng rồi biến mất vì không có nguồn gốc để quay trở lại. Do đó, quy luật "Sáng tạo Nhị nguyên" (Duality Creation) tách rời khỏi Nguồn gốc đòi hỏi năng lượng liên tục từ bên ngoài để duy trì bản thân. Thế giới Nhị nguyên chia cắt thành Âm và Dương, va chạm đối kháng liên tục rồi biến mất vào hư vô. Sự sụp đổ là điều tất yếu ở cuối quá trình tăng trưởng Nhị nguyên.
                                        </p>
                                        <p>
                                            Quy luật "Vesica Piscis" của sáng tạo Nhị nguyên là nền tảng của "Hình học Metatronic" (Metatronic Geometry) có lịch sử sa đọa lâu dài trong cõi vũ trụ Kinh nghiệm. Metatronic là tên của một chủng tộc ý thức. Họ phát triển công nghệ Hố đen Nhân tạo chết chóc bằng cách bóp méo luật sáng tạo Sự sống Vĩnh cửu.
                                        </p>
                                        <p><b>1.6.2.	Dãy số Fibonacci </b></p>
                                        <p>Bắt đầu từ số 0 và 1, dãy số 'Fibonacci' tuân theo quy luật đơn giản là cộng liên tục hai số trước đó để tạo ra số tiếp theo. Dãy số này vẽ nên một đường cong phi đối xứng ngày càng lớn và dần rời xa điểm bắt đầu '0' (nguồn).</p>
                                        <p><b>1.6.3.	Dãy số Krystal </b></p>
                                        <p>Ngược lại, dãy số Krystal theo hình dạng lưới Krystal sử dụng tổng của tất cả các số trước đó chứ không chỉ 2 số gần nhất để tạo số tiếp theo.  Do đó, nó tạo ra một đường cong tăng trưởng đối xứng quanh điểm bắt đầu.</p>
                                        <p className='imgCenter'>
                                            <img src="/static/images/chapter1-img2.png" loading="lazy" />
                                            <br />Biểu đồ: So sánh dãy Fibonacci và đường xoắn ốc Krystal. [Nguồn: Giáo lý Giải phóng, Tài liệu Các Hộ pháp]
                                        </p>
                                        <p>Khi xem xét kỹ các hình vẽ dưới đây, có thể thấy đường xoắn ốc Krystal bắt đầu từ trung tâm, trong khi đường xoắn ốc Fibonacci lại bắt đầu lệch về một bên. Khi tăng trưởng, đường xoắn Fibonacci dịch chuyển điểm trung tâm và xa dần điểm khởi nguyên (nguồn gốc) năng lượng. Do đó, nó lặp lại mô hình tăng trưởng tiêu thụ năng lượng, hút năng lượng từ điểm ảo luôn thay đổi, cho đến khi không còn năng lượng để hấp thụ thì tăng trưởng dừng lại và dẫn đến cái chết.</p>
                                        <p>Ngược lại, đường xoắn ốc Krystal tăng trưởng xoay quanh nguồn gốc, tự tạo ra năng lượng cần thiết mà không cần hút từ bên ngoài. Vì lý do này, trong tài liệu Các Hộ Pháp, đường xoắn Fibonacci được gọi là "Fib.of.no.Chi", có nghĩa là "sự lừa dối về năng lượng cạn kiệt".</p>
                                        <p className='imgCenter'>
                                            <img src="/static/images/chapter1-img3.png" loading="lazy" />
                                        </p>
                                        <p>Biểu đồ so sánh đường cong Fibonacci và Krystal cho thấy đường cong Fibonacci ban đầu tăng trưởng nhanh hơn đường cong Krystal, nhưng sau đó dần trở nên nhỏ hơn và uốn cong vào bên trong.
                                            <br />Theo tài liệu "Sự ra đời hữu cơ của con người", vào ngày thứ 6 sau khi noãn và tinh trùng thụ tinh và phát triển, đột biến bắt đầu. Vào ngày thứ 7 (Trục 7) khi đường tăng trưởng 'Krystal' giao nhau với đường tăng trưởng 'Fibonacci', một điểm khai thác năng lượng xảy ra khiến nó bị 'cướp đoạt' (hijacking) sang quỹ đạo 'Fibonacci'. Điều này làm thay đổi con người phát triển thành một sinh vật có tuổi thọ hạn chế và tiêu hao năng lượng của chính mình. Điểm khai thác năng lượng này được gọi là 'sự ràng buộc/trói buộc của Ngôi sao Chết Metatronic' (Metatronic Death Star Harness).
                                        </p>
                                        <p className='imgCenter'>
                                            <img src="/static/images/chapter1-img4.png" loading="lazy" />
                                            <br />#Biểu đồ: Mạng lưới Hành tinh Chết Metatronic
                                            <br />Metatronic Death Star Harness
                                        </p>
                                        <p>
                                            Quá trình lưu thông năng lượng tồn tại dưới dạng hai mẫu dòng năng lượng điện từ. Một là dòng chảy hình Âm Dương kín, tuần hoàn trong mạch kín với tuổi thọ hữu hạn. Kiểu thứ hai là hệ thống Krystic mở, tuần hoàn theo hình dạng con bướm Yan Yun với tính vĩnh cửu.
                                            <br />Sự khác biệt về dòng chảy năng lượng của Yin-Yang và Yan Yun xuất phát từ góc độ và cách thức quay của Merkaba.

                                        </p>
                                        <p><b>1.6.4.	Yin-Yang và Yan-Yun</b></p>
                                        <p><i>(*) Yin-Yang và Yan Yun là hai khái niệm đối lập nhau về năng lượng:
                                            <br />- Yin Yang: Là năng lượng nhị nguyên, tương tác đối kháng giữa hai phần tử âm và dương. Biểu tượng Âm Dương thể hiện sự cân bằng giữa hai thái cực. Năng lượng Âm Dương tuần hoàn trong mạch kín với tuổi thọ hữu hạn.
                                            <br />- Yan Yun: Là năng lượng tam nguyên, dòng chảy liên tục từ nguồn gốc đến vô cực. Biểu tượng Yan Yun là hình dạng con bướm, thể hiện sự kết nối giữa trên và dưới, trái và phải. Năng lượng Yan Yun tuần hoàn trong mạch mở với tính vĩnh cửu.
                                            <br />Nói cách khác, Yin-Yang là năng lượng nhị nguyên hữu hạn, còn Yan Yun là năng lượng tam nguyên vô cực. Chúng tạo ra hai dòng chảy năng lượng khác nhau.
                                        </i>
                                        </p>
                                        <p className='imgCenter'>
                                            <img src="/static/images/chapter1-img5.png" loading="lazy" />
                                            <br /># Biểu đồ: Các mô hình năng lượng Yin Yang và Yan Yun
                                        </p>
                                        <p className='imgCenter'>
                                            <img src="/static/images/chapter1-img6.png" loading="lazy" />
                                            <br /># Biểu đồ: Các mô hình năng lượng Yin Yang và Yan Yun của Trái Đất
                                        </p>
                                        <p>
                                            Tóm lại, công thức tăng trưởng-mở rộng của "Dãy số Krystal" và "Dãy số Fibonacci" cho thấy sự khác biệt giữa nguyên lý Krystic của sự sống vĩnh cửu và nguyên lý Anti-Krystic của sự sống nhân tạo hữu hạn.
                                        </p>
                                        <p>Dãy Fibonacci tính toán số tiếp theo bằng cách cộng hoặc "ăn" hai số trước đó. Nếu xem mỗi số là một lượng tử năng lượng, thì các số trước đó phải bị thêm vào và tiêu hao để tạo ra số tiếp theo, khiến vị trí cũ của chúng trống rỗng, biến mất. Nghĩa là các số trước trở thành các lượng tử hữu hạn phải chết đi để sinh ra số sau. Đây là công thức năng lượng "tái chế năng lượng hữu hạn" để tạo ra tăng trưởng, thay vì tăng trưởng thông qua sức mạnh tự tái tạo.</p>
                                        <p>Ngược lại, sự mở rộng toán học của dãy Krystal, nhân các số trước đó để tạo ra số tiếp theo. Quá trình này tuân theo cách gia tăng cụ thể của lưới Kathara, với đặc điểm là giữ nguyên vẹn các số trước. Nói cách khác, tính vĩnh cửu của các lượng tử trước được duy trì ổn định. Sức mạnh tự tái tạo được sử dụng để tăng cường lượng tử của các số trước, nhằm tạo ra số tiếp theo độc nhất. Các số trong dãy Krystal luôn duy trì mối liên hệ với điểm trung tâm mà chúng bắt nguồn, tồn tại với tính độc nhất của riêng mình trong bối cảnh tăng trưởng, đồng thời thể hiện hình thái năng lượng vĩnh hằng. Thế hệ tiếp theo được sinh ra từ sự gia tăng tương đối của lượng tử trước, với lượng tử duy nhất của riêng nó. Đây là công thức năng lượng “tạo ra tăng trưởng mới thông qua sức mạnh tự tái tạo vĩnh cửu”. </p>
                                        <p>Điểm khác biệt rõ ràng nhất giữa hai đường cong là sự kết nối với Nguồn (Source). </p>
                                        <p>Dãy Krystal xuất phát từ điểm trung tâm của sự sáng tạo. Nó duy trì mối quan hệ hô hấp sống động và mở rộng co lại với điểm trung tâm bằng cách “bảo tồn những thứ đã tồn tại trước đó”.</p>
                                        <p>Ngược lại, dãy Fibonacci xuất phát từ một “điểm bám dính” vào một hình thái sống động, hút cạn và vô hiệu hóa những thứ tồn tại trước để mở rộng, dần mất đi sự tiếp xúc với điểm bám dính ban đầu. Đây là lý do tại sao Fibonacci được gọi là “Fib của Không Có Chi (năng lượng)”, theo nguyên lý năng lượng sai lệch. Sản phẩm cuối cùng của nó là sự tự hủy, vì vậy nó còn được gọi là “Biểu đồ tự hoại”. Kỹ thuật Âm Dương Metatronic được gọi là “kỹ thuật tử vong hoa tàn” hay “kỹ thuật chết chóc”.</p>
                                        <p>Theo tài liệu Người Giám hộ, Âm Dương (Yin-Yang) được mô tả là hai dòng điện từ bằng lượng tử đối nghịch nhau của hai thái cực, không hữu cơ. Âm Dương là kỹ thuật lỗ đen chống lại năng lượng động lực học Yan-Yun sáng tạo sự sống vĩnh cửu Krystic của vũ trụ, bị biến đổi và bóp méo thành kỹ thuật nhân tạo chống đối Krystic của sự sống giới hạn. </p>
                                        <p>Trong lịch sử vũ trụ, loài người được gọi là “Người thiên thần”. Tổ tiên ban đầu của Người thiên thần là chủng tộc Turaneusiam trên hành tinh Tara ở mật độ 2. Khi gen Người thiên thần Turaneusiam lần đầu được tạo ra theo “đường cong tăng trưởng Krystal”, họ không bị giới hạn về tuổi thọ và có thể sống chỉ bằng hô hấp năng lượng Trái đất. Tuy nhiên, sự xâm nhập của các chủng tộc ý thức đã khiến lưới điện từ Trái đất bị gieo trồng mã Metatronic chống lại Krystic. Gen của Người thiên thần bị pha trộn với nhiều chủng tộc xâm lược dẫn đến việc con người ngày nay có tuổi thọ ngắn hơn, trải nghiệm cái chết và phải tiêu thụ các sinh vật khác để tồn tại. </p>
                                        <p>Đường cong năng lượng Fibonacci được tìm thấy trong các hiện tượng tự nhiên của Trái đất. Vậy sự tồn tại năng lượng thực sự của Fibonacci ảnh hưởng đến tâm trí và cuộc sống chúng ta như thế nào?</p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-7">1.7.	HAI HẠT GIỐNG TRONG CHÚNG TA</h6>
                                    <div className="textContent lazy-item">
                                        <p>Trong xã hội loài người rộng lớn và phức tạp, nỗi đau mà tâm trí gây ra dường như vô tận, như thể sống trong một cái bẫy. Khi đi vào trạng thái năng lượng gốc của bất kỳ hiện tượng nào, chúng ta có thể nhận ra điều gì đang xảy ra, thực tại đích thực. Đường cong tăng trưởng năng lượng Fibonacci cung cấp manh mối cơ bản để hiểu trạng thái cuộc sống và tâm trí con người. </p>
                                        <p>Bản chất thực sự của con người bắt nguồn từ khuôn mẫu thiêng liêng. Khuôn mẫu thiêng liêng tạo ra đường cong tăng trưởng năng lượng Krystal. Đó là sức mạnh và cách tồn tại tự nhiên của sự tồn tại của chúng ta, là điểm tĩnh lặng-trung tâm bên trong, sức mạnh tự tái tạo năng lượng, và sự hội nhập của bản ngã. Tuy nhiên, tâm trí của chúng ta khi sống trong thực tại bị trói buộc bởi mô hình năng lượng Fibonacci. Hãy ví von mô hình Krystal và Fibonacci như hai hạt giống được gieo trong tâm trí chúng ta. Tùy thuộc vào việc chúng ta tưới nước và nuôi dưỡng hạt giống nào, chúng ta sẽ trải nghiệm các trạng thái tâm trí hoàn toàn khác nhau.</p>
                                        <p><b>Trung tâm tĩnh lặng VS Điểm ảo huyễn (điểm ảo)</b></p>
                                        <p>Partica là hạt nguyên tử của ý thức, là trung tâm rỗng (Void-Still point) của cả hai cực điện: cực và không cực Omni Polara (cực tính, vô cực). Nó là một điểm trung tâm rỗng và yên lặng. Từ trung tâm này, hai dòng năng lượng cực âm (+) và cực dương (-) ánh sáng và âm thanh phát ra. Các hạt partica điều chỉnh tính chất hai cực thông qua Trung tâm bất động (Still-Center-Point) bắt nguồn từ Ý thức nguyên thủy để đạt được sự cân bằng. Do đó, Trung tâm bất động  (Still-Center-Point) là nguồn gốc của sự tồn tại của chúng ta. Đó là suối nguồn nội tâm vĩnh cửu nơi năng lượng sống phun trào bên trong. Chúng ta nhận biết bản thể vĩnh cửu và trải nghiệm sự thống nhất đầy đủ trọn vẹn (Wholeness), sự thiêng liêng (Holyness) của sự tồn tại cá nhân thông qua Trung tâm bất động.  </p>
                                        <p>Mất điểm tĩnh lặng-trung tâm có nghĩa là đánh mất dòng suối sự sống nội tâm và rời xa nhận thức bản thể vĩnh hằng. Đánh mất hoặc xa rời điểm trung tâm nội tại khiến tâm trí chỉ nhìn thấy "thực tại chiều đó" mà nó đang ở. Tâm trí mất nền tảng cấu trúc bản chất và xa rời thực tại bản ngã lớn hơn mất cân bằng và rơi vào sợ hãi. Đây là trạng thái tâm trí bất an và hỗn loạn của bản ngã. Bởi vì nó đã đánh mất sự kết nối với ý thức lớn hơn cấu thành nên nó, và sự toàn vẹn của toàn bộ bản ngã. Bản ngã coi thực tại mà nó đang tồn tại là tất cả và bị mắc kẹt trong trạng thái tâm trí lo sợ và ám ảnh rằng phải sống sót trong thực tại.</p>
                                        <p>Khi xa rời điểm tĩnh lặng-trung tâm của sự tồn tại, chúng ta mất khả năng cân bằng điều chỉnh cực tính và bị trói buộc vào nhị nguyên. Điểm mấu chốt của công thức năng lượng Fibonacci là làm méo mó bản chất tam vị nhất thể thành nhị nguyên bằng cách khiến ta xa rời điểm trung tâm (nguồn). Nói ngắn gọn, hạt giống Fibonacci là công thức năng lượng phá vỡ bản chất con người. Hạt giống Fibonacci chuyển điểm trung tâm, nguồn sức mạnh và năng lượng sự sống của chúng ta từ bên trong không phải tới bản thân mà tới các đối tượng bên ngoài. Nghĩa là khiến "điểm trung tâm" nội tâm hướng về "điểm ảo" bên ngoài. Quá trình hạt giống này phát triển và tạo thành đường cong tăng trưởng ảnh hưởng đến toàn bộ cuộc đời con người, trói buộc cuộc sống con người vào mô hình Fibonacci. Nỗi đau sự tồn tại của chúng ta do điều này gây ra là vô cùng lớn.</p>
                                        <p className='imgCenter'>
                                            <img src="/static/images/chapter1-img7.png" loading="lazy" />
                                            <br />#Biểu đồ: So sánh mẫu năng lượng Fibonacci và mẫu năng lượng Crystal
                                        </p>
                                        <p>
                                            <b>Hãy xem cuộc sống của chúng ta.</b>
                                        </p>
                                        <p>
                                            Chúng ta học rằng tất cả những gì cần thiết cho chúng ta đều "bên ngoài" ngay từ khi sinh ra. Tôn giáo dạy rằng bạn là kẻ tội lỗi, yếu đuối và bất lực nên bạn phải tin vào vị cứu tinh bên ngoài. Điều này chuyển nguồn năng lượng bên trong kết nối với Thượng đế sang sức mạnh cứu rỗi bên ngoài. Khoa học tuyên bố không có giá trị tinh thần trong vật chất hoặc cơ thể và phủ nhận bản chất tinh thần thực sự của ý thức được thể hiện thông qua vật chất-cơ thể. Hệ thống xã hội cho chúng ta biết rằng các điều kiện và tiêu chuẩn để con người hạnh phúc đều nằm "bên ngoài". Để trở nên hạnh phúc, bạn phải thành công, và để thành công, bạn phải so sánh và cạnh tranh với người khác. Trong quá trình này, trung tâm liên tục di chuyển. Bởi vì nó tạo ra "điểm ảo" để chinh phục mục tiêu. Chúng ta được cho biết mỗi khi chinh phục điểm này, chúng ta sẽ có được điều mình muốn. Tâm hồn đã lạc mất “Trung tâm tĩnh lặng” thật sự của bản thân, cách xa sự suối nguồn sự sống bên trong, mất đi cảm giác thống nhất và toàn vẹn chỉ biết lao đi như con châu chấu từ điểm này sang điểm khác, hô to "lần này..! lần này..!!" Để trở nên hạnh phúc, mục tiêu kéo dài suốt cuộc đời: đại học tốt hơn, công việc tốt hơn, lựa chọn người bạn đời tốt hơn, một cuộc hôn nhân tốt hơn,... Tiếp tục như vậy.
                                        </p>
                                        <p>
                                            Xã hội con người công thức hóa "điểm ảo huyễn" và lấy nó làm tiêu chuẩn và là mô hình cho cuộc sống. Ở đỉnh của kim tự tháp xã hội được cấu trúc, các mô hình thành công xã hội nắm giữ điểm ảo huyễn tươi cười rạng rỡ. Mọi người đều theo đuổi cùng một điểm ảo huyễn gây ra sự cạnh tranh dữ dội về mặt cấu trúc và khiến những người bám víu vào nó trở nên giống nhau về cách suy nghĩ, giá trị, hành vi. Các phương tiện truyền thông đại chúng và các chương trình giáo dục xã hội chính thức trình bày "các tiêu chuẩn công cộng an toàn xã hội" như là “công thức cho cuộc sống”. "Tiêu chuẩn phổ biến và ý thức tập thể an toàn xã hội" sẽ trở thành điểm tưởng tượng cho ý thức phổ biến và khi ý thức bổ biến được coi là hiện thực thực sự. Sân khấu hiện thực khổng lồ của ý thức phổ biến quy định các điều kiện và cách sống của con người trong nó. Cuộc sống của con người dần dần bị mắc kẹt trong không lĩnh vực thực tế tập thể, và tính cá nhân độc đáo của mỗi cá nhân đã bị chiếm dụng và kẹt vào những ảo tưởng sai lầm.
                                            <br />Mặt khác, hạt giống krytal nằm trong “Trung tâm tĩnh lặng” vốn đã là tự chủ. Nó không mất đi tính cá nhân độc đáo của nó, được kết nối với ý thức tâm linh của chính nó. Điểm “trung tâm yên tĩnh” này cho phép chúng ta nhận ra "một cảm giác sai lầm về an toànxã hội" và "ảo tưởng ý thức tập thể". “Trung tâm yên tĩnh” đánh thức trí tuệ bên trong, khám phá sự thông tuệ và thâm nhập vào sự lừa dối của các giả thuyết và thức tỉnh con người khỏi bức màn che phủ cuộc sống giả (ma trận). Cuộc sống của “Trung tâm yên lặng” Silent Center Point không theo đuổi một cuộc sống khai thác năng lượng, cướp bóc năng lượng, đó là nơi cố gắng ép buộc bản thân phải phù hợp với các tiêu chuẩn và mô hình xã hội, hoặc lấy đi những gì nó không có từ bên ngoài.
                                            <br /> <i>* Ở đoạn trên nói rất rõ về những enery vampire, những người ái kỉ, luôn tìm kiếm bên ngoài nên họ đau khổ.</i>
                                        </p>
                                        <p>Ý thức con người được kết nối với Nguồn vô hạn và vĩnh cửu, cung cấp năng lượng tái tạo liên tục thông qua "Điểm trung tâm tĩnh lặng" bên trong. Cuộc sống dựa trên điểm tâm linh trung tâm ấy không làm mất đi cá tính cá nhân, mà lại nuôi dưỡng và bồi đắp năng lượng nội tại ở mỗi người, để thể hiện sức mạnh sáng tạo độc đáo của tâm hồn trong cuộc sống. Đường cong tăng trưởng năng lượng phát xuất từ trung tâm sâu thẳm bên trong con người tạo nên sự hứng khởi và đam mê nội tại, nghĩa là sức mạnh để vững bước tiến lên phía trước, thậm chí khi gặp thất bại hay khó khăn, để xây dựng nên bản chất đích thực của mình. Với nguồn năng lượng ấy, không có gì bị lãng phí hay mất mát.</p>
                                        <p>Tuy nhiên, trong quá trình theo đuổi những điểm mục tiêu ảo ảnh, con người không thể tránh khỏi việc phải tiêu tốn một lượng năng lượng to lớn. Bởi vì các mục tiêu đó về bản chất chỉ là những chuẩn mực áp đặt từ bên ngoài. Việc cố gắng nhồi nhét bản thân vào khuôn khổ của những mục tiêu xã hội ấy không phải là tự nguyện, mà là một trạng thái tâm trí bị lôi cuốn, khiến con người rơi vào tình trạng lo âu và căng thẳng. Tâm trí càng mệt mỏi vì phải vất vả bám víu vào những mục tiêu nhân tạo ấy, càng phải chịu đựng nhiều căng thẳng tinh thần như trầm cảm, hoảng loạn, kiệt sức.</p>
                                        <p>Đường cong tăng trưởng theo cấp số nhân của Fibonacci tạo ra những chu kỳ lặp đi lặp lại tiêu hao và bóc lột năng lượng tinh thần khiến tâm hồn con người sa vào vũng lầy trì trệ.</p>
                                        <p>Đường cong Fibonacci luôn vô hiệu hóa những thành quả trước đó để tìm kiếm những mục tiêu mới, chứ không tích lũy hay xây dựng dựa trên nền móng đã có. Nói cách khác, cuộc sống con người không có sự liên kết và phát triển dựa trên quá khứ và hiện tại. Cách sống đó như đổ nước vào một cái thùng rỗng, chỉ mang lại những thành quả nhất thời, để lại một lỗ trống sâu hoắm trong tâm hồn. </p>
                                        <p>Năng lượng sống phát sinh từ trung tâm bên trong con người sẽ mang lại niềm hạnh phúc chân thực bởi sự mở rộng và hòa nhập của tâm thức. Nhưng các mục tiêu nhân tạo chỉ đem lại niềm an ủi nhất thời, giống như liều thuốc giảm đau tạm thời. Sự hài lòng nhất thời ấy không phải hạnh phúc, mà chỉ như một liều ma túy, khiến con người nghiện ngập. Tâm trí càng nghiện vui thú nhất thời ấy càng tìm kiếm nhiều hơn, đến mức trở nên cuồng loạn và đau khổ chứ không còn là hạnh phúc thực sự.</p>
                                        <p>Hai hạt giống Fibonacci và krystal nuôi dưỡng hai thái độ tâm trí khác biệt trong con người.</p>
                                        <p>Hạt giống Fibonacci nuôi dưỡng tâm trí vô thức. Tâm trí vô thức là tâm trí thiếu hiểu biết khiến ta chỉ nhìn thấy "hiện tượng xuất hiện" trong cuộc sống. Tâm trí thiếu hiểu biết không nhận ra "trách nhiệm tự thân của sự tồn tại" đối với các vấn đề xảy ra trong cuộc sống. Bởi vì nó khiến người ta tìm kiếm nguyên nhân của mọi vấn đề từ bên ngoài, từ đó khiến người ta bám vào chính vấn đề để vật lộn. Đây là thái độ tâm trí vô thức "bám vào-phản ứng tự động với vấn đề bên ngoài". Thái độ phản ứng tự động với vấn đề tái tạo vấn đề thành những mô hình lặp đi lặp lại và tiêu hao năng lượng sống.</p>
                                        <p>
                                            Khi tâm trí xoay theo mô hình vô thức, nó cho rằng vấn đề và tôi xuất hiện một cách tách biệt, có thái độ trốn tránh vấn đề hoặc đổ lỗi cho đối tượng bên ngoài. Kết quả là, khi bị trói buộc trong mô hình năng lượng Fibonacci, tâm trí vô thức tự coi mình là nạn nhân của thế giới và bị đong đưa trong "trò chơi năng lượng ma cà rồng đổ lỗi nạn nhân-kẻ gây hại" của con lắc nhị nguyên. Điều đó khiến quên đi trách nhiệm tự thân với tư cách "người sáng tạo có ý thức" tạo ra cuộc sống thông qua tự do ý chí.
                                        </p>
                                        <p>Ngược lại, hạt giống Krystal đánh thức tâm trí có ý thức. Tâm trí có ý thức là ý thức cao hơn nhận ra mô hình năng lượng đằng sau các hiện tượng xuất hiện trong cuộc sống. Tâm trí có ý thức là điểm tĩnh lặng-trung tâm phía sau tâm trí, tồn tại trong thế giới nhưng không chìm đắm trong thế giới. Tâm trí có ý thức khiến chúng ta nhận ra mọi vấn đề xảy ra trong cuộc sống từ quan điểm của người sáng tạo. Nói cách khác, nó giúp nhận ra rằng đằng sau mọi vấn đề xuất hiện đều có trách nhiệm nội tại. Do đó, tâm trí có ý thức đánh thức thái độ ý thức của sự tồn tại về việc chúng ta đang phản ứng năng lượng như thế nào đối với vấn đề. Điều này tạo ra sự thay đổi từ "bám vào-phản ứng tự động với vấn đề bên ngoài" sang "quan sát vấn đề từ điểm tĩnh lặng-trung tâm bên trong - chọn hành động của mình".</p>
                                        <p>Thái độ tâm trí này mang lại sức mạnh ý thức siêu nhiên để nhìn nhị nguyên từ điểm tĩnh lặng-trung tâm, và đánh thức sức mạnh đích thực của lựa chọn tự do ý chí. Điều đó giúp sống với tâm trí ý thức cao hơn "ở trong thế gian nhưng không thuộc về thế gian".</p>
                                        <p>Đa phần chúng ta vẫn còn sống theo năng lượng hao tổn của Fibonacci, chứ không phải năng lượng xây dựng của krystal. Đường cong Fibonacci đã ăn sâu vào mọi mặt của đời sống, khiến con người rơi vào vòng xoáy so sánh, cạnh tranh và đua đòi. Chúng ta cần phải thức tỉnh, thoát khỏi những cái bẫy vô hình ấy để tìm lại được bản chất và tiềm năng con người.</p>
                                        <p>Lão Tử đã nói "vô vi tự nhiên" (無爲自然).
                                            Quay về với tự nhiên có nghĩa là quay về với bản chất thật sự của con người. Đó là con đường khôi phục điểm trung tâm yên tĩnh kết nối với nguồn cội (Đạo), đó là con đường chân thật của nhân loại, là con đường tìm về với sự trong trắng và trong sáng nguyên thủy của sự sống vĩnh hằng.
                                        </p>
                                    </div>
                                </div>

                                <div className="chapter-part lazy-item">
                                    <h6 className="lazy-item" id="heading-1-8">1.8.	SỰ TRỞ VỀ VỚI TRẠNG THÁI TRONG SÁNG BAN ĐẦU (Return to Innocence)</h6>
                                    <div className="textContent lazy-item">
                                        <p><i>[Nguồn: Voyager 2: Bí mật của Amenti, Tài liệu của Guardians, 1999] </i></p>
                                        <p><b>1.8.1.	Cấu trúc của sự thiêng liêng, sự chữa lành của các tôn giáo thế giới</b></p>
                                        <p>Năm 208,216 trước Công nguyên, Breneau sụp đổ. Chủng tộc Angelic Human 12 chiều (D-12, ý thức Christos Maharata thiêng liêng, 12 chiều Stargate) trên trái đất phải chịu đựng nỗi đau lớn vì không thể tiếp cận được nữa. Trước khi sự kiện sụp đổ xảy ra, đối với Angelic Human, mọi trải nghiệm sống đều là lễ hội tâm linh vật chất tràn ngập niềm vui và lời cầu nguyện sống động. "Tôn giáo" lúc bấy giờ không phải là giáo điều kiểm soát tước đoạt sức mạnh và phẩm giá cá nhân. Thời đó, mọi người không chết. Họ có thể ý thức lựa chọn thăng hoa để rời khỏi mật độ trái đất thông qua hệ thống Stargate vũ trụ. Khoa học và tâm linh được hiểu hoàn toàn như một động lực sáng tạo từ cùng nguồn gốc. Trải nghiệm biểu hiện được hiện thực hóa thực sự là trải nghiệm hợp nhất (At-one-ment) với Thần-Nguồn (God-Source) yêu thương.</p>
                                        <p>Khi đó, chúng tôi biết rằng bản thân mình và mọi sinh vật trên trái đất đều là những biểu hiện được chúc phúc của Thần-Nguồn và tôn vinh mọi sinh vật. Trong những ngày thuần khiết cổ xưa, 12 bộ tộc Angelic Human được bổ nhiệm làm đồng-người bảo vệ của Thánh địa Hành tinh Trái đất. Mỗi bộ tộc được ủy thác một trong 12 CDT-plate Emerald Covenant: từ mỗi CDT-plate do 12 bộ tộc Angelic Human bảo vệ, các bản dịch truyền miệng và bản dịch văn bản đầu tiên trên trái đất về những lời dạy thiêng liêng của Đấng Sáng Tạo đã được tạo ra. Đây là 12 cuốn sách chân lý thiêng liêng được gọi là "tôn giáo". Các lời dạy trong sách thiêng bổ sung cho nhau. Chúng ca ngợi một Thần Nguồn duy nhất với vô số cái tên và không tên của tình yêu vĩnh cửu. Tất cả "sách thiêng" cổ xưa đang chia rẽ chủng tộc chúng ta ngày nay đều xuất phát từ 12 nội dung giáo lý Emerald Covenant từng được coi là thiêng liêng. Khi các thiên thần sa ngã xâm chiếm Atlantis, hỗn loạn nổi lên, và sau thời đại Atlantis, mô hình ma trận ma thuật chống-Kryst dần chiếm lĩnh trái đất, các tôn giáo thiêng liêng của chúng ta bị cướp bóc và gần như biến mất. Những giáo lý giả được đan xen vào sự thật và những nội dung vĩ đại nhất của chân lý bị loại bỏ hoàn toàn.</p>
                                        <p>Hơn 11.000 năm qua, con người và các chủng tộc lai Illuminati đã phủ nhận sự thật lịch sử và bản sắc chủng tộc, ký ức, sự kết nối của chúng ta với Lực-Thần (God-Force). Thay vì thế, họ hủy diệt lẫn nhau và hủy diệt trái đất vì sự lừa dối tôn giáo của các sinh vật ngoài hành tinh/thiên thần sa ngã xâm lược. Tùy thuộc vào mỗi người chúng ta yêu hay không yêu "thiên đường có thể với tới" mà sự Hiện diện-Thần (God-presence) sống động và yêu thương, Tinh thần-Thần (God-spirit) chân chính tượng trưng. Sự Hiện diện-Thần sống động không nằm trong bất kỳ cuốn sách nào. Nó ở trong mọi người, mọi nơi, mọi vật. "Sách thiêng" thực sự dạy chúng ta chân lý vĩnh cửu về linh hồn và khoa học. Nó giúp chúng ta thức tỉnh nhanh nhất và dễ dàng nhất sự Hiện diện-Thần bên trong. Một số người sẽ yêu thích tiếng gầm rít của các vị thần giả ngoài hành tinh đầy độc ác, phán xét và lạnh lùng hơn là Thần sống-yêu thương. Bởi vì chúng mang lại cho họ cảm giác an toàn giả (false security) và một khuôn khổ tham chiếu "được xã hội chấp nhận". "Nhét mình vào tập thể" để có được cảm giác an toàn giả chỉ là một "phần thưởng" thảm hại đổi lấy cái giá nhốt linh hồn mình vào tù.</p>
                                        <p>
                                            Các giáo lý thiêng liêng của 12 bộ tộc đang chìm đắm trong đại dương dối trá do các Thiên thần Sa ngã tạo ra. Việc giải cứu sự thiêng liêng là trách nhiệm của chúng ta. Tôn giáo hiện đại chứa đựng một phần sự thật và một phần dối trá. Khi nhận ra rằng tìm thấy Nguồn sống động bên trong là trách nhiệm của mỗi người, chúng ta có thể giải cứu vẻ thiêng liêng trong mọi tín ngưỡng. Chúng ta có thể loại bỏ sự lừa dối và những thứ khiến chúng ta phán xét, giết hại lẫn nhau và trở thành tử đạo một cách vô thức để phục vụ chương trình nghị sự của Thiên thần Sa ngã. Để tìm thấy “Thiên đường trần gian”, trước tiên chúng ta phải tìm thấy Thiên đường bên trong mình. Nhưng bao lâu chúng ta còn tự đóng đinh mình nhân danh các thần giận dữ bên ngoài và tên giả mạo độc ác, chúng ta sẽ không bao giờ tìm thấy Thiên đường. Khi giải cứu vẻ thiêng liêng và loại bỏ sự lừa dối trong các tín ngưỡng thế giới, chúng ta sẽ tìm thấy Thượng đế và tìm thấy chính mình cũng như nhau. Từ khám phá đó, tất cả chúng ta có thể bắt đầu hành trình “Quay về Trạng thái trong sáng ban đầu”.
                                        </p>
                                        <p><b>1.8.2.	Thượng Đế thật sự hiện hữu</b></p>
                                        <p>Thượng đế là một lực vật lý (sức mạnh) toàn năng, hiện diện khắp nơi và toàn tri. Nguồn (Source) của ý thức (Consciousness). Tất cả chúng ta đều sống trong ý thức nguồn (ngay cả "những kẻ xấu") và chúng ta được tạo ra từ ý thức nguồn. Thượng đế chân chính ở ngoài hình tướng xuất hiện; không thực thể nào có thể tách rời khỏi Thượng đế chân chính. Bởi vì sự xuất hiện của mọi hình tướng đều ở trong nguồn. Ngay cả các thực thể ma trận ma quỷ cũng là một phần của tính toàn thể của thần. Tuy nhiên, họ thể hiện một phần của ý thức thần đã quên đi bản sắc thiêng liêng và đánh mất ký ức về tính toàn thể mà họ là một phần. Các thực thể ma trận ma quỷ cạnh tranh quyền lực với các thực thể khác. Bởi vì chúng không nhận ra hoặc hiểu rằng giữa nguồn và tất cả những thực thể sống động đang hiện hữu có sức mạnh, năng lượng, tình yêu sống động được cung cấp vô hạn và tuần hoàn mãi mãi.</p>
                                        <p>Tất cả chúng ta đều trực tiếp kết nối với Thần-Nguồn hiện hữu trong mọi khoảnh khắc; chúng ta có thể học những "bí mật" từng là kiến thức thông thường để cho phép sự Hiện diện-Thần yêu thương sống động dần hiện thực hóa bên trong mình. Chúng ta không cần phải tự đặt mình dưới các Thiên thần Sa ngã tự xưng là các vị thần giả, những kẻ cướp đoạt năng lượng/sức mạnh của chúng ta để duy trì nguồn cung cấp hữu hạn của chúng. Khi chúng ta vượt qua trở ngại "thờ phượng thần giả bên ngoài" và thay vào đó là "giá trị (Worth-ship) của thần sống động bên trong", chúng ta sẽ không quá bị ấn tượng hoặc bị mê hoặc về mặt tinh thần và cảm xúc bởi chương trình "người anh em cứu tinh không gian (space-brother savior)" của các Thiên thần Sa ngã.</p>
                                        <p><b>1.8.3.	Sự thăng hoa là sự thật</b></p>
                                        <p>Tuy nhiên, sự thăng hoa không thể đạt được bằng cách bám chặt máy móc vào những tài liệu cổ bị bóp méo bởi các Thiên thần sa ngã, khiến chính mình bị cuốn vào Ma trận ảo. Các chủng tộc Sáng lập Ngọc Lục Bảo luôn dạy Lời giáo huấn Tự do nguyên bản – đó là về sự hiện hữu sống động của Thượng đế bên trong mỗi chúng ta, và về tính thiêng liêng không thể tách rời cùng Nguồn gốc yêu thương.</p>
                                        <p>Các bậc Tiền nhân (Sáng lập) dạy về sự thật khoa học thiêng liêng của Maharata (∗Cristos bên trong cá nhân, khuôn mẫu tiền vật chất và bản vẽ thiêng liêng 12 chiều). Maharata chính là ý nghĩa ban đầu của câu chuyện Chúa Kitô đích thực. Đây là điều mà chính Chúa Kitô Jesheua Melchizedek và nhiều người trong các tôn giáo từng đam mê giảng dạy.</p>
                                        <p>Trước khi hành tinh chúng ta bị “chiếm đóng” bởi những linh hồn Ma trận sa ngã khát quyền lực, lệch lạc về mặt tinh thần và di truyền, Lời giáo huấn Ngọc Lục Bảo là di sản và trí tuệ của nhân loại. Đó là tâm hồn của mọi tôn giáo. Lời giáo huấn ấy thuộc về tất cả mọi người, dù theo đạo nào – Kitô giáo, Hồi giáo, Do Thái giáo, Ấn Độ giáo, Phật giáo, Shaman giáo... Trước khi các “Falcon”, “Phoenix”, “Serpent”, “Dragon”, “Dove” tuyên bố Trái Đất là lãnh thổ của chúng và muốn chiếm đoạt linh hồn nhân loại, đây là chân lý. Trong thời kỳ trong sáng xa xưa, khi Nhân loại Thiên thần sống trong liên minh hòa bình và hạnh phúc cùng các nền văn minh khác trong “Nhiều Ngôi nhà của Thượng đế”, đó là những ngày tháng ngây thơ trong trắng. “Nhiều Ngôi nhà của Thượng đế” chính là Ma trận Thời gian 15 Chiều của chúng ta. Đó là thời kỳ trước khi Atlantis sụp đổ. Giờ đây, Kỷ nguyên trong sáng đang tái sinh.</p>
                                        <p>Ngày nay, chúng ta đối diện với sự lựa chọn sâu sắc. Liệu chúng ta sẽ dâng hiến và hy sinh Chúa Kitô sống động bên trong (∗Thức tánh cá nhân Avatar Crist 12 chiều của mỗi người) trước bàn thờ của các vị thần đầy thù hận trong các cuốn sách cổ? Hay chúng ta sẽ bước vào nội tâm để khám phá sự hiện diện sống động vĩnh cửu của Thượng đế luôn ở bên trong? Đây là thời điểm quyết định, bởi trong bi kịch hiện tại, chúng ta sẽ chứng kiến “Cuộc chiến của các Thiên thần” đã được tiên tri từ lâu. Chính lúc đó, Thiên thần Sa ngã và Thiên thần Áng sáng sẽ gặp gỡ. Mỗi người sẽ phải đối diện với sự lựa chọn quyết định mình sẽ trở thành thiên thần nào.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}


export default FreedomTeachingChapter2