import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import styles from './Footer.module.css';

function Footer() {
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    return (
        // <div id="footer">
        //     <div class="wrapper">
        //         <div class="footerMenu">
        //             <nav class="footer-navigation">
        //                 <div class="menu-footer-menu-container">
        //                     <ul id="menu-footer-menu" class="menu">
        //                         <li id="menu-item-535"
        //                             class="menu-item"><a
        //                                 href="#">School of Souls</a></li>
        //                         <li id="menu-item-536"
        //                             class="menu-item"><a
        //                                 href="#">Documents</a></li>
        //                         <li id="menu-item-537"
        //                             class="menu-item"><a
        //                                 href="#">Support Centre</a></li>
        //                         <li id="menu-item-538"
        //                             class="menu-item"><a
        //                                 href="#">Testimonials</a></li>
        //                         <li id="menu-item-539"
        //                             class="menu-item"><a
        //                                 href="#">Forum</a></li>
        //                         <li id="menu-item-540"
        //                             class="menu-item"><a
        //                                 href="#">Blueprints</a></li>
        //                         <li id="menu-item-541"
        //                             class="menu-item"><a
        //                                 href="#">Contact</a></li>
        //                     </ul>
        //                 </div>
        //             </nav>
        //         </div>
        //         <div class="copyRight">
        //             &copy; 2024 <span>School of Souls</span> All Rights Reserved.
        //         </div>
        //         <div class="clear"></div>
        //     </div>
        // </div>
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.footerLeft}>
                    <p>&copy; 2024 <span>School of Souls</span> All Rights Reserved.</p>
                </div>

                {user && (
                    <div className={styles.footerRight}>
                        <div className={styles.userSection}>
                            <div className={styles.userInfo}>
                                <span className={styles.userEmail}>{user.email}</span>
                                {/* <span className={styles.userRole}>({user.role})</span> */}
                            </div>

                            <div className={styles.userActions}>
                                <button
                                    onClick={handleLogout}
                                    className={styles.logoutButton}
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </footer>
    )
}

export default Footer;